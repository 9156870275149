import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Routes
import { ROOT_SERVER } from 'helpers/helperInit';
import ServiceList from "./sections/ServiceList";
import RegisterService from "./sections/RegisterService";
import MKTypography from "components/MKTypography";
import { getAllServices } from "redux/actions/publicationAction";
import { SERVICE_SHOW_DETAILS } from "helpers/helperInit";

function Services({id_type_service}) {
    const dispatch = useDispatch();
    const [actionService, setActionService] = useState("list");
    const [titleTypeService, setTitleTypeService] = useState("");

    let hasConn = sessionStorage.getItem('hasConn');

    const handleChangeAction = (action) => {
        setActionService(action);
    }

    useEffect(async () => {
        if(hasConn === null) {
            window.location = ROOT_SERVER + "/home";
        }
        const typeServ = SERVICE_SHOW_DETAILS.filter(item => parseInt(item.id_type_service) === id_type_service);
        setTitleTypeService(typeServ[0].name)
        await dispatch(getAllServices());
    });
    
    return (
        <>
            <MKTypography className='color-primary' fontWeight='bold'>
                {titleTypeService}
            </MKTypography>
            {actionService === "list" && (<ServiceList onChangeAction={handleChangeAction} id_type_service={id_type_service} />)}
            {actionService === "register" && (<RegisterService onChangeAction={handleChangeAction} id_type_service={id_type_service} />)}
        </>
  );
}

export default Services;
