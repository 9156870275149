import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

// Mui Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TablePagination from '@mui/material/TablePagination';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table'
import TableHead from "@mui/material/TableHead";
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import { ROOT_SERVER } from "helpers/helperInit";
import CategorySelect from "components/SeveralSelect/CategorySelect";
import Button from "@mui/material/Button";

import { set_one_publication_action, getAllPublications, saveDeletePublication } from "redux/actions/publicationAction";
import ConfirmationDialog from "components/ConfirmationDialog";

const PUBLICATION_ENTY = {
    "id_publicacion": 0,
    "id_categoria": 0,
    "titulo": "",
    "descripcion": "",
    "fecha": ""
}

const PublicationList = ({onChangeAction, id_type_publication}) => {
    const dispatch = useDispatch();
    const reducerPublication = "publicationReducer";
    const reducerCategory = "categoryReducer";
    const bgImage = ROOT_SERVER + '/static/images/academyc/image1.jpg';
    const data_publications = useSelector(state => state[reducerPublication].data_publications);
    const data_categories = useSelector(state => state[reducerCategory].data_categories);
    const [dataPublications, setDataPublications] = useState(null);
    const [deletePublication, setDeletePublication] = useState({
        id_publicacion: 0
    });
    const [stateConfirmDialog, setStateConfirmDialog] = useState({
        open: false,
        title: '',
        content: ''
    });
    const [valuesPage, setValuesPage] = useState({
        category_id: 0,
    })
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0)
    }

    const handleOpenNewPublication = () => {
        onChangeAction("register");
        dispatch(set_one_publication_action(PUBLICATION_ENTY));
    }

    const handleOpenFrmEditPublication = async (publication) => {
        publication.id_categoria = publication.fk_categoria,
        await dispatch(set_one_publication_action(publication));
        onChangeAction("register");
    }

    const handleSelectCategory = (category) => {
        if(category.id_categoria === 0){
            setDataPublications(data_publications);
        } else {
            const newList = data_publications.filter(item => item.fk_categoria === category.id_categoria);
            setDataPublications(newList);
        }
    }

    const handleOpenConfirmDialog = (publication, value) => {
        setDeletePublication({
            id_publicacion: parseInt(publication.id_publicacion)
        });
        setStateConfirmDialog({
            open: true,
            title: value = "Eliminar Publicación",
            content: value ="¿Seguro de Eliminar la Publicación seleccionada?"
        });
    }

    const handleCloseConfirmDialog = async (value) => {
        if(value === "Yes") {
            const newDataPublication = {
                "id_publicacion": deletePublication.id_publicacion
            };
			await dispatch(saveDeletePublication(newDataPublication));
			await dispatch(getAllPublications());
        }
        setStateConfirmDialog({
            open: false,
            title: '',
            content: ''
        });
    }

    useEffect(() => {
        const newList = data_publications.filter(item => parseInt(item.fk_categoria) === parseInt(id_type_publication));
        setDataPublications(newList);
    },[data_publications, valuesPage]);

    return (
        <>
            <Container>
                <Grid container>
                    {/* <Grid item xs={6}>
                        <Container>
                            <CategorySelect 
                                dataCategories={data_categories} 
                                onSelectCategory={handleSelectCategory} 
                                categorySelected={valuesPage.category_id}
                            />
                        </Container>
                    </Grid> */}
                    <Grid item xs={12} textAlign='right'>
                        <Button 
                            variant='contained' 
                            color='secondary' 
                            style={{color: '#FFF'}}
                            onClick={handleOpenNewPublication}
                        >
                            GENERAR NUEVA
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* <TableCell colSpan={2}>Categoria</TableCell> */}
                            <TableCell>Titulo</TableCell>
                            <TableCell>Descripcion</TableCell>
                            <TableCell align="right" width={'120px'}>...</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataPublications !== null && dataPublications
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(publication => {
                            return(
                                <TableRow key={publication.id_publicacion} style={{verticalAlign: 'top'}}>
                                    {/* <TableCell style={{borderBottom: '1px solid #CCC'}}>
                                        <MKBox
                                            display="flex"
                                            alignItems="center"
                                            borderRadius="xl"
                                            mx={2}
                                            my={2}
                                            py={6}
                                            sx={{
                                                backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                                                    `${linearGradient(
                                                        rgba('#FFF', 0),
                                                        rgba('#FFF', 0), 
                                                        '150', 'to left'
                                                )}, url(${bgImage})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                // minHeight: '100px',
                                                width: '100px'
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell style={{borderBottom: '1px solid #CCC'}}>
                                        <MKTypography variant="h3" className='color-primary'>
                                            {publication.nombre}
                                        </MKTypography>
                                    </TableCell> */}
                                    <TableCell style={{borderBottom: '1px solid #CCC'}}>
                                        <MKTypography className='color-primary'>
                                            {publication.titulo}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell style={{borderBottom: '1px solid #CCC'}}>
                                        <MKTypography variant="body2" opacity={0.8} mb={2}>
                                            {publication.descripcion.substring(0, 250)}...
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align="right" style={{borderBottom: '1px solid #CCC'}}>
                                        <Tooltip title='Editar'>
                                            <IconButton onClick={() => handleOpenFrmEditPublication(publication)}>
                                                <EditIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Anular'>
                                            <IconButton onClick={() => handleOpenConfirmDialog(publication, "0")}>
                                                <DeleteIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                                // <OnePublication key={publication.id_publicacion} data={publication} />
                            )
                        })}            
                    </TableBody>
                </Table>
                <TablePagination
                    component='div'
                    rowsPerPageOptions={[5,10,50,100]}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={dataPublications ? dataPublications.length : 0 }
                    labelRowsPerPage=''
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <ConfirmationDialog 
                    open={stateConfirmDialog.open}
                    title={stateConfirmDialog.title}
                    content={stateConfirmDialog.content}
                    onClose={handleCloseConfirmDialog}
                />
            </Container>
        </>
    )
}

export default PublicationList;