import { typesMenu, typesUser } from '../constants/type';

const initialStateUser = {
    data_users: [],
    one_user: {},
    // auth_user: {},
    open_frm: false,
    open_change_pass: false,
    open_frm_permissions: false,
    editing: false,
}

const initialStateMenu = {
    data_keys_public: [],
    data_user_permts: [],
}

export const userReducer = (state = initialStateUser, action) => {
    switch(action.type) {
        case typesUser.readUsers:
            return { ...state, data_users: action.payload };
        case typesUser.openFrm:
            return { 
                ...state,
                 open_frm: action.payload[0], 
                 open_frm_permissions: false,
                 editing: action.payload[1], 
            };
        case typesUser.search:
            return { ...state, one_user: action.payload }
        case typesUser.openFrmPermisions:
            return { 
                ...state,
                open_frm: false, 
                open_frm_permissions: action.payload,
                editing: false, 
            };
        case typesUser.openChangePass:
            return {
                ...state,
                open_change_pass: action.payload,
            }
        default:
            return state;
    }
}

export const menuReducer = (state = initialStateMenu, action) => {
    switch (action.type) {
        case typesMenu.readKeysPublic:
            return {
                ...state,
                data_keys_public: action.payload,
            }
        case typesMenu.readUserPermits:
            return {
                ...state,
                data_user_permts: action.payload,
            }
        default:
            return state;
    }
}