import { typesPrograms } from "redux/constants/type"
import { typesPublication, typesServices } from "redux/constants/type"

const initialStatePublication = {
    data_publications: [],
    one_publication: {},
    one_publication_type: {}
}

const initialStateCategory = {
    data_categories: [],
}

const initialStateService = {
    data_services: [],
    one_service: null,
    one_service_type: {}
}

const initialStateProgram = {
    data_programs: [],
    one_program: null,
    first_load: true,
    // one_service_type: {}
}

export const publicationReducer = (state = initialStatePublication, action) => {
    switch (action.type) {
        case typesPublication.read:
            return {
                ...state,
                data_publications: action.payload
            }
        case typesPublication.setOnePublication:
            return {
                ...state,
                one_publication: action.payload,
            }
        case typesPublication.setTipoPubl:
            return {
                ...state,
                one_publication_type: action.payload,
            }
        default:
            return state;
    }
}

export const serviceReducer = (state = initialStateService, action) => {
    switch (action.type) {
        case typesServices.read:
            return {
                ...state,
                data_services: action.payload
            }
        case typesServices.setOneService:
            return {
                ...state,
                one_service: action.payload,
            }
        case typesServices.setTipoServ:
            return {
                ...state,
                one_service_type: action.payload,
            }
        default:
            return state;
    }
}

export const programReducer = (state = initialStateProgram, action) => {
    switch (action.type) {
        case typesPrograms.read:
            return {
                ...state,
                data_programs: action.payload
            }
        case typesPrograms.setOneProgram:
            return {
                ...state,
                one_program: action.payload,
            }
        case typesPrograms.firstLoad:
            return {
                ...state,
                first_load: action.payload
            }
        // case typesPrograms.setTipoServ:
        //     return {
        //         ...state,
        //         one_service_type: action.payload,
        //     }
        default:
            return state;
    }
}

export const categoryReducer = (state = initialStateCategory, action) => {
    switch (action.type) {
        case typesPublication.readCateg:
            return {
                ...state,
                data_categories: action.payload
            }
        default:
            return state;
    }
}