// import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ContentProgram from "./ContentProgram";
import ListPrograms from "./ListPrograms";
import LinkServices from "pages/LandingPages/Services/sections/LinkServices";

const ContentPage = () => {
    // const reducerService = "serviceReducer";
    // const one_service_type = useSelector(state => state[reducerService].one_service_type);

    return(
        <Box>
            <Grid container>
                <Grid item xs={12} lg={3}>
                    <ListPrograms />
                </Grid>
                <Grid item xs={12} lg={9}>
                    <ContentProgram />
                </Grid>
                {/* <Grid item xs={12} lg={2}>
                    <LinkServices />
                </Grid> */}
            </Grid>
        </Box>
    )
}

export default ContentPage;