import Typography from '@mui/material/Typography';
import "./index.css";

const CustomAlert = ({ typeAlert, mainMessage, secondMessage }) => {
    return(
        <>
            <Typography className={typeAlert === 'success' ? "text-main-success" : "text-main-error"}>{mainMessage}</Typography>
            <Typography className={"text-second"}>{secondMessage}</Typography>
        </>
    )
}

export default CustomAlert;