import axios from "axios";
import { MESSAGE_NOT_CONNECT_SERVER } from "helpers/helperInit";
import { URL_SERVICE, getUserAuth } from "helpers/helperInit";
import { typesPrograms } from "redux/constants/type";
import { typesPublication, typesServices } from "redux/constants/type";
import { openNotifAction } from "./severalAction";

const validatorResponseService = (dispatch, requireAuth, data, entity, action) => {
    if(data.success !== undefined) {
        if(requireAuth) {
            if (!data.isAuthenticated) {
                window.location = "/";
            }    
        }
        if (data.success) {
            if(entity === "publication") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_publication_action(data.data));
                        return;
                    case "insert":
                        dispatch(openNotifAction(["Publicación insertada con exito", "success", 'filled']));
                        return;
                    case "update":
                        dispatch(openNotifAction(["Publicación actualizada con exito", "success", 'filled']));
                        return;
                    case "delete":
                        dispatch(openNotifAction(["Publicación eliminada con exito", "success", 'filled']));
                        return;
                    default:
                        return
                }
            }
            if(entity === "category") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_category_action(data.data));
                        return;
                    default:
                        return
                }
            }
            if(entity === "service") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_services_action(data.data));
                        return;
                    // case "insert":
                    //     dispatch(openNotifAction(["Publicación insertada con exito", "success", 'filled']));
                    //     return;
                    // case "update":
                    //     dispatch(openNotifAction(["Publicación actualizada con exito", "success", 'filled']));
                    //     return;
                    // case "delete":
                    //     dispatch(openNotifAction(["Publicación eliminada con exito", "success", 'filled']));
                    //     return;
                    default:
                        return
                }
            }
            if(entity === "program") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_programs_action(data.data));
                        return;
                    // case "insert":
                    //     dispatch(openNotifAction(["Publicación insertada con exito", "success", 'filled']));
                    //     return;
                    // case "update":
                    //     dispatch(openNotifAction(["Publicación actualizada con exito", "success", 'filled']));
                    //     return;
                    // case "delete":
                    //     dispatch(openNotifAction(["Publicación eliminada con exito", "success", 'filled']));
                    //     return;
                    default:
                        return
                }
            }
        } else {
            dispatch(openNotifAction([data.msg_rpta, "error", 'filled']));
        }
    } else {
        dispatch(openNotifAction([MESSAGE_NOT_CONNECT_SERVER, "error", 'filled']));
    }
}

// Publication actions
export const getAllPublications = () => {
    const requireAuth = false;
    const entity = "publication";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "publicacion/getPublicacionesPortal/", {
            headers: {
                'Content-Type': 'Application/Json',
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

// export const saveNewPublication = (dataPublication) => {
//     const dataUserAuth = getUserAuth();
//     const strToken = dataUserAuth[1];
//     const requireAuth = true;
//     const entity = "publication";
//     const action = "insert";
//     return async (dispatch) => {
//         const res = await axios(URL_SERVICE + "publicacion/insertPublicacion/", {
//             headers: {
//                 'Content-Type': 'Application/Json',
//                 'Authorization': 'Bearer ' + strToken,
//             },
//             method: "POST",
//             data: dataPublication
//         });
//         const data = (await res).data;
//         validatorResponseService(dispatch, requireAuth, data, entity, action);
//     }
// }

export const saveNewPublication = async (dataPublication) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "publication";
    const action = "insert";
    const res = await axios(URL_SERVICE + "publicacion/insertPublicacion/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataPublication
    });
    const data = (await res).data;
    return data;
}

// export const saveEditPublication = (dataPublication) => {
//     const dataUserAuth = getUserAuth();
//     const strToken = dataUserAuth[1];
//     const requireAuth = true;
//     const entity = "publication";
//     const action = "update";
//     return async (dispatch) => {
//         const res = await axios(URL_SERVICE + "publicacion/updatePublicacion/", {
//             headers: {
//                 'Content-Type': 'Application/Json',
//                 'Authorization': 'Bearer ' + strToken,
//             },
//             method: "PUT",
//             data: dataPublication
//         });
//         const data = (await res).data;
//         validatorResponseService(dispatch, requireAuth, data, entity, action);
//     }
// }

export const saveEditPublication = async (dataPublication) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "publication";
    const action = "update";
    const res = await axios(URL_SERVICE + "publicacion/updatePublicacion/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "PUT",
        data: dataPublication
    });
    const data = (await res).data;
    return data;
}

export const saveDeletePublication = (dataPublication) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "publication";
    const action = "delete";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "publicacion/deletePublicacion/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "DELETE",
            data: dataPublication
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const saveNewImgPublication = async (dataImgPubli) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "publicacion/insertPublicacionImagen/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataImgPubli
    });
    const data = (await res).data;
    return data;
}

export const get_all_publication_action = (data) => {
    return {
        type: typesPublication.read,
        payload: data
    }
}

export const set_one_publication_action = (data) => {
    return {
        type: typesPublication.setOnePublication,
        payload: data
    }
}

export const set_publication_type_action = (data) => {
    return {
        type: typesPublication.setTipoPubl,
        payload: data
    }
}

export const getAllCategories = () => {
    const requireAuth = false;
    const entity = "category";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "categoria/getCategorias/", {
            headers: {
                'Content-Type': 'Application/Json',
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const get_all_category_action = (data) => {
    return {
        type: typesPublication.readCateg,
        payload: data
    }
}
// End publication actions

// Services actions
export const getAllServices = () => {
    const requireAuth = false;
    const entity = "service";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "servicio/getServicio/", {
            headers: {
                'Content-Type': 'Application/Json',
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const saveNewService = async (dataService) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "servicio/insertServicio/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataService
    });
    const data = (await res).data;
    return data;
}

export const saveEditService = async (dataService) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "servicio/updateServicio/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataService
    });
    const data = (await res).data;
    return data;
}

export const saveDeleteService = async (dataService) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "servicio/deleteServicio/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataService
    });
    const data = (await res).data;
    return data;
}

export const saveNewModality = async (dataModality) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "servicio/insertServicioModalidad/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataModality
    });
    const data = (await res).data;
    return data;
}

export const saveNewRequirement = async (dataRequirement) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "servicio/insertServicioRequisito/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataRequirement
    });
    const data = (await res).data;
    return data;
}

export const saveNewCost = async (dataCost) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "servicio/insertServicioCosto/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataCost
    });
    const data = (await res).data;
    return data;
}

export const saveNewCronograma = async (dataCronograma) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "servicio/insertServicioCronograma/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataCronograma
    });
    const data = (await res).data;
    return data;
}

export const saveNewResult = async (dataResult) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "servicio/insertServicioResultado/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataResult
    });
    const data = (await res).data;
    return data;
}

export const saveNewAttached = async (dataAttached) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "servicio/insertServicioArchivo/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataAttached
    });
    const data = (await res).data;
    return data;
}

export const get_all_services_action = (data) => {
    return {
        type: typesServices.read,
        payload: data
    }
}

export const set_one_service_action = (data) => {
    return {
        type: typesServices.setOneService,
        payload: data
    }
}

export const set_service_type_action = (data) => {
    return {
        type: typesServices.setTipoServ,
        payload: data
    }
}
// End Services actions

// Programas actions
export const getAllPrograms = () => {
    const requireAuth = false;
    const entity = "program";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "programa/getProgramas/", {
            headers: {
                'Content-Type': 'Application/Json',
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const saveNewProgram = async (dataProgram) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "programa/insertPrograma/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataProgram
    });
    const data = (await res).data;
    return data;
}

export const saveEditProgram = async (dataProgram) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "programa/updatePrograma/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataProgram
    });
    const data = (await res).data;
    return data;
}

export const saveDeleteProgram = async (dataProgram) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "programa/deletePrograma/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataProgram
    });
    const data = (await res).data;
    return data;
}

export const saveNewProgramPlan = async (dataPlan) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "programa/insertProgramaPlan/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataPlan
    });
    const data = (await res).data;
    return data;
}

export const saveNewProgramImagen = async (dataImagen) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "programa/insertProgramaImagen/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataImagen
    });
    const data = (await res).data;
    return data;
}

export const saveNewProgramModality = async (dataModality) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "programa/insertProgramaModalidad/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataModality
    });
    const data = (await res).data;
    return data;
}

export const saveNewProgramRequiment = async (dataRequiment) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "programa/insertProgramaRequisito/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataRequiment
    });
    const data = (await res).data;
    return data;
}

export const get_all_programs_action = (data) => {
    return {
        type: typesPrograms.read,
        payload: data
    }
}

export const set_one_program_action = (data) => {
    return {
        type: typesPrograms.setOneProgram,
        payload: data
    }
}

export const set_load_first_view = (data) => {
    return {
        type: typesPrograms.firstLoad,
        payload: data
    }
}

// export const set_service_type_action = (data) => {
//     return {
//         type: typesServices.setTipoServ,
//         payload: data
//     }
// }
// End Programas actions