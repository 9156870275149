import axios from "axios";
import { MESSAGE_NOT_CONNECT_SERVER } from "helpers/helperInit";
import { URL_SERVICE, getUserAuth } from "helpers/helperInit";
import { typesGeneralData } from "redux/constants/type";
import { openNotifAction } from "./severalAction";

const validatorResponseService = (dispatch, requireAuth, data, entity, action) => {
    if(data.success !== undefined) {
        if(requireAuth) {
            if (!data.isAuthenticated) {
                window.location = "/";
            }    
        }
        if (data.success) {
            if(entity === "general_data") {
                switch (action) {
                    case "get":
                        dispatch(get_general_data_action(data.data));
                        return;
                    case "insert":
                        dispatch(openNotifAction(["Publicación insertada con exito", "success", 'filled']));
                        return;
                    case "update":
                        dispatch(openNotifAction(["Publicación actualizada con exito", "success", 'filled']));
                        return;
                    case "delete":
                        dispatch(openNotifAction(["Publicación eliminada con exito", "success", 'filled']));
                        return;
                    default:
                        return
                }
            }
        } else {
            dispatch(openNotifAction([data.msg_rpta, "error", 'filled']));
        }
    } else {
        dispatch(openNotifAction([MESSAGE_NOT_CONNECT_SERVER, "error", 'filled']));
    }
}

// Publication actions
export const getGeneralData = () => {
    const requireAuth = false;
    const entity = "general_data";
    const action = "get";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "datosgenerales/getDatosGenerales/", {
            headers: {
                'Content-Type': 'Application/Json',
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const getOtherGeneralData = async () => {
    const requireAuth = false;
    const entity = "general_data";
    const action = "get";
    const res = await axios(URL_SERVICE + "datosgenerales/getDatosGenerales/", {
        headers: {
            'Content-Type': 'Application/Json',
        },
        method: "GET"
    });
    const data = (await res).data;
    return data;
}

export const saveGeneralData = async (dataGeneralData) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "datosgenerales/updateDatosGenerales/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataGeneralData
    });
    const data = (await res).data;
    return data;
}

export const get_general_data_action = (data) => {
    return {
        type: typesGeneralData.read,
        payload: data
    }
}

// export const set_one_publication_action = (data) => {
//     return {
//         type: typesPublication.setOnePublication,
//         payload: data
//     }
// }
// End publication actions