import { useSelector } from "react-redux";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material UI Icons
import LocalPhone from '@mui/icons-material/LocalPhone';
import FmdGood from '@mui/icons-material/FmdGood';
import Language from '@mui/icons-material/Language';
import Email from '@mui/icons-material/Email';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;
  const reducerGeneralData = "generalDataReducer";
  const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);

  return (
    <MKBox component="footer" sx={{ background: "#2D3F50" }}>
      <Container>
        <Grid container spacing={3} >
          <Grid item xs={12} md={4}>
            {data_general_data.length > 0 && (
              <>
                <div><LocalPhone color="light" /> 
                  <span style={{ color: "#FFF", fontSize: '11px', marginTop: '-5px' }}> 
                    {data_general_data[0].telefono}
                  </span>
                </div>
                <div><FmdGood color="light" /> 
                  <span style={{ color: "#FFF", fontSize: '11px', marginTop: '-5px' }}> 
                    {data_general_data[0].direccion}
                  </span>
                </div>
                <div><Language color="light" /> 
                  <span style={{ color: "#FFF", fontSize: '11px', marginTop: '-5px' }}> 
                    {data_general_data[0].web}
                  </span>
                </div>
                <div><Email color="light" /> 
                  <span style={{ color: "#FFF", fontSize: '11px', marginTop: '-5px' }}> 
                    {data_general_data[0].email}
                  </span>
                </div>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={4}></Grid>
          <Grid item xs={12} md={4} textAlign="right">
            {socials.map(({ icon, link }, key) => (
              <MKTypography
                key={link}
                component="a"
                href={link}
                target="_blank"
                rel="noreferrer"
                variant="h5"
                color="light"
                opacity={0.8}
                mr={key === socials.length - 1 ? 0 : 2.5}
              >
                {icon}
              </MKTypography>
            ))}
          </Grid>
        </Grid>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object, PropTypes.array])).isRequired,
};

export default DefaultFooter;
