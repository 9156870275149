import { useState, useEffect } from "react";
// redux
import { useDispatch, useSelector } from 'react-redux';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import CustomAlert from 'components/CustomAlert';

// CustomHooks
import { useUser } from 'customHooks/useUser';

// redux actions
import { open_change_pass_action } from 'redux/actions/userAction';

function ChangePassword() {
  const dispatch = useDispatch();
  const { SaveChangePassword } = useUser();
  const reducerUser = "userReducer";
  const open_change_pass = useSelector(state => state[reducerUser].open_change_pass);
  const [values, setValues] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });
  const [valuesAlert, setValuesAlert] = useState({
    openAlert: false,
    typeAlert: 'success',
    mainMessage: "",
    secondMessage: "",
  });

  const handleChange = e => setValues({ ...values, [e.target.name]: e.target.value });

  const initValues = () => {
    setValues({
      current_password: "",
      new_password: "",
      confirm_password: "",
    });
    setValuesAlert({
      openAlert: false,
      typeAlert: 'success',
      mainMessage: "",
      secondMessage: "",
    });
  }

  const handleSaveChangePassword = async (e) => {
    e.preventDefault();
    const newData = {
      "current_password": values.current_password,
      "new_password": values.new_password,
      "confirm_password": values.confirm_password,
    }
    const dataRpta = await SaveChangePassword(newData);
    if(!dataRpta.success) {
      setValuesAlert({
        openAlert: true,
        typeAlert: 'error',
        mainMessage: "* " + dataRpta.msg_rpta,
        secondMessage: "Verifique",
      });  
      return;
    }

    setValuesAlert({
      openAlert: true,
      typeAlert: 'success',
      mainMessage: "* " + dataRpta.msg_rpta,
      secondMessage: "El Password ha sido modificado con exito",
    });
    
    setTimeout(() => {
      dispatch(open_change_pass_action(false));
    }, 1500);
  }

  const handleCancel = () => dispatch(open_change_pass_action(false));

  useEffect(() => {
    initValues();
  }, [open_change_pass])

  return (
    <>
      <MKBox component="section" py={{ xs: 0, lg: 0 }}>
        <Container>
          {/* <div>
            <div style={{ color: '#DD4E3D', fontSize: '14px' }}>* Password no coincide</div>
            <div style={{ color: '#938B8B', fontSize: '13px', fontWeight: '100', fontStyle: 'italic' }}></div>
          </div> */}
          <CustomAlert typeAlert={valuesAlert.typeAlert} mainMessage={valuesAlert.mainMessage} secondMessage={valuesAlert.secondMessage} />
          <Grid container item>
            <MKBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"
              mb={2}
              sx={{ overflow: "hidden" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <MKBox component="form" p={2} method="post" onSubmit={handleSaveChangePassword}>
                    <MKBox pt={0.5} pb={2} px={1}>
                      <Grid container>
                        <Grid item xs={12} lg={12} pr={2} mb={6}>
                          <MKInput
                            type="password"
                            variant="standard"
                            label="Password actual"
                            name="current_password"
                            autoComplete='off'
                            value={values.current_password}
                            placeholder="Ingrese su password actual"
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            required
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} lg={12} pr={1} mb={6}>
                          <MKInput
                            type="password"
                            variant="standard"
                            label="Nueva password"
                            name="new_password"
                            autoComplete='off'
                            value={values.new_password}
                            placeholder="Ingrese su nueva password"
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            required
                            fullWidth
                          />
                        </Grid>

                        <Grid item xs={12} lg={12} pr={1} mb={2}>
                          <MKInput
                            type="password"
                            variant="standard"
                            label="Confirmar password"
                            name="confirm_password"
                            autoComplete='off'
                            value={values.confirm_password}
                            placeholder="confirme su nueva password"
                            InputLabelProps={{ shrink: true }}
                            onChange={handleChange}
                            required
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={12}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >
                        <Stack direction="row" alignItems="flex-end" spacing={1}>
                          <MKButton variant="gradient" color="info" type="submit">
                            Guardar
                          </MKButton>
                          <MKButton color="light" onClick={handleCancel}>Cancelar</MKButton>
                        </Stack>
                      </Grid>
                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default ChangePassword;
