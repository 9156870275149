import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const SexoSelect = (props) => {
    const { dataSexo, onSelectSexo, sexoSelected } = props;
    const [values, setValues] = useState({
        id_sexo: "1"
    });

    const handleChange = e => {
        let idSexo = parseInt(e.target.value);
        // let oneSexo = dataSexo.filter(p=> p.id_sexo === idSexo);
        setValues({ id_sexo: idSexo });
        // onSelectSexo(oneSexo[0]);
        onSelectSexo(idSexo);
    }

    useEffect(() => {
        setValues({ id_sexo: sexoSelected });
    }, [sexoSelected]);

    return (
        <FormControl variant='standard' fullWidth>
            <InputLabel>Sexo</InputLabel>
            <Select
                name="id_sexo"
                value={values.id_sexo}
                label="Sexo"
                required
                onChange={handleChange}
            >
                {/* <MenuItem value="-1" key="-1"></MenuItem> */}
                {dataSexo && dataSexo.map(sexo => {
                    let keySexo = "key" + sexo.id_sexo;
                    return(
                        <MenuItem value={sexo.id_sexo} key={keySexo} >{sexo.nom_sexo}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default SexoSelect;