import { useSelector } from "react-redux";

// MUI Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit Components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// HelperInit
import { ROOT_SERVER, INSTITUTION_SUBTITLE } from 'helpers/helperInit';
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import NavbarMorropon from "examples/Navbars/NavbarMorropon";
import { Card } from "@mui/material";



const HeaderInstituto = () => {
    const reducerGeneralData = "generalDataReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);
    let bgImage = "";

    if(data_general_data.length > 0) {
        bgImage=ROOT_SERVER + "/static/images/logo/" + data_general_data[0].imagen_escudo;
        // bgImage=ROOT_SERVER + "/static/images/logo/escudoMorropon.png";
    }
    console.log("bgImage...", bgImage)
    return (
        <>
        {/* <NavbarMorropon /> */}
        {/* MKBox */}
        <Card
            // minHeight="15vh"
            heigth="100%"
            width="100%"
            className='bg-navbar'
            // raised={false}
            sx={{
                backgroundPosition: "left",
                placeItems: "left",
                align: 'left',
                paddingBottom: '-50px',
                borderRadius: 0,
            }}
        >
            {/* <Container> */}
                <Grid container pl={2} py={1}>
                    <Grid item xs={12} lg={3} alignContent="left">
                        <img src={bgImage} width={50}></img>
                    </Grid>
                    <Grid item xs={12} lg={9}>
                        <NavbarMorropon />
                    </Grid>
                    {/* <Grid item xs={12} alignContent="center" textAlign={'left'}>
                        {data_general_data.length > 0 && (
                            <> */}
                                {/* <MKTypography 
                                    color='white'
                                    sx={{
                                        fontSize: '20px',
                                        fontFamily: 'Georgia'
                                    }}
                                    textAlign='center'
                                    pb={4}
                                >
                                    {data_general_data[0].nombre_institucion}
                                </MKTypography> */}
                                {/* <MKTypography 
                                    className='color-three'
                                    sx={{
                                        fontSize: '12px',
                                        fontFamily: 'Arial',
                                        paddingLeft: '25px',
                                    }}
                                    textAlign='center'
                                >
                                    {INSTITUTION_SUBTITLE}
                                </MKTypography> */}
                            {/* </>
                        )}
                    </Grid> */}
                </Grid>
            {/* </Container> */}
        </Card>
        </>
    )
}
export default HeaderInstituto;