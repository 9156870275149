import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import { 
    KEY_SERVICE_ADMISION,
    KEY_SERVICE_NIVELACION,
    KEY_SERVICE_MATRICULA,
    KEY_SERVICE_TRAMITE,
    KEY_SERVICE_CONVOCATORIA,
    KEY_SERVICE_TEST
} from "helpers/helperInit";
import { ROOT_SERVER } from "helpers/helperInit";

const CardService = ({key_service, data }) => {
    const dispatch = useDispatch();
    const handleSetKeyService = () => {
        sessionStorage.setItem("key_service", JSON.stringify(key_service));
        dispatch(set_one_service_action(null));
        // dispatch(set_service_type_action(key_service));
    }

    return(
        <MKBox 
            component={Link}
            to={ROOT_SERVER + "/pages/informate"}
            style={{
                border: '1px solid #C2C2C2', 
                background: 'transparent',
                borderRadius: 0,
                border:'none'
            }}
            onClick={handleSetKeyService}
        >
            <Grid container px={6} spacing={2}>
                <Grid item xs={12}>
                    <MKBox textAlign="center" style={{border:'none',background: "#FFF"}}>
                        <MKTypography display="inline" variant="h6" className='color-four' textTransform="capitalize" fontWeight="bold">
                            {key_service.name.toUpperCase()}
                        </MKTypography>
                    </MKBox>
                    <MKBox
                        component="img"
                        src={key_service.image_name}
                        width="80%"
                        zIndex={1}
                        style={{opacity: 0.8, minHeight: '130px', height: '100px'}}
                    />
                    {/* {data !== null ? (
                        <>
                            <MKTypography className='color-primary' fontWeight="bold" style={{fontSize: '15px', lineHeight: 1.5, padding: '2px 15px'}}>
                                {data.titulo}
                            </MKTypography>
                            <MKBox mt={1} mb={3}>
                                <MKTypography variant="body2" component="p" style={{fontSize: '15px', textAlign: 'left', lineHeight: 1.5, padding: '2px 15px'}}>
                                    {data.descripcion !== null ? data.descripcion.substring(0,200) : ""}
                                </MKTypography>
                            </MKBox>
                        </>
                    ) : (
                        <MKBox textAlign="center" style={{height: '130px'}}>
                            <MKTypography className='color-primary' fontWeight="bold" style={{fontSize: '15px', lineHeight: 1.5, padding: '2px 15px'}}>
                                SIN CONTENIDO
                            </MKTypography>
                        </MKBox>
                    )} */}
                </Grid>
            </Grid>
        </MKBox>
    );
}

const HomeServices = () => {
    const reducerService = "serviceReducer";
    const data_services = useSelector(state => state[reducerService].data_services);
    const [dataAdmision, setDataAdmision] = useState(null);
    const [dataNivelacion, setDataNivelacion] = useState(null);
    const [dataMatricula, setDataMatricula] = useState(null);
    const [dataTramite, setDataTramite] = useState(null);
    const [dataConvocatoria, setDataConvocatoria] = useState(null);
    const [dataTest, setDataTest] = useState(null);

    const initData = () => {
        if(data_services !== null) {
            let oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_ADMISION.key_service));
            if (oneService.length > 0) setDataAdmision(oneService[0]);
            
            oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_NIVELACION.key_service));
            if (oneService.length > 0) setDataNivelacion(oneService[0]);
    
            oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_MATRICULA.key_service));
            if (oneService.length > 0) setDataMatricula(oneService[0]);
            
            oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_TRAMITE.key_service));
            if (oneService.length > 0) setDataTramite(oneService[0]);
    
            oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_CONVOCATORIA.key));
            if (oneService.length > 0) setDataConvocatoria(oneService[0]);
    
            oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_TEST.key));
            if (oneService.length > 0) setDataTest(oneService[0]);
        }
    }

    useEffect(async () => {
        await initData();
    });

    return(
        <>
        <Grid container spacing={3} pt={4}>
            <Grid item xs={12} md={6} lg={4}>
                <CardService key_service={KEY_SERVICE_ADMISION} data={dataAdmision} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <CardService key_service={KEY_SERVICE_NIVELACION} data={dataNivelacion} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <CardService key_service={KEY_SERVICE_MATRICULA} data={dataMatricula} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <CardService key_service={KEY_SERVICE_TRAMITE} data={dataTramite} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <CardService key_service={KEY_SERVICE_CONVOCATORIA} data={dataConvocatoria} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <CardService key_service={KEY_SERVICE_TEST} data={dataTest} />
            </Grid>
        </Grid>
        </>
    );
}

export default HomeServices;