import { useSelector } from "react-redux";

// MUI Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit Components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// HelperInit
import { ROOT_SERVER, INSTITUTION_SUBTITLE } from 'helpers/helperInit';
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { SHOW_HEADER } from "helpers/helperInit";
import HeaderLuciano from "./HeaderLuciano";
import HeaderInstituto from "./HeaderInstituto";



const SimpleHeader = () => {
    const reducerGeneralData = "generalDataReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);
    let bgImage = "";

    if(data_general_data.length > 0) {
        bgImage=ROOT_SERVER + "/static/images/logo/" + data_general_data[0].imagen_escudo;
    }
    return (
        <>
        {SHOW_HEADER === 1 ? <HeaderLuciano /> : <HeaderInstituto /> }
        </>
    )
}
export default SimpleHeader;