import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MKTypography from "components/MKTypography";
import MuiLink from "@mui/material/Link";

import { ROOT_SERVER } from "helpers/helperInit";

import { useSelector } from "react-redux";
import { ROOT_FILES_PDF } from "helpers/helperInit";
import { useState } from "react";
import Contactenos from "./Contactenos";

const imgLocalization = ROOT_SERVER + '/static/images/general_data/localization.png';
const imgLocate = ROOT_SERVER + '/static/images/general_data/locate.png';
const imgPhone = ROOT_SERVER + '/static/images/general_data/phone.png';
const imgWeb = ROOT_SERVER + '/static/images/general_data/web.jpg';
const imgEmail = ROOT_SERVER + '/static/images/general_data/email.png';

const imgPdf = ROOT_SERVER + '/static/images/icons/pdf.png';

const Information = () => {
    const reducerGeneralData = "generalDataReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);
    const [expandRevalidacion, setExpandRevalidacion] = useState(false);

    const handleChangeExpandRevalidacion = () => {
        setExpandRevalidacion(!expandRevalidacion);
    };

    let pathPdf = "";
    if(data_general_data.length > 0) {
        pathPdf = data_general_data[0].url_img_publicaciones;
    }

    return (
        <Box>
            {(data_general_data && data_general_data.length > 0) && (
                <>
                    <Container >
                        <Grid container spacing={3} px={2}>
                            <Grid item xs={12} lg={12} >
                                <Grid container columnSpacing={2} >
                                    <Grid item xs={12} lg={6}>
                                        <Card sx={{padding: '30px'}}>
                                            <MKTypography 
                                                variant="h3" 
                                                mb={1} 
                                                className='color-primary'
                                            >
                                                NUESTRA MISIÓN
                                            </MKTypography>
                                            <MKTypography variant="body2" opacity={0.8} mb={2}>
                                                {data_general_data[0].mision}
                                            </MKTypography>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Card sx={{padding: '30px'}}>
                                            <MKTypography 
                                                variant="h3" 
                                                mb={1} 
                                                className='color-primary'
                                            >
                                                NUESTRA VISIÓN
                                            </MKTypography>
                                            <MKTypography variant="body2" opacity={0.8} mb={2}>
                                                {data_general_data[0].vision}
                                            </MKTypography>
                                        </Card>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} lg={6}>
                                <Contactenos />
                            </Grid> */}
                            {data_general_data[0].objetivos !== null && (
                                <>
                                {data_general_data[0].objetivos !== "" && (
                                    <Grid item xs={12}>
                                        <MKTypography 
                                            variant="h4" 
                                            mb={1} 
                                            className='color-primary'
                                        >
                                            OBJETIVOS
                                        </MKTypography>
                                        <MKTypography variant="body2" opacity={0.8} mb={2}>
                                            {data_general_data[0].objetivos}
                                        </MKTypography>
                                    </Grid>
                                )}
                                </>
                            )}
                            {data_general_data[0].objetivos !== null && (
                                <Grid item xs={12} pb={6}>
                                    <Card sx={{padding: '30px'}}>
                                    <MKTypography 
                                        variant="h4" 
                                        mb={1} 
                                        className='color-primary'
                                    >
                                        NUESTRA HISTORIA
                                    </MKTypography>
                                    <MKTypography variant="body2" opacity={0.8} mb={2}>
                                        {data_general_data[0].resena}
                                    </MKTypography>
                                    </Card>
                                </Grid>
                            )}
                            <Box textAlign={'center'} sx={{width: '100%'}} px={3}>
                            {data_general_data[0].pdf_creacion !== null && (
                                <>
                                <Accordion expanded={expandRevalidacion} onChange={handleChangeExpandRevalidacion} sx={{border: 'none'}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Grid container>
                                            <Grid item xs={12} textAlign='left'>
                                                <img src={imgPdf} width={20} style={{cursor: 'pointer'}} />
                                                <MKTypography display='inline' pl={1} style={{fontSize: '13px', fontStyle: 'italic', color: '#000'}} >
                                                    Creación    
                                                </MKTypography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{height: '500px !important'}}>
                                        <iframe style={{width: '100%', height: '100%'}} src={pathPdf + data_general_data[0].pdf_creacion} />
                                    </AccordionDetails>
                                </Accordion>
                                </>
                            )}
                            <Divider />
                            {data_general_data[0].pdf_revalidacion !== null && (
                                <>
                                {/* <Box pb={6}>
                                    <MKTypography display='inline' pl={5} pr={5} pb={6} style={{fontSize: '20px', fontStyle: 'italic'}} >
                                        Revalidación
                                    </MKTypography>
                                    <Card
                                        component={MuiLink}
                                        href={pathPdf + data_general_data[0].pdf_revalidacion}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            background: 'transparent',
                                            borderRadius: 0,
                                        }}
                                        alignItems='center'
                                    >
                                        <img src={imgPdf} width={50} style={{cursor: 'pointer'}} />
                                    </Card>
                                    
                                </Box> */}
                                <Accordion expanded={expandRevalidacion} onChange={handleChangeExpandRevalidacion} sx={{border: 'none'}}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Grid container>
                                            <Grid item xs={12} textAlign='left'>
                                                <img src={imgPdf} width={20} style={{cursor: 'pointer'}} />
                                                <MKTypography display='inline' pl={1} style={{fontSize: '13px', fontStyle: 'italic', color: '#000'}} >
                                                    Revalidación
                                                </MKTypography>
                                           </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{height: '500px !important'}}>
                                        <iframe style={{width: '100%', height: '100%'}} src={pathPdf + data_general_data[0].pdf_revalidacion} />
                                    </AccordionDetails>
                                </Accordion>
                                </>
                            )}
                            </Box>
                        </Grid>
                    </Container>
                    <Divider />
                    <div className="myDiv">
                        <div className="bg" style={{backgroundImage: `url(${imgLocalization})`}}></div>
                        <MKTypography 
                            style={{fontSize: '15px'}}
                            pt={4}
                            px={6}
                            mb={1} 
                            className='color-secondary'
                        >
                            CONTACTENOS EN
                        </MKTypography>
                        <Container >
                            <div>
                                <img src={imgLocate} width={30} /> {data_general_data[0].direccion}
                            </div>
                            <div>
                                <img src={imgPhone} width={30} /> {data_general_data[0].telefono}
                            </div>
                            <div>
                                <img src={imgWeb} width={30} /> {data_general_data[0].web}
                            </div>
                            <div>
                                <img src={imgEmail} width={30} /> {data_general_data[0].email}
                            </div>
                        </Container>
                    </div>
                </>
            )}
        </Box>
    );
}

export default Information;