import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

// Images
import SimpleHeader from "examples/Headers/SimpleHeader";

// Custom Pages and Components
// import PublicationList from "./sections/PublicationsList";

// Helperinit
import { ROOT_SERVER } from "helpers/helperInit";
import Header from "./sections/Header";
import ContentPage from "./sections/ContentPage";
import HomeLinks from "pages/Home/sections/HomeLinks";
import { getAllPrograms } from "redux/actions/publicationAction";
import ListMain from "./sections/ListMain";
import { getGeneralData } from "redux/actions/generalDataAction";
import { getAllPublications } from "redux/actions/publicationAction";
import { getAllServices } from "redux/actions/publicationAction";
import { getAllStudents } from "redux/actions/notesAction";

function Programs() {
    const reducerProgram = "programReducer";
    const dispatch = useDispatch();
    const first_load = useSelector(state => state[reducerProgram].first_load);
    const [firstLoad, setFirstLoad] = useState(true);

    const handleSetFirstLoad = (valor) => {
        console.log("valor....:", valor)
        setFirstLoad(valor);
    }

    useEffect(async () => {
        dispatch(getGeneralData());
        await dispatch(getAllPrograms());
        dispatch(getAllPublications());
        dispatch(getAllServices());
        dispatch(getAllStudents());
    });

    console.log("first_load...:", first_load)

    return (
        <>
            <SimpleHeader />
            <Box sx={{background: "#FFF"}}>
                {/* <Header /> */}
                {!!first_load ? (
                    <>
                    {!!firstLoad ? (<ListMain onSelect={handleSetFirstLoad} />) : (<ContentPage />)}
                    </>
                ) : (
                    <ContentPage />
                )}
                
                <Box style={{background: '#FFF'}}>
                    <Divider />
                    <HomeLinks />
                </Box>
            </Box>
            <MKBox mt={4}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default Programs;