import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

// Images
import SimpleHeader from "examples/Headers/SimpleHeader";

// Reducer
import { getAllPublications, getAllCategories } from "redux/actions/publicationAction";

// Custom Pages and Components
// import PublicationList from "./sections/PublicationsList";

// Helperinit
import { ROOT_SERVER } from "helpers/helperInit";
import Header from "./sections/Header";
import ContentPage from "./sections/ContentPage";
import HomeLinks from "pages/Home/sections/HomeLinks";
import { getGeneralData } from "redux/actions/generalDataAction";
import { getAllPrograms } from "redux/actions/publicationAction";

function Services() {
    const dispatch = useDispatch();
    const reducerGeneralData = "generalDataReducer";
    const reducerService = "serviceReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);
    const one_service_type = useSelector(state => state[reducerService].one_service_type);

    useEffect(async () => {
        if(data_general_data.length > 0) {
            document.title = data_general_data[0].nombrecorto;
        }
        dispatch(getAllPrograms());
        await dispatch(getGeneralData());
        await dispatch(getAllPublications());
        await dispatch(getAllCategories());
    });

    return (
        <>
            <SimpleHeader />
            <Box>
                <ContentPage />
                <Box style={{background: '#FFF'}}>
                    <Divider />
                    <HomeLinks />
                </Box>
            </Box>
            <MKBox mt={4}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default Services;