import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AddCardIcon from '@mui/icons-material/AddCard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Helpers
import { ROOT_SERVER } from 'helpers/helperInit';
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { 
    KEY_SERVICE_ADMISION,
    KEY_SERVICE_NIVELACION,
    KEY_SERVICE_MATRICULA,
    KEY_SERVICE_TRAMITE,
    KEY_SERVICE_CONVOCATORIA,
    KEY_SERVICE_TEST
} from "helpers/helperInit";
import { set_service_type_action } from "redux/actions/publicationAction";
import { set_one_service_action } from "redux/actions/publicationAction";
import PublicationCarousel from "pages/LandingPages/Publications/sections/PublicationCarousel";
import { ROOT_ICONS } from "helpers/helperInit";
import PublicationCardNotice from "pages/LandingPages/Publications/sections/PublicationCardNotice";
import PublicationCardEvent from "pages/LandingPages/Publications/sections/PublicationCardEvent";
import { KEY_PUBLICATION } from "helpers/helperInit";
import { KEY_NOTICIA } from "helpers/helperInit";
import { KEY_EVENT } from "helpers/helperInit";
import HomeServices from "./HomeServices";

const imgPublication = ROOT_SERVER + '/static/images/academyc/noticias1.jpg';
const imgNew = ROOT_SERVER + '/static/images/icons/image2.png';
const iconPublication = ROOT_ICONS + 'publicaciones.jpg';

const responsiveXS = 6;
const responsiveMd = 4;

// const CardService = ({key_service, data }) => {
//     const dispatch = useDispatch();
//     const handleSetKeyService = () => {
//         sessionStorage.setItem("key_service", JSON.stringify(key_service));
//         dispatch(set_one_service_action(null));
//         // dispatch(set_service_type_action(key_service));
//     }

//     return(
//         <Card 
//             component={Link}
//             to={ROOT_SERVER + "/pages/informate"}
//             style={{
//                 border: '1px solid #C2C2C2', 
//                 background: 'transparent',
//                 borderRadius: 0
//             }}
//             onClick={handleSetKeyService}
//         >
//             <Grid container>
//                 <Grid item xs={12}>
//                     <MKBox textAlign="center" style={{background: "#FFF"}}>
//                         <MKTypography display="inline" variant="h6" className='color-four' textTransform="capitalize" fontWeight="bold">
//                             {key_service.name.toUpperCase()}
//                         </MKTypography>
//                     </MKBox>
//                     <MKBox
//                         component="img"
//                         src={key_service.image_name}
//                         width="100%"
//                         zIndex={1}
//                         style={{opacity: 0.8, minHeight: '130px', height: '100px'}}
//                     />
//                     {data !== null ? (
//                         <>
//                             <MKTypography className='color-primary' fontWeight="bold" style={{fontSize: '15px', lineHeight: 1.5, padding: '2px 15px'}}>
//                                 {data.titulo}
//                             </MKTypography>
//                             <MKBox mt={1} mb={3}>
//                                 <MKTypography variant="body2" component="p" style={{fontSize: '15px', textAlign: 'left', lineHeight: 1.5, padding: '2px 15px'}}>
//                                     {data.descripcion !== null ? data.descripcion.substring(0,200) : ""}
//                                 </MKTypography>
//                             </MKBox>
//                         </>
//                     ) : (
//                         <MKBox textAlign="center" style={{height: '130px'}}>
//                             <MKTypography className='color-primary' fontWeight="bold" style={{fontSize: '15px', lineHeight: 1.5, padding: '2px 15px'}}>
//                                 SIN CONTENIDO
//                             </MKTypography>
//                         </MKBox>
//                     )}
//                 </Grid>
//             </Grid>
//         </Card>
//     );
// }

const HomePublicationInstituto = () => {
    // const reducerService = "serviceReducer";
    const reducerPublication = "publicationReducer";
    // const data_services = useSelector(state => state[reducerService].data_services);
    const data_publications = useSelector(state => state[reducerPublication].data_publications);
    const [dataNotices, setDataNotices] = useState(null);
    const [dataPublications, setDataPublications] = useState(null);
    const [dataEvents, setDataEvents] = useState(null);
    // const [dataAdmision, setDataAdmision] = useState(null);
    // const [dataNivelacion, setDataNivelacion] = useState(null);
    // const [dataMatricula, setDataMatricula] = useState(null);
    // const [dataTramite, setDataTramite] = useState(null);
    // const [dataConvocatoria, setDataConvocatoria] = useState(null);
    // const [dataTest, setDataTest] = useState(null);

    const initData = () => {
        // if(data_services !== null) {
        //     let oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_ADMISION.key_service));
        //     if (oneService.length > 0) setDataAdmision(oneService[0]);
            
        //     oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_NIVELACION.key_service));
        //     if (oneService.length > 0) setDataNivelacion(oneService[0]);
    
        //     oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_MATRICULA.key_service));
        //     if (oneService.length > 0) setDataMatricula(oneService[0]);
            
        //     oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_TRAMITE.key_service));
        //     if (oneService.length > 0) setDataTramite(oneService[0]);
    
        //     oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_CONVOCATORIA.key));
        //     if (oneService.length > 0) setDataConvocatoria(oneService[0]);
    
        //     oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_TEST.key));
        //     if (oneService.length > 0) setDataTest(oneService[0]);
        // }
        if(data_publications !== null) {
            let newList = data_publications.filter(item => parseInt(item.fk_categoria) === parseInt(KEY_PUBLICATION));
            setDataPublications(newList);
            newList = data_publications.filter(item => parseInt(item.fk_categoria) === parseInt(KEY_NOTICIA));
            setDataNotices(newList);
            newList = data_publications.filter(item => parseInt(item.fk_categoria) === parseInt(KEY_EVENT));
            setDataEvents(newList);
        }
    }

    useEffect(async () => {
        await initData();
    }, [data_publications]);

    return(
        <>  
        <img src={imgNew} width={30} />
                <MKTypography display="inline" variant="h5" className='color-four' textTransform="capitalize" fontWeight="bold">
                    NUESTROS SERVICIOS
                </MKTypography>
                <hr />
                <Grid item xs={12}>
                    <HomeServices />
                </Grid>
                <Grid item xs={12}>
                    <Box>
                        <div className='color-primary-dark' style={{fontSize: '50px'}}>
                            <AddCardIcon style={{paddingTop: '20px'}} /> 
                            <MKTypography pt={-5} display="inline" variant="h5" className='color-primary-dark' textTransform="capitalize" fontWeight="bold">
                                Publicaciones
                            </MKTypography>
                            <hr style={{border: '1px solid #A21919', marginBottom: '10px'}} />
                        </div>
                        {dataPublications !== null && (
                            <PublicationCarousel data={dataPublications} />
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} lg={9}>
                    <Box>
                        <div className='color-primary-dark' style={{fontSize: '50px'}}>
                            <ReceiptLongIcon style={{paddingTop: '20px'}} /> 
                            <MKTypography pt={-5} display="inline" variant="h5" className='color-primary-dark' textTransform="capitalize" fontWeight="bold">
                                Noticias
                            </MKTypography>
                            <hr style={{border: '1px solid #A21919', marginBottom: '10px'}} />
                        </div>
                        {dataNotices !== null && (
                            <Grid container spacing={1}>
                                <Grid item xs={12} lg={4}>
                                    {dataNotices.length > 0 && (
                                        <PublicationCardNotice data={dataNotices[0]} />
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    {dataNotices.length > 1 && (
                                        <PublicationCardNotice data={dataNotices[1]} />
                                    )}
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    {dataNotices.length > 2 && (
                                        <PublicationCardNotice data={dataNotices[2]} />
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Box>
                        <div className='color-primary-dark' style={{fontSize: '50px'}}>
                            <ReceiptLongIcon style={{paddingTop: '20px'}} /> 
                            <MKTypography pt={-5} display="inline" variant="h5" className='color-primary-dark' textTransform="capitalize" fontWeight="bold">
                                Eventos
                            </MKTypography>
                            <hr style={{border: '1px solid #A21919', marginBottom: '10px'}} />
                        </div>
                        {dataEvents !== null && (
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    {dataEvents.length > 0 && (
                                        <PublicationCardEvent data={dataEvents[0]} />
                                    )}
                                </Grid>
                            </Grid>
                        )}
                    </Box>
                </Grid>             

        </>
    );
}

export default HomePublicationInstituto;