import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import TextField from "@mui/material/TextField";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";


import Card from "@mui/material/Card";
import MKTypography from "components/MKTypography";
import { ROOT_GALERIA } from "helpers/helperInit";

import { ROOT_SERVER } from "helpers/helperInit";
import SchoolSelect from "pages/LandingPages/Programs/sections/SchoolSelect";
import YearSelect from "components/SeveralSelect/YearSelect";
import { getYears } from "helpers/helperInit";

const bgImage = ROOT_GALERIA + 'egresados.jpg';

console.log("bgImage...:", bgImage);

const ListStudents = () => {
    const reducerProgram = "programReducer";
    const reducerStudent = "studentReducer";
    const data_students = useSelector(state => state[reducerStudent].data_students);
    const data_programs = useSelector(state => state[reducerProgram].data_programs);
    const [dataStudents, setDataStudents] = useState(data_students);
    const [defaultSchool, setDefaultSchool] = useState(0);
    const [defaultFinYear, setDefaultFinYear] = useState(0);
    const [valorBusq, setValorBusq] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const arrYears = getYears(2015);

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }

    const handleFindStudent = (e) => {
        setValorBusq(e.target.value);
        let newList = [];
        if(e.target.value === "") {
            newList = data_students;
        } else {
            newList = data_students.filter(item => 
                item.nom_persona.toUpperCase().includes(e.target.value.toUpperCase()) || 
                item.ape_paterno.toUpperCase().includes(e.target.value.toUpperCase()) || 
                item.ape_materno.toUpperCase().includes(e.target.value.toUpperCase())
            );
        }
        if(defaultFinYear > 0) {
            newList = newList.filter(item => parseInt(item.anio_egreso) === parseInt(defaultFinYear));
        }
        if(defaultSchool > 0) {
            newList = newList.filter(item => parseInt(item.id_programa) === parseInt(defaultSchool));
        }
        setDataStudents(newList);
    }

    const handleSelectSchool = (school) => {
        setDefaultSchool(school.id_programa);
        let newList = data_students.filter(item => parseInt(item.id_programa) === parseInt(school.id_programa));
        if(defaultFinYear > 0) {
            newList = newList.filter(item => parseInt(item.anio_egreso) === parseInt(defaultFinYear));
        }
        if(valorBusq !== "") {
            newList = newList.filter(item => 
                item.nom_persona.toUpperCase().includes(valorBusq.toUpperCase()) || 
                item.ape_paterno.toUpperCase().includes(valorBusq.toUpperCase()) || 
                item.ape_materno.toUpperCase().includes(valorBusq.toUpperCase())
            );
        }
        setDataStudents(newList);
    }

    const handleSelectFinYear = (year) => {
        setDefaultFinYear(year.year_id);
        let newList = data_students.filter(item => parseInt(item.anio_egreso) === parseInt(year.year_id));
        if(defaultSchool > 0) {
            newList = newList.filter(item => parseInt(item.id_programa) === parseInt(defaultSchool));
        }
        if(valorBusq !== "") {
            newList = newList.filter(item => 
                item.nom_persona.toUpperCase().includes(valorBusq.toUpperCase()) || 
                item.ape_paterno.toUpperCase().includes(valorBusq.toUpperCase()) || 
                item.ape_materno.toUpperCase().includes(valorBusq.toUpperCase())
            );
        }
        setDataStudents(newList);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0)
    }

    const initData = () => {
        setDataStudents(data_students);
    }

    useEffect(() => {
        initData();
    }, [data_students]);

    return(
        <>
        <Box>
            <div className="carousel-item" style={{ height: '300px', maxHeight: '200px', opacity: 0.2 }}>
                <img src={bgImage} height='100%' width='100%' />
            </div>
            <MKTypography 
                px={3}
                py={1}
                sx={{fontStyle: 'it alic', fontSize: '15px',borderBottom: '1px solid #CCC'}}>
                Nuestros Egresados
            </MKTypography>
            <Grid container py={3} px={3} spacing={1} sx={{background: '#F2F2F2'}}>
                <Grid item xs={6}>
                    <TextField 
                        variant="standard"
                        name="valorBusq"
                        value={valorBusq}
                        placeholder="Buscar..."
                        onChange={handleFindStudent}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={3}>
                    <SchoolSelect dataSchools={data_programs} onSelectSchool={handleSelectSchool} schoolSelected={defaultSchool} />
                </Grid>
                <Grid item xs={3}>
                    <YearSelect dataYears={arrYears} onSelectYear={handleSelectFinYear} yearSelected={defaultFinYear} title="Año de Egreso" />
                </Grid>
            </Grid>
            <Box px={3}>
                <Table>
                    <TableBody>
                        <TableRow hover sx={{fontStyle: 'italic'}}>
                            <TableCell align="left" sx={{fontSize: '14px', borderBottom: '1px solid #CCC'}}>Escuela</TableCell>
                            <TableCell align="center" sx={{fontSize: '14px', borderBottom: '1px solid #CCC'}}>Código</TableCell>
                            <TableCell align="left" sx={{fontSize: '14px', borderBottom: '1px solid #CCC'}}>Nombre</TableCell>
                            <TableCell align="center" sx={{fontSize: '14px', borderBottom: '1px solid #CCC'}}>Inició</TableCell>
                            <TableCell align="center" sx={{fontSize: '14px', borderBottom: '1px solid #CCC'}}>Egresó</TableCell>
                            </TableRow>
                        {dataStudents && dataStudents
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(student => {
                            return(
                                <TableRow key={"student" + student.id_alumno} hover>
                                    <TableCell align="center" sx={{fontSize: '14px', borderBottom: '1px solid #CCC', fontStyle: 'italic'}}>{student.codigo}</TableCell>
                                    <TableCell align="left" sx={{fontSize: '14px', borderBottom: '1px solid #CCC'}}>
                                        {student.nom_persona} {student.ape_paterno} {student.ape_materno}
                                    </TableCell>
                                    <TableCell align="left" sx={{fontSize: '14px', borderBottom: '1px solid #CCC'}}>{student.nombreprograma}</TableCell>
                                    <TableCell align="center" sx={{fontSize: '14px', borderBottom: '1px solid #CCC'}}>{student.anio_inicio}</TableCell>
                                    <TableCell align="center" sx={{fontSize: '14px', borderBottom: '1px solid #CCC'}}>{student.anio_egreso}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                <TablePagination
                    component='div'
                    rowsPerPageOptions={[5,10,50,100]}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={dataStudents ? dataStudents.length : 0 }
                    labelRowsPerPage=''
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Box>
        </Box>
        </>
    );
}

export default ListStudents;