import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Mui Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TablePagination from '@mui/material/TablePagination';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table'
import TableHead from "@mui/material/TableHead";
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import Button from "@mui/material/Button";

import ConfirmationDialog from "components/ConfirmationDialog";
import { set_one_program_action } from "redux/actions/publicationAction";
import { getAllPrograms } from "redux/actions/publicationAction";
import { saveDeleteProgram } from "redux/actions/publicationAction";

const ProgramsList = ({onChangeAction}) => {
    const dispatch = useDispatch();
    const reducerProgram = "programReducer";
    const data_programs = useSelector(state => state[reducerProgram].data_programs);
    const [dataPrograms, setDataPrograms] = useState(null);
    const [deleteProgram, setDeleteProgram] = useState({
        id_programa: 0
    });
    const [stateConfirmDialog, setStateConfirmDialog] = useState({
        open: false,
        title: '',
        content: ''
    });
    const [valuesPage, setValuesPage] = useState({
        category_id: 0,
    })
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0)
    }

    const handleSetAction = () => {
        dispatch(set_one_program_action(null));
        onChangeAction("register");
    }

    const handleEditProgram = async (program) => {
        await dispatch(set_one_program_action(program));
        onChangeAction("register");
    }

    const handleOpenConfirmDialog = (program) => {
        setDeleteProgram({
            id_programa: parseInt(program.id_programa)
        });
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Registro",
            content: "¿Seguro de Eliminar el registro seleccionado?"
        });
    }

    const handleCloseConfirmDialog = async (value) => {
        if(value === "Yes") {
            const newDataProgram = {
                "id_programa": deleteProgram.id_programa
            };
			const rptaSave = await saveDeleteProgram(newDataProgram);
			await dispatch(getAllPrograms());
        }
        setStateConfirmDialog({
            open: false,
            title: '',
            content: ''
        });
    }

    useEffect(() => {
        setDataPrograms(data_programs);
    },[data_programs, valuesPage]);

    return (
        <>
            <Container>
                <Grid container pt={3}>
                    <Grid item xs={12} textAlign='right'>
                        <Button 
                            variant='contained' 
                            color='secondary' 
                            style={{color: '#FFF'}}
                            onClick={handleSetAction}
                        >
                            GENERAR NUEVA
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
                <Table>
                    <TableBody>
                        {dataPrograms !== null && dataPrograms
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(program => {
                            return(
                                <TableRow key={program.id_programa} style={{verticalAlign: 'top'}}>
                                    <TableCell style={{borderBottom: '1px solid #CCC'}}>
                                        <MKTypography className='color-primary' style={{cursor: 'pointer', fontStyle: 'italic', fontSize: '16px'}}>
                                            {program.nombreprograma}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell style={{borderBottom: '1px solid #CCC'}}>
                                        <MKTypography variant="body2" opacity={0.8} mb={2}>
                                            {program.descripcion !== null ? program.descripcion.substring(0, 250) : ""}...
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align="right" style={{borderBottom: '1px solid #CCC'}}>
                                        <Tooltip title='Editar'>
                                            <IconButton onClick={() => handleEditProgram(program)}>
                                                <EditIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Anular'>
                                            <IconButton onClick={() => handleOpenConfirmDialog(program)}>
                                                <DeleteIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}            
                    </TableBody>
                </Table>
                <TablePagination
                    component='div'
                    rowsPerPageOptions={[5,10,50,100]}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={dataPrograms ? dataPrograms.length : 0 }
                    labelRowsPerPage=''
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <ConfirmationDialog 
                    open={stateConfirmDialog.open}
                    title={stateConfirmDialog.title}
                    content={stateConfirmDialog.content}
                    onClose={handleCloseConfirmDialog}
                />
            </Container>
        </>
    )
}

export default ProgramsList;