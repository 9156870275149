import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Helmet } from 'react-helmet'

// @mui material components
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";
import SimpleHeader from "examples/Headers/SimpleHeader";

// Routes
import footerRoutes from "footer.routes";

// Helpers
import { ROOT_SERVER } from 'helpers/helperInit';
// import Header from "./sections/Header";
// import HomeAboutUs from "./sections/HomeAboutUs";
// import HomePublication from "./sections/HomePublication";
// import HomeLinks from "./sections/HomeLinks";
import { getAllPublications } from "redux/actions/publicationAction";
// import HeaderHomeMorropon from "./sections/HeaderHomeMorropon";
import { SHOW_HEADER } from "helpers/helperInit";
import PageContent from "./sections/PageContent";
import { getAllStudents } from "redux/actions/notesAction";
import { getGeneralData } from "redux/actions/generalDataAction";
import HomeLinks from "pages/Home/sections/HomeLinks";
import { Grid } from "@mui/material";
import AbautLinkMain from "../AboutUs/sections/AbautLinkMain";
import ProgramLinkMain from "../Programs/sections/ProgramLinkMain";
import ServiceLinkMain from "../Services/sections/ServiceLinkMain";
import HomeServices from "pages/Home/sections/HomeServices";
import { getAllPrograms } from "redux/actions/publicationAction";
import { getAllServices } from "redux/actions/publicationAction";

function Students() {
  const dispatch = useDispatch();
  sessionStorage.removeItem('hasConn');
  
  useEffect(async() => {
    dispatch(getGeneralData());
    dispatch(getAllStudents());
    dispatch(getAllPrograms());
    dispatch(getAllServices());
  });

  return (
    <>
      <SimpleHeader />
      <Box sx={{background: '#FFF'}}>
        <PageContent />
        <Divider />
        <Box sx={{background: "#FFF"}} px={3}>
          <Grid container pb={4}>
            <Grid item xs={12} lg={4}>
              <AbautLinkMain />
            </Grid>
            <Grid item xs={12} lg={4} pl={2}>
              <ProgramLinkMain />
            </Grid>
            <Grid item xs={12} lg={4} pl={2}>
              <ServiceLinkMain />
            </Grid>
          </Grid>
          {/* <HomeServices />  */}
          <HomeLinks />
        </Box>
      </Box>
      <MKBox mt={4}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Students;
