import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import MKTypography from "components/MKTypography";
import { ROOT_SERVER } from "helpers/helperInit";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import { ROOT_FILES_PDF } from "helpers/helperInit";
import FirstCarousel from "components/Carousel";
import { useEffect } from "react";

const imgPdf = ROOT_SERVER + '/static/images/icons/pdf.png';
const filePDF = ROOT_SERVER + '/static/files/OFICIO.pdf';

const ContentPublication = () => {
    const reducerPublication = "publicationReducer";
    const reducerGeneralData = "generalDataReducer";
    const one_publication = useSelector(state => state[reducerPublication].one_publication);
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);

    let pathImg = "";
    let pathPdf = "";
    if(data_general_data.length > 0) {
        pathImg = data_general_data[0].url_img_publicaciones;
        pathPdf = data_general_data[0].url_img_publicaciones;
    }

    // useEffect(() => {

    // }, one_publication);

    return(
        <Box
            heigth="100%"
            // minHeight={"1190px"}
            width="100%"
            px={6}
            py={5}
            sx={{
                background: "#FFF",
                backgroundPosition: "left",
                placeItems: "left",
                align: 'left',
            }}
        >
            {(one_publication && one_publication.id_publicacion) && (
                <Box>
                    <MKTypography variant="h2" mb={1} className='color-primary'>
                        {one_publication.titulo}
                    </MKTypography>
                    <MKTypography variant="body2" mb={1} color='text'>
                        {one_publication.descripcion}
                    </MKTypography>
                    <FirstCarousel images={one_publication.imagenes}  path={pathImg} />
                </Box>
            )}
        </Box>
    );
}

export default ContentPublication;