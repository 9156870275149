import React, { Component, useEffect } from 'react';
import { Link } from "react-router-dom";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { ROOT_SERVER } from 'helpers/helperInit';

// import './css/my-style.css';
import { ROOT_GALERIA } from 'helpers/helperInit';
import MKTypography from 'components/MKTypography';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { set_one_publication_action } from 'redux/actions/publicationAction';
import { KEY_PUBLICATION } from 'helpers/helperInit';

const PublicationCarousel = ({data}) => {
    const dispatch = useDispatch();
    const reducerGeneralData = "generalDataReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);

    const handleLoadData = (publication) => {
        sessionStorage.setItem("key_type_publication", KEY_PUBLICATION);
        dispatch(set_one_publication_action(publication));
    }

    let nomImg = "";
    let pathImg = "";
    if(data_general_data.length > 0) {
        pathImg = data_general_data[0].url_img_publicaciones;
        nomImg = pathImg + "empty.jpg";
    }

    // useEffect(() => {

    // }, []);

    return (
        <>
        {data.length > 0} {
            <Carousel autoPlay infiniteLoop	interval={2000}
            >
                {data && data.map(data => {
                    nomImg = data.imagenes.length > 0 ? data.imagenes[0].nombreimagen + "." + data.imagenes[0].formato : "empty.jpg" ;
                    // nomImg = returnImage();
                    return(
                        <Box 
                            key={data.id_publicacion}
                            component={Link}
                            to={ROOT_SERVER + "/pages/publicaciones"}
                            onClick={() => handleLoadData(data)}
                        >
                            <div style={{ height: '350px' }}>
                                {/* <img src={"http://localhost:2800/imagenes/publicaciones/educacion.jpg"} style={{maxHeight: '350px'}} /> */}
                                <img src={pathImg + nomImg} style={{maxHeight: '350px'}} />
                            </div>        
                            <MKTypography px={2} pt={2} className='color-primary' fontWeight='bold' style={{background: '#FFF'}}>
                                {data.titulo}
                            </MKTypography>
                            <MKTypography px={2} style={{background: '#FFF', color: '#000', fontSize: '14px'}} textAlign= 'left'>
                                {data.descripcion.substring(0,150)}...
                            </MKTypography>
                        </Box>
                    );
                })}
            </Carousel>    
        }
        </>

        // <Carousel autoPlay infiniteLoop	interval={2000}>
        //     {images && images.map(image => {
        //         return(
        //             <div key={image.id_programa_imagen} style={{ minHeight: '500px' }}>
        //                 {/* <img src={img1} /> */}
        //                 <img src={ROOT_GALERIA + image.nombreimagen} />
        //             </div>        
        //         );
        //     })}
        // </Carousel>
    );
}

export default PublicationCarousel;