import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import CalendarMonthIcon from '@mui/icons-material/CalendarToday';

import MKBox from 'components/MKBox';
import { ROOT_SERVER } from 'helpers/helperInit';
import MKTypography from 'components/MKTypography';
import { 
    KEY_SERVICE_ADMISION,
    KEY_SERVICE_NIVELACION,
    KEY_SERVICE_MATRICULA,
    KEY_SERVICE_TRAMITE,
    KEY_SERVICE_CONVOCATORIA,
    KEY_SERVICE_TEST
} from "helpers/helperInit";
import { set_one_service_action } from "redux/actions/publicationAction";
import { set_service_type_action } from 'redux/actions/publicationAction';
import { KEY_PUBLICATION } from 'helpers/helperInit';
import { KEY_NOTICIA } from 'helpers/helperInit';
import { KEY_EVENT } from 'helpers/helperInit';
import { set_one_publication_action } from 'redux/actions/publicationAction';
import { set_publication_type_action } from 'redux/actions/publicationAction';
import { TYPE_PUBLICATION } from 'helpers/helperInit';

const CardService = ({key_public, data }) => {
    const dispatch = useDispatch();

    const oneTyePubl = TYPE_PUBLICATION.filter(item => parseInt(item.id_type_publication) === parseInt(key_public));
    
    const handleLoadData = () => {
        sessionStorage.setItem("key_type_publication", key_public);
        dispatch(set_one_publication_action(data));
        dispatch(set_publication_type_action(key_public));
    }

    return(
        <Card 
            onClick={() => handleLoadData(data)}
            style={{
                background: 'transparent',
                borderRadius: 0,
                background: "#FFF",
                minHeight: '180px',
                border: '1px solid #ECECEC',
                cursor: 'pointer'
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <MKBox textAlign="left" style={{background: "#FFF"}}>
                        <MKTypography pl={2} display="inline" textTransform="capitalize" style={{fontSize: '12px'}}>
                            {oneTyePubl[0].name}
                        </MKTypography>
                    </MKBox>
                    <hr style={{margin: '0px 10px', border: '1px solid #CCC'}} />
                    {/* <Divider /> */}
                    <MKTypography pl={5} pt={3} style={{fontSize: '16px', color: "#0C83D6"}}>
                        {data.titulo.substring(0,100)}...
                    </MKTypography>
                    <Box pl={5} pt={1}>
                        <div style={{fontSize: '15px', color: "#7D7D7E"}}>
                            <CalendarMonthIcon /> <p style={{fontSize: '11px', display: 'inline'}}>Publicado: {data.fecha.substring(0,10)}</p>
                        </div>
                    </Box>
                    {/* <Box px={2} pt={3}>
                        <button style={{width: '100%', height: '25px', background: '#5591EA', borderRadius: '3px', color: '#FFF', fontSize: '11px', border: 'none'}}>Mas Información</button>
                    </Box> */}
                </Grid>
            </Grid>
        </Card>
    );
}

const LinkFirstType = () => {
    const reducerPublication = "publicationReducer";
    const data_publications = useSelector(state => state[reducerPublication].data_publications);
    const [dataPublication, setDataPublication] = useState(null);
    const [dataNotice, setDataNotice] = useState(null);
    const [dataEvent, setDataEvent] = useState(null);

    const initData = () => {
        if(data_publications !== null) {
            let onePubl = data_publications.filter(item => parseInt(item.fk_categoria) === parseInt(KEY_PUBLICATION));
            if (onePubl.length > 0) setDataPublication(onePubl[0]);
            
            onePubl = data_publications.filter(item => parseInt(item.fk_categoria) === parseInt(KEY_NOTICIA));
            if (onePubl.length > 0) setDataNotice(onePubl[0]);
    
            onePubl = data_publications.filter(item => parseInt(item.fk_categoria) === parseInt(KEY_EVENT));
            if (onePubl.length > 0) setDataEvent(onePubl[0]);            
        }
    }

    useEffect(async () => {
        await initData();
    });

    return(
        <Box
            heigth="100%"
            // minHeight={"1190px"}
            width="100%"
            className='bg-primary-opacity'
            sx={{
                backgroundPosition: "left",
                placeItems: "left",
                align: 'left',
                padding: '10px'
            }}
        >
            <Grid container spacing={3}>
                {dataPublication !== null && (
                    <Grid item xs={12}>
                        <CardService key_public={KEY_PUBLICATION} data={dataPublication} />
                    </Grid>
                )}
                {dataNotice !== null && (
                    <Grid item xs={12}>
                        <CardService key_public={KEY_NOTICIA} data={dataNotice} />
                    </Grid>
                )}
                {dataEvent !== null && (
                    <Grid item xs={12}>
                        <CardService key_public={KEY_EVENT} data={dataEvent} />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

export default LinkFirstType;