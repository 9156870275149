import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import Container from '@mui/material/Container'

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { saveNewPublication } from 'redux/actions/publicationAction';
import { openNotifAction } from 'redux/actions/severalAction';
import { saveEditPublication } from 'redux/actions/publicationAction';
import MKTypography from 'components/MKTypography';
import { getAllServices } from 'redux/actions/publicationAction';
import { SignalCellularNull } from '@mui/icons-material';
import { saveNewService } from 'redux/actions/publicationAction';
import { SERVICE_SHOW_DETAILS } from 'helpers/helperInit';
import TableHead from 'assets/theme/components/table/tableHead';
import { dateFormatYYMMDD } from "helpers/helperInit";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { saveNewModality } from 'redux/actions/publicationAction';
import { saveNewRequirement } from 'redux/actions/publicationAction';
import { saveNewCost } from 'redux/actions/publicationAction';
import { saveNewCronograma } from 'redux/actions/publicationAction';
import { saveNewResult } from 'redux/actions/publicationAction';
import { saveNewAttached } from 'redux/actions/publicationAction';
import { saveEditService } from 'redux/actions/publicationAction';
import { Box } from '@mui/system';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { saveLoadFileImage } from 'redux/actions/severalAction';
import { saveLoadFilePDF } from 'redux/actions/severalAction';

const RegisterService = ({onChangeAction, id_type_service}) => {
    const dispatch = useDispatch();
    const reducerService = "serviceReducer";
    const one_service = useSelector(state => state[reducerService].one_service);
    const [serviceShowDetail, setServiceShowDetail] = useState(null);
    const [title, setTitle] = useState("Nuevo Registro");
    const [values, setValues] = useState({
        "id_servicio": 0,
        "titulo": "",
        "descripcion": "",
        "fk_tiposervicio": id_type_service
    });
    const [editing, setEditing] = useState(false);
    const [idElementEdit, setIdElementEdit] = useState(0);
    const [nomElemeDelete, setNomElemenrDelete] = useState("");
    const [dataModality, setDataModality] = useState([]);
    const [valuesModality, setValuesModality] = useState({
        titulo: "",
        descripcion: ""
    });
    const [openDialogModality, setOpenDialogModality] = useState(false);

    const [dataRequirement, setDataRequirement] = useState([]);
    const [valuesRequirement, setValuesRequirement] = useState({
        titulo: "",
        descripcion: ""
    });
    const [openDialogRequirement, setOpenDialogRequirement] = useState(false);
    
    const [dataCosts, setDataCosts] = useState([]);
    const [valuesCost, setValuesCost] = useState({
        descripcion: "",
        importe: 0
    });
    const [openDialogCost, setOpenDialogCost] = useState(false);
    
    const [dataCronograma, setDataCronograma] = useState([]);
    const [valuesCronograma, setValuesCronograma] = useState({
        descripcion: "",
        fecha_inicio: "",
        fecha_fin: ""
    });
    const [openDialogCronograma, setOpenDialogCronograma] = useState(false);
    
    const [dataResults, setDataResults] = useState([]);
    const [valuesResult, setValuesResult] = useState({
        descripcion: "",
        pdfresulados: "",
    });
    const [openDialogResult, setOpenDialogResult] = useState(false);

    const [dataAttacheds, setDataAttacheds] = useState([]);
    const [valuesAttached, setValuesAttached] = useState({
        descripcion: "",
        nombrearchivo: "",
        formato: "",
    });
    const [openDialogAttached, setOpenDialogAttached] = useState(false);
    const [stateConfirmDialog, setStateConfirmDialog] = useState({
        open: false,
        title: '',
        content: ''
    });
    const [selectedFile, setSelectedFile] = useState(null);

    const handleSaveService = async () => {
        if(values.titulo === "" || values.descripcion === "") {
            dispatch(openNotifAction(["Datos incompletos", "error", 'filled']));
            return;
        }
        let idNewServ = values.id_servicio;
        if(parseInt(values.id_servicio) === 0) {
            const rptaSave = await saveNewService(values);
            idNewServ = rptaSave.data[0].idservicio;
        } else {
            const rptaSave = await saveEditService(values);
        }
        for(var i = 0; i < dataModality.length; i++) {
            dataModality[i].fk_servicio = idNewServ;
            const rptaModality = await saveNewModality(dataModality[i]);
        }

        for(var i = 0; i < dataRequirement.length; i++) {
            dataRequirement[i].fk_servicio = idNewServ;
            const rptaRequirement = await saveNewRequirement(dataRequirement[i]);
        }
        
        for(var i = 0; i < dataCosts.length; i++) {
            dataCosts[i].fk_servicio = idNewServ;
            const rptaCost = await saveNewCost(dataCosts[i]);
        }
        
        for(var i = 0; i < dataCronograma.length; i++) {
            dataCronograma[i].fk_servicio = idNewServ;
            const rptaCronograma = await saveNewCronograma(dataCronograma[i]);
        }
        
        for(var i = 0; i < dataResults.length; i++) {
            dataResults[i].fk_servicio = idNewServ;
            const rptaResult = await saveNewResult(dataResults[i]);
        }
        
        for(var i = 0; i < dataAttacheds.length; i++) {
            dataAttacheds[i].fk_servicio = idNewServ;
            const rptaAttached = await saveNewAttached(dataAttacheds[i]);
        }

        await dispatch(getAllServices());
        onChangeAction("list");
    }

    const handleSetAction = () => {
        onChangeAction("list");
    }

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const handleOpenDialogModality = (modality) => {
        if(modality === null) {
            setValuesModality({
                titulo: "",
                descripcion: ""
            });
            setEditing(false);
            setIdElementEdit(0);
        } else {
            setValuesModality({
                titulo: modality.titulo !== null ? modality.titulo : "",
                descripcion: modality.descripcion !== null ? modality.descripcion : ""
            });
            setEditing(true);
            setIdElementEdit(modality.id_servicio_modalidad);
        }
        setOpenDialogModality(true);
    }
    
    const handleOpenDialogRequirement = (requirement) => {
        if(requirement === null) {
            setValuesRequirement({
                titulo: "",
                descripcion: ""
            });
            setEditing(false);
            setIdElementEdit(0);
        } else {
            setValuesRequirement({
                titulo: requirement.titulo !== null ? requirement.titulo : "",
                descripcion: requirement.descripcion !== null ? requirement.descripcion : ""
            });
            setEditing(true);
            setIdElementEdit(requirement.id_servicio_requisito);
        }
        
        setOpenDialogRequirement(true);
    }
    
    const handleOpenDialogCost = (cost) => {
        if(cost === null) {
            setValuesCost({
                descripcion: "",
                importe: 0
            });
            setEditing(false);
            setIdElementEdit(0);
        } else {
            setValuesCost({
                descripcion: cost.descripcion !== null ? cost.descripcion : "",
                importe: cost.importe !== null ? cost.importe : 0
            });
            setEditing(true);
            setIdElementEdit(cost.id_servicio_costo);
        }
        
        setOpenDialogCost(true);
    }
    
    const handleOpenDialogCronograma = (cronograma) => {
        if(cronograma === null) {
            setValuesCronograma({
                descripcion: "",
                fecha_inicio: "",
                fecha_fin: ""
            });
            setEditing(false);
            setIdElementEdit(0);
        } else {
            setValuesCronograma({
                descripcion: cronograma.descripcion !== null ? cronograma.descripcion : "",
                fecha_inicio: cronograma.fecha_inicio !== null ? cronograma.fecha_inicio : "",
                fecha_fin: cronograma.fecha_fin !== null ? cronograma.fecha_fin : "",
            });
            setEditing(true);
            setIdElementEdit(cronograma.id_servicio_cronograma);
        }
        
        setOpenDialogCronograma(true);
    }
    
    const handleOpenDialogResult = (result) => {
        if(result === null) {
            setValuesResult({
                descripcion: "",
                pdfresulados: ""
            });
            setEditing(false);
            setIdElementEdit(0);
        } else {
            setValuesResult({
                descripcion: result.descripcion !== null ? result.descripcion : "",
                pdfresulados: result.pdfresulados !== null ? result.pdfresulados : "",
            });
            setEditing(true);
            setIdElementEdit(result.id_servicio_resultado);
        }
        setOpenDialogResult(true);
    }
    
    const handleOpenDialogAttached = (attached) => {
        if(attached === null) {
            setValuesAttached({
                descripcion: "",
                nombrearchivo: "",
                formato: ""
            });
            setEditing(false);
            setIdElementEdit(0);
        } else {
            setValuesAttached({
                descripcion: attached.descripcion !== null ? attached.descripcion: "",
                nombrearchivo: attached.nombrearchivo !== null ? attached.nombrearchivo: "",
                formato: attached.formato !== null ? attached.formato: "",
            });
            setEditing(true);
            setIdElementEdit(attached.id_servicio_archivo);
        }
        setOpenDialogAttached(true);
    }

    const handleChangeModality = (e) => {
        setValuesModality({...valuesModality, [e.target.name]: e.target.value})
    }
    
    const handleChangeRequirement = (e) => {
        setValuesRequirement({...valuesRequirement, [e.target.name]: e.target.value})
    }
    
    const handleChangeCost = (e) => {
        setValuesCost({...valuesCost, [e.target.name]: e.target.value})
    }
    
    const handleChangeCronograma = (e) => {
        setValuesCronograma({...valuesCronograma, [e.target.name]: e.target.value})
    }

    const handleChangeFInicio = (value) => {
        const strDate = dateFormatYYMMDD(value);
        setValuesCronograma({ ...valuesCronograma, fecha_inicio: strDate });
    }
    
    const handleChangeFFin = (value) => {
        const strDate = dateFormatYYMMDD(value);
        setValuesCronograma({ ...valuesCronograma, fecha_fin: strDate });
    }

    const handleChangeResult = (e) => {
        setValuesResult({...valuesResult, [e.target.name]: e.target.value})
    }

    const onFileResultChange = (event) => {
        // setValuesResult({ ...valuesResult, pdfresulados: event.target.files[0].name });
        setSelectedFile({ selectedFile: event.target.files[0] });
    };

    const handleChangeAttached = (e) => {
        setValuesAttached({...valuesAttached, [e.target.name]: e.target.value})
    }

    const onFileAttachedChange = (event) => {
        // const nameExte = event.target.files[0].name.split('.')
        // setValuesAttached({ ...valuesAttached, nombrearchivo: nameExte[0], formato: nameExte[1] });
        setSelectedFile({ selectedFile: event.target.files[0] });
    };

    const onFilePdfUpload = async () => {
        if(selectedFile === null) {
            dispatch(openNotifAction(["Seleccione un archivo", "error", "filled"]));
            return null;
        }
        const formData = new FormData();
        formData.append(
            "myFile",
            selectedFile.selectedFile,
            selectedFile.selectedFile.name,
        );
        let rptaUpload = await saveLoadFilePDF(formData);
        return rptaUpload.filename;
    };

    const handleSetModality = () => {
        if(!editing) {
            dataModality.push(valuesModality);
        } else {
            dataModality.map(item => {
                if(parseInt(item.id_servicio_modalidad) === parseInt(idElementEdit)) {
                    item.titulo = valuesModality.titulo;
                    item.descripcion = valuesModality.descripcion;
                    return;
                }
            })
        }
        setOpenDialogModality(false);
    }
    
    const handleSetRequirement = () => {
        if(!editing) {
            dataRequirement.push(valuesRequirement);
        } else {
            dataRequirement.map(item => {
                if(parseInt(item.id_servicio_requisito) === parseInt(idElementEdit)) {
                    item.titulo = valuesRequirement.titulo;
                    item.descripcion = valuesRequirement.descripcion;
                    return;
                }
            });
        }
        setOpenDialogRequirement(false);
    }
    
    const handleSetCost = () => {
        if(!editing) {
            dataCosts.push(valuesCost);
        } else {
            dataCosts.map(item => {
                if(parseInt(item.id_servicio_costo) === parseInt(idElementEdit)) {
                    item.descripcion = valuesCost.descripcion;
                    item.importe = valuesCost.importe;
                    return;
                }
            });
        }
        setOpenDialogCost(false);
    }

    const handleSetCronograma = () => {
        if(!editing) {
            dataCronograma.push(valuesCronograma);
        } else {
            dataCronograma.map(item => {
                if(parseInt(item.id_servicio_cronograma) === parseInt(idElementEdit)) {
                    item.descripcion = valuesCronograma.descripcion;
                    item.fecha_inicio = valuesCronograma.fecha_inicio.substring(0,10);
                    item.fecha_fin = valuesCronograma.fecha_fin.substring(0,10);
                    return;
                }
            });
        }
        setOpenDialogCronograma(false);
    }

    const handleSetResult = async () => {
        const fileName = await onFilePdfUpload();
        if(fileName !== null) {
            if(!editing) {
                const newElem = {
                    descripcion: valuesResult.descripcion,
                    pdfresulados: fileName
                }
                dataResults.push(newElem);
            } else {
                dataResults.map(item => {
                    if(parseInt(item.id_servicio_resultado) === parseInt(idElementEdit)) {
                        item.descripcion = valuesResult.descripcion;
                        item.pdfresulados = fileName
                        return;
                    }
                });
            }
            setOpenDialogResult(false);
        }
    }
    
    const handleSetAttached = async () => {
        const fileName = await onFilePdfUpload();
        const nameExte = fileName.split('.');
        if(fileName !== null) {
            if(!editing) {
                const newElem = {
                    descripcion: valuesAttached.descripcion,
                    nombrearchivo: nameExte[0],
                    formato: nameExte[1]
                }
                dataAttacheds.push(newElem);
            } else {
                dataAttacheds.map(item => {
                    if(parseInt(item.id_servicio_archivo) === parseInt(idElementEdit)) {
                        item.descripcion = valuesAttached.descripcion;
                        item.nombrearchivo = nameExte[0];
                        item.formato = nameExte[1];
                        return;
                    }
                });
            }
            setOpenDialogAttached(false);
        }
    }

    const handleCloseDialog = () => {
        setOpenDialogModality(false);
        setOpenDialogRequirement(false);
        setOpenDialogCost(false);
        setOpenDialogCronograma(false);
        setOpenDialogResult(false);
        setOpenDialogAttached(false);
    };

    const handleDeleteModality = (modality) => {
        setIdElementEdit(modality.id_servicio_modalidad);
        setNomElemenrDelete("modality");
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Modalidad",
            content: "¿Seguro de Eliminar la modalidad seleccionada?"
        });
    }
    
    const handleDeleteRequirement = (requirement) => {
        setIdElementEdit(requirement.id_servicio_requisito);
        setNomElemenrDelete("requirement");
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Requisito",
            content: "¿Seguro de Eliminar el requisito seleccionado?"
        });
    }
    
    const handleDeleteCost = (cost) => {
        setIdElementEdit(cost.id_servicio_costo);
        setNomElemenrDelete("cost");
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Costo",
            content: "¿Seguro de Eliminar el costo seleccionado?"
        });
    }
    
    const handleDeleteCronograma = (cronograma) => {
        setIdElementEdit(cronograma.id_servicio_cronograma);
        setNomElemenrDelete("cronograma");
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Cronograma",
            content: "¿Seguro de Eliminar el cronograma seleccionado?"
        });
    }
    
    const handleDeleteResult = (result) => {
        setIdElementEdit(result.id_servicio_resultado);
        setNomElemenrDelete("result");
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Resultado",
            content: "¿Seguro de Eliminar el resultado seleccionado?"
        });
    }
    
    const handleDeleteAttached = (attached) => {
        setIdElementEdit(attached.id_servicio_archivo);
        setNomElemenrDelete("attached");
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Archivo",
            content: "¿Seguro de Eliminar el archivo seleccionado?"
        });
    }

    const handleCloseConfirmDialog = async (value) => {
        if(value === "Yes") {
            if(nomElemeDelete === "modality"){
                const newList = dataModality.filter(item => parseInt(item.id_servicio_modalidad) !== parseInt(idElementEdit));
                setDataModality(newList);
            }
            if(nomElemeDelete === "requirement"){
                const newList = dataRequirement.filter(item => parseInt(item.id_servicio_requisito) !== parseInt(idElementEdit));
                setDataRequirement(newList);
            }
            if(nomElemeDelete === "cost"){
                const newList = dataCosts.filter(item => parseInt(item.id_servicio_costo) !== parseInt(idElementEdit));
                setDataCosts(newList);
            }
            if(nomElemeDelete === "cronograma"){
                const newList = dataCronograma.filter(item => parseInt(item.id_servicio_cronograma) !== parseInt(idElementEdit));
                setDataCronograma(newList);
            }
            if(nomElemeDelete === "result"){
                const newList = dataResults.filter(item => parseInt(item.id_servicio_resultado) !== parseInt(idElementEdit));
                setDataResults(newList);
            }
            if(nomElemeDelete === "attached"){
                const newList = dataAttacheds.filter(item => parseInt(item.id_servicio_archivo) !== parseInt(idElementEdit));
                setDataAttacheds(newList);
            }
        }
        setStateConfirmDialog({
            open: false,
            title: '',
            content: ''
        });
        setIdElementEdit(0);
        setNomElemenrDelete("");
    }

    const initData = () => {
        if(one_service !== null) {
            setValues({
                id_servicio: one_service.id_servicio, 
                titulo: one_service.titulo,
                descripcion: one_service.descripcion,
                fk_tiposervicio: one_service.id_tiposervicio
            });
            setDataModality(one_service.modalidad);
            setDataRequirement(one_service.requisito);
            setDataCosts(one_service.costo);
            setDataCronograma(one_service.cronograma);
            setDataResults(one_service.resultado);
            setDataAttacheds(one_service.archivo);
            setTitle("Actualizando registro");
        }
        const oneTypeService = SERVICE_SHOW_DETAILS.filter(item => parseInt(item.id_type_service) === parseInt(id_type_service));
        setServiceShowDetail(oneTypeService[0]);
    }

    useEffect(() => {
        initData();
    }, [one_service]);

    return(
        <>
        <MKTypography className='color-primary'>
            {title}
        </MKTypography>
        <Card >
            <Container sx={{textAlign: 'right', paddingTop: '5px'}}>
                <Button 
                    variant='contained' 
                    color='primary' 
                    style={{color: '#FFF', marginRight: '5px'}}
                    onClick={handleSaveService}
                >
                    GUARDAR
                </Button>
                <Button 
                    variant='contained' 
                    color='secondary' 
                    style={{color: '#FFF'}}
                    onClick={handleSetAction}
                >
                    CANCELAR
                </Button>
            </Container>
            <Divider />
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField 
                            variant="standard"
                            label="Título"
                            name="titulo"
                            value={values.titulo}
                            placeholder="Título"
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} pb={1}>
                        <TextField
                            variant="standard"
                            label="Descripción"
                            name="descripcion"
                            value={values.descripcion}
                            placeholder="Descripción"
                            multiline
                            fullWidth
                            rows={4}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
                {(serviceShowDetail !== null && !!serviceShowDetail.show.show_modalidad) && (
                    <>
                    <MKTypography pt={5} mb={1} pr={3} display='inline' sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                        <u>Modalidad</u>
                    </MKTypography>
                    <Button 
                        variant='contained' 
                        style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                        onClick={() => handleOpenDialogModality(null)}
                    >
                        <AddIcon />
                    </Button>
                    <Table>
                        <TableBody>
                            {dataModality.map((modality, index) => {
                                return(
                                    <TableRow hover key={"key" + index}>
                                        <TableCell style={{width: '40%'}}>
                                            <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}} pl={2}>
                                                {modality.titulo !== null ? modality.titulo : ""}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell style={{width: '60%'}}>
                                            <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={2}>
                                                {modality.descripcion !== null ? modality.descripcion : ""}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Box sx={{width: '80px'}}>
                                                <Tooltip title='Editar'>
                                                    <IconButton onClick={() => handleOpenDialogModality(modality)}>
                                                        <EditIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Anular'>
                                                    <IconButton onClick={() => handleDeleteModality(modality)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    </>
                )}
                <Divider />
                {(serviceShowDetail !== null && !!serviceShowDetail.show.show_requisitos) && (
                    <>
                    <MKTypography pt={5} mb={1} pr={3} display='inline' sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                        <u>Requisitos</u>
                    </MKTypography>
                    <Button 
                        variant='contained' 
                        // color='secondary' 
                        style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                        onClick={() => handleOpenDialogRequirement(null)}
                    >
                        <AddIcon />
                    </Button>
                    <Table>
                        <TableBody>
                            {dataRequirement.map((requirement, index) => {
                                return(
                                    <TableRow hover key={"key" + index}>
                                        <TableCell style={{width: '40%'}}>
                                            <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}} pl={2}>
                                                {requirement.titulo !== null ? requirement.titulo : ""}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell style={{width: '60%'}}>
                                            <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={2}>
                                                {requirement.descripcion}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Box sx={{width: '80px'}}>
                                                <Tooltip title='Editar'>
                                                    <IconButton onClick={() => handleOpenDialogRequirement(requirement)}>
                                                        <EditIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Anular'>
                                                    <IconButton onClick={() => handleDeleteRequirement(requirement)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    </>
                )}
                <Divider />
                {(serviceShowDetail !== null && !!serviceShowDetail.show.show_costos) && (
                    <>
                    <MKTypography pt={5} mb={1} pr={3} display='inline' sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                        <u>Costos</u>
                    </MKTypography>
                    <Button 
                        variant='contained' 
                        // color='secondary' 
                        style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                        onClick={() => handleOpenDialogCost(null)}
                    >
                        <AddIcon />
                    </Button>
                    <Table>
                        <TableBody>
                            {dataCosts.map((cost, index) => {
                                return(
                                    <TableRow hover key={"key" + index}>
                                        <TableCell style={{width: '40%'}}>
                                            <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}} pl={2}>
                                                {cost.descripcion}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell style={{width: '60%'}}>
                                            <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={2}>
                                                {cost.importe}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Box sx={{width: '80px'}}>
                                                <Tooltip title='Editar'>
                                                    <IconButton onClick={() => handleOpenDialogCost(cost)}>
                                                        <EditIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Anular'>
                                                    <IconButton onClick={() => handleDeleteCost(cost)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    </>
                )}
                <Divider />
                {(serviceShowDetail !== null && !!serviceShowDetail.show.show_cronograma) && (
                    <>
                    <MKTypography pt={5} mb={1} pr={3} display='inline' sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                        <u>Cronograma</u>
                    </MKTypography>
                    <Button 
                        variant='contained' 
                        // color='secondary' 
                        style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                        onClick={() => handleOpenDialogCronograma(null)}
                    >
                        <AddIcon />
                    </Button>
                    <Table>
                        <TableBody>
                            {dataCronograma.map((cronograma, index) => {
                                return(
                                    <TableRow hover key={"key" + index}>
                                        <TableCell style={{width: '60%'}}>
                                            <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={2}>
                                                {cronograma.descripcion !== null ? cronograma.descripcion : ""}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell style={{width: '20%'}}>
                                            <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}}>
                                                {cronograma.fecha_inicio.substring(0,10)}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell style={{width: '20%'}}>
                                            <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}}>
                                                {cronograma.fecha_fin.substring(0,10)}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Box sx={{width: '80px'}}>
                                                <Tooltip title='Editar'>
                                                    <IconButton onClick={() => handleOpenDialogCronograma(cronograma)}>
                                                        <EditIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Anular'>
                                                    <IconButton onClick={() => handleDeleteCronograma(cronograma)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    </>
                )}
                <Divider />
                {(serviceShowDetail !== null && !!serviceShowDetail.show.show_resultados) && (
                    <>
                    <MKTypography pt={5} mb={1} pr={3} display='inline' sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                        <u>Resultados</u>
                    </MKTypography>
                    <Button 
                        variant='contained' 
                        style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                        onClick={() => handleOpenDialogResult(null)}
                    >
                        <AddIcon />
                    </Button>
                    <Table>
                        <TableBody>
                            {dataResults.map((result, index) => {
                                return(
                                    <TableRow hover key={"key" + index}>
                                        <TableCell style={{width: '70%'}}>
                                            <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={2}>
                                                {result.descripcion !== null ? result.descripcion : ""}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell style={{width: '30%'}} align='right'>
                                            <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}}>
                                                {result.pdfresulados !== null ? result.pdfresulados : ""}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Box sx={{width: '80px'}}>
                                                <Tooltip title='Editar'>
                                                    <IconButton onClick={() => handleOpenDialogResult(result)}>
                                                        <EditIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Anular'>
                                                    <IconButton onClick={() => handleDeleteResult(result)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                    </>
                )}
                <Divider />
                {(serviceShowDetail !== null && !!serviceShowDetail.show.show_adjuntos) && (
                    <>
                    <MKTypography pt={5} mb={1} pr={3} display='inline' sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                        <u>Adjuntos</u>
                    </MKTypography>
                    <Button 
                        variant='contained' 
                        // color='secondary' 
                        style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                        onClick={() => handleOpenDialogAttached(null)}
                    >
                        <AddIcon />
                    </Button>
                    <Table>
                        <TableBody>
                            {dataAttacheds.map((attached, index) => {
                                return(
                                    <TableRow hover key={"key" + index}>
                                        <TableCell style={{width: '70%'}}>
                                            <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={2}>
                                                {attached.descripcion !== null ? attached.descripcion : ""}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell style={{width: '30%'}} align='right'>
                                            <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}}>
                                                {attached.nombrearchivo}.{attached.formato}
                                            </MKTypography>
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Box sx={{width: '80px'}}>
                                                <Tooltip title='Editar'>
                                                    <IconButton onClick={() => handleOpenDialogAttached(attached)}>
                                                        <EditIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title='Anular'>
                                                    <IconButton onClick={() => handleDeleteAttached(attached)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                    
                                )
                            })}
                        </TableBody>
                    </Table>
                    </>
                )}
            </Container>
        </Card>


        <Dialog onClose={handleCloseDialog} open={openDialogModality} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            variant="standard"
                            label="titulo"
                            name="titulo"
                            value={valuesModality.titulo}
                            onChange={handleChangeModality}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                            variant="standard"
                            label="Descripcion"
                            name="descripcion"
                            value={valuesModality.descripcion}
                            onChange={handleChangeModality}
                            color='text'
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetModality} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>
        
        <Dialog onClose={handleCloseDialog} open={openDialogRequirement} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            variant="standard"
                            label="titulo"
                            name="titulo"
                            value={valuesRequirement.titulo}
                            onChange={handleChangeRequirement}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                            variant="standard"
                            label="Descripcion"
                            name="descripcion"
                            value={valuesRequirement.descripcion}
                            onChange={handleChangeRequirement}
                            color='text'
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetRequirement} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>
        
        <Dialog onClose={handleCloseDialog} open={openDialogCost} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            variant="standard"
                            label="Descripcion"
                            name="descripcion"
                            value={valuesCost.descripcion}
                            onChange={handleChangeCost}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            type="number"
                            label="Importe"
                            name="importe"
                            value={valuesCost.importe}
                            onChange={handleChangeCost}
                            color='text'
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetCost} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>

        <Dialog onClose={handleCloseDialog} open={openDialogCronograma} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            variant="standard"
                            label="Descripción"
                            name="descripcion"
                            value={valuesCronograma.descripcion}
                            onChange={handleChangeCronograma}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                variant="standard"
                                label="F. Inicio"
                                inputFormat="dd/MM/yyyy"
                                value={valuesCronograma.fecha_inicio}
                                color='text'
                                onChange={(newValue) => {
                                    handleChangeFInicio(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                variant="standard"
                                label="F. Fin"
                                inputFormat="dd/MM/yyyy"
                                value={valuesCronograma.fecha_fin}
                                color='text'
                                onChange={(newValue) => {
                                    handleChangeFFin(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetCronograma} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>

        <Dialog onClose={handleCloseDialog} open={openDialogResult} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            variant="standard"
                            label="Descripcion"
                            name="descripcion"
                            value={valuesResult.descripcion}
                            onChange={handleChangeResult}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <div>
                        <input color="text" type="file" id="txtFile" onChange={onFileResultChange} />
                    </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetResult} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>
        
        <Dialog onClose={handleCloseDialog} open={openDialogAttached} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            variant="standard"
                            label="Descripcion"
                            name="descripcion"
                            value={valuesAttached.descripcion}
                            onChange={handleChangeAttached}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <div>
                        <input color="text" type="file" id="txtFile" onChange={onFileAttachedChange} />
                    </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetAttached} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>
        <ConfirmationDialog 
            open={stateConfirmDialog.open}
            title={stateConfirmDialog.title}
            content={stateConfirmDialog.content}
            onClose={handleCloseConfirmDialog}
        />
        </>
    );
}

export default RegisterService;