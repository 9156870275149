import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ROOT_SERVER, getUserAuth } from 'helpers/helperInit';
import FloatingPanel from 'components/FloatingPanel';
import { open_change_pass_action } from 'redux/actions/userAction';
import ChangePassword from 'pages/Access/ChangePassword';
import "./index.css";

let userAuth = {
  "displayName": "",
  "photoURL": "",
  "role": "",
}

function UserMenu({ transparent }) {
  const dispatch = useDispatch();
  const reducerUser = "userReducer";
  const open_frm = useSelector(state => state[reducerUser].open_change_pass);

  const dataUserAuth = getUserAuth();
  if (dataUserAuth.length > 0) {
    const URLImage = ROOT_SERVER + dataUserAuth[3];
    const publicUrl = new URL(URLImage, window.location.href);
    userAuth.displayName = dataUserAuth[2];
    userAuth.photoURL = publicUrl.href;
    userAuth.role = dataUserAuth[4];
  }
  const navigate = useNavigate();

  const [userMenu, setUserMenu] = useState(null);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const handleCloseChangePass = () => dispatch(open_change_pass_action(false));
  const handleOpenChangePass = () => {
    dispatch(open_change_pass_action(true));
    userMenuClose();
  };

  return (
    <>
      <Button
        onClick={userMenuClick}
        color="inherit"
      >
        <div>
          <Typography component="span" className={ transparent ? "text-14-white" : "text-14-black"}> 
            {userAuth.displayName}
          </Typography>
          <Typography className={ transparent ? "text-10-white" : "text-10-black" }>
            {userAuth.role.toString()}
            {(!userAuth.role || (Array.isArray(userAuth.role) && userAuth.role.length === 0)) && 'Guest'}
          </Typography>
        </div>
        <Avatar className="md:mx-4" alt="user photo" />
        {/* {userAuth.photoURL ? (
          <Avatar className="md:mx-4" alt="user photo" src={userAuth.photoURL} />
        ) : (
          <Avatar className="md:mx-4">{userAuth.displayName[0]}</Avatar>
        )} */}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: 'py-8',
        }}
      >
        {!userAuth.role || userAuth.role.length === 0 ? (
          <>
            <MenuItem component={Link} to="/login" role="button" style={{background: 'red'}}>
              <ListItemIcon className="min-w-40">
                <Icon>lock</Icon>
              </ListItemIcon>
              <ListItemText primary="Login" />
            </MenuItem>
            <MenuItem component={Link} to="/register" role="button">
              <ListItemIcon className="min-w-40">
                <Icon>person_add</Icon>
              </ListItemIcon>
              <ListItemText primary="Register" />
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem onClick={handleOpenChangePass} role="button">
            {/* component={Link} to={URLPermissions} */}
              <ListItemIcon>
                <Icon fontSize='small'>key</Icon>
              </ListItemIcon>
              <ListItemText><span style={{ fontSize: '14px', fontWeight: '100' }}>Cambiar password</span> </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={() => {
                userMenuClose();
                window.location = ROOT_SERVER + '/home';
              }}
            >
              <ListItemIcon>
                <Icon fontSize='small'>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText><span style={{ fontSize: '14px' }}>Logout</span></ListItemText>
            </MenuItem>
          </>
        )}
      </Popover>
      <FloatingPanel 
        openForm={open_frm}
        closeForm={handleCloseChangePass}
        expanded={true}
        expandedBtn={true}
        widthPanel={"40%"}
        title={"Cambiar password"}
      >
        <ChangePassword />
      </FloatingPanel>
    </>
  );
}

export default UserMenu;
