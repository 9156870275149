import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import MKTypography from "components/MKTypography";
import { ROOT_SERVER } from "helpers/helperInit";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const imgLocate = ROOT_SERVER + '/static/images/icons/logo-file.svg';

const AbautLinkMain = () => {
    const reducerGeneralData = "generalDataReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);

    return (
        <>
        {data_general_data.length > 0 && (
            <Box 
                style={{
                    background: '#FFF',
                    borderRadius: 0,
                    border: 'none'
                }}
            >
                <img src={imgLocate} width={50} style={{display: 'inline'}} />
                <MKTypography display='inline' pl={3} style={{color: '#000', fontSize: '24px', fontFamily: 'Arial', fontWeight: 600}}>
                    Quienes Somos
                </MKTypography>
                <MKTypography pt={1} style={{fontSize: '20px', color: "#000"}}>
                    {data_general_data[0].mision !== null ? data_general_data[0].mision.substring(0,150) : ""}...
                </MKTypography>
                <Box px={2} pt={2}>
                    <Button 
                        component={Link}
                        to={ROOT_SERVER + "/pages/about-us"}
                        className="color-secondary"
                        sx={{fontSize: '16px'}}
                    >
                        MAS INFORMACIÓN <ArrowForwardIcon />
                    </Button>
                </Box>
            </Box>
        )}
        </>
    );
}

export default AbautLinkMain;