// @mui material components
import Card from "@mui/material/Card";

import { ROOT_SERVER } from "helpers/helperInit";
import MKTypography from "components/MKTypography";

const bgImage = ROOT_SERVER + '/static/images/academyc/noticias1.jpg';

const Header = ({title, image}) => {
    return(
        <Card style={{zIndex: -2}}>
            <div>
                <div className="carousel-item" style={{ height: '300px', width: '500px', justifyContent: 'center' }}>
                    <img src={image} className='img-carrusel' />
                    <p 
                        className={"titulo-cauroles"}
                        style={{
                            fontWeight: 700,
                            color: '#FFF',
                            fontSize: '50px',
                            fontFamily: 'Georgia'
                        }}
                    >
                        {title}
                    </p>
                    {/* <MKTypography variant="body2" color="white" opacity={0.8} mb={2}>
                        data_general_data.objetivos
                    </MKTypography> */}
                </div>
            </div>
        </Card>
    );
}

export default Header;