import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Routes
import { ROOT_SERVER } from 'helpers/helperInit';
import { getAllPublications, getAllCategories } from "redux/actions/publicationAction";
import PublicationList from "./sections/PublicationsList";
import RegisterPublication from "./sections/RegisterPublication";
import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";
import { TYPE_PUBLICATION } from "helpers/helperInit";

function Publications({id_type_publication}) {
    const dispatch = useDispatch();
    const [actionStudent, setActionStudent] = useState("list");
    const [titleTypePubli, setTitleTypePubli] = useState("");

    let hasConn = sessionStorage.getItem('hasConn');

    const handleChangeAction = (action) => {
        setActionStudent(action);
    }

    useEffect(async () => {
        if(hasConn === null) {
            window.location = ROOT_SERVER + "/home";
        }
        const typePubl = TYPE_PUBLICATION.filter(item => parseInt(item.id_type_publication) === id_type_publication);
        setTitleTypePubli(typePubl[0].name)
        await dispatch(getAllPublications());
        await dispatch(getAllCategories());
    }, [actionStudent]);
    
    return (
        <>
            <MKTypography className='color-primary' fontWeight='bold' pt={3}>
                {titleTypePubli}
            </MKTypography>
            {/* <Divider /> */}
            {actionStudent === "list" && (<PublicationList onChangeAction={handleChangeAction} id_type_publication={id_type_publication} />)}
            {actionStudent === "register" && (<RegisterPublication onChangeAction={handleChangeAction} id_type_publication={id_type_publication} />)}
        </>
  );
}

export default Publications;
