import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

// Mui icons
import NewspaperIcon from '@mui/icons-material/Newspaper';
import DataObjectIcon from '@mui/icons-material/DataObject';
import SchoolIcon from '@mui/icons-material/School';
import ApprovalIcon from '@mui/icons-material/Approval';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import SpellcheckIcon from '@mui/icons-material/Spellcheck';
import SubtitlesOffIcon from '@mui/icons-material/SubtitlesOff';
import AddTaskIcon from '@mui/icons-material/AddTask';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import footerRoutes from "footer.routes";
import { ROOT_SERVER } from 'helpers/helperInit';
import { useDispatch, useSelector } from 'react-redux';
import MKTypography from 'components/MKTypography';
import Grid from '@mui/material/Grid';
// import Teachers from './Teachers';
// import Courses from './Courses';
// import Schools from './Schools';
import Notification from 'components/Notification';
import { closeNotifAction } from 'redux/actions/severalAction';
import Publications from './Publications';
import DefaultFooter from 'examples/Footers/DefaultFooter';
import MKBox from 'components/MKBox';
import GeneralData from './GeneralData';
import Services from './Services';
import Programs from './Programs';
import { getGeneralData } from 'redux/actions/generalDataAction';
import { KEY_PUBLICATION } from 'helpers/helperInit';
import { KEY_NOTICIA } from 'helpers/helperInit';
import { KEY_EVENT } from 'helpers/helperInit';
import Students from './Students';

const drawerWidth = 240;
const widthToolBar = '150px';
const logoCompany = ROOT_SERVER + "/static/images/logo/escudoMorropon.png";

const ListMenu = ({onShowPage}) => {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleLoadPage = (pageName) => {
        onShowPage(pageName);
    }

    return(
        <List
            sx={{ width: '100%', maxWidth: 360 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            // subheader={
            //     <ListSubheader component="div" id="nested-list-subheader" sx={{background: '#d2e2eb'}}>
            //         Opciones del sistema
            //     </ListSubheader>
            // }
        >
            <ListItemButton onClick={() => handleLoadPage("general-data")} >
                <ListItemIcon><DataObjectIcon fontSize='small' /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Datos Generales" 
                />
            </ListItemButton>
            <ListItemButton onClick={() => handleLoadPage("publications")} >
                <ListItemIcon><NewspaperIcon /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Publicaciones" 
                />
            </ListItemButton>
            <ListItemButton onClick={() => handleLoadPage("noticias")} >
                <ListItemIcon><NewspaperIcon /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Noticias" 
                />
            </ListItemButton>
            <ListItemButton onClick={() => handleLoadPage("events")} >
                <ListItemIcon><NewspaperIcon /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Eventos" 
                />
            </ListItemButton>
            <ListItemButton onClick={() => handleLoadPage("programs")} >
                <ListItemIcon><SchoolIcon /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Escuelas" 
                />
            </ListItemButton>
            <ListItemButton onClick={() => handleLoadPage("admission")} >
                <ListItemIcon><ApprovalIcon /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Admisión" 
                />
            </ListItemButton>
            <ListItemButton onClick={() => handleLoadPage("announcement")} >
                <ListItemIcon><GroupAddIcon /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Convocatoria" 
                />
            </ListItemButton>
            <ListItemButton onClick={() => handleLoadPage("tuition")} >
                <ListItemIcon><AppRegistrationIcon /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Matrícula" 
                />
            </ListItemButton>
            <ListItemButton onClick={() => handleLoadPage("leveling")} >
                <ListItemIcon><AddTaskIcon /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Nivelación" 
                />
            </ListItemButton>
            <ListItemButton onClick={() => handleLoadPage("test")} >
                <ListItemIcon><SpellcheckIcon /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Test" 
                />
            </ListItemButton>
            <ListItemButton onClick={() => handleLoadPage("transact")} >
                <ListItemIcon><SubtitlesOffIcon /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Trámite" 
                />
            </ListItemButton>
            <ListItemButton onClick={() => handleLoadPage("graduated")} >
                <ListItemIcon><SubtitlesOffIcon /></ListItemIcon>
                <ListItemText 
                    primaryTypographyProps={{fontSize: '18px', marginLeft: '-20px'}}
                    primary="Egresados" 
                />
            </ListItemButton>
            {/* <ListItemButton onClick={handleClick}>
                <ListItemIcon><SchoolIcon /></ListItemIcon>
                <ListItemText primary="Opciones" />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} onClick={() => handleLoadPage("courses")} >
                        <ListItemIcon><SendIcon /></ListItemIcon>
                        <ListItemText primary="Opción 1" />
                    </ListItemButton>
                </List>
            </Collapse> */}
        </List>
    )
}

const Administrator = () => {
    const dispatch = useDispatch();
    const reducerGeneralData = "generalDataReducer";
    const reducerNotif = "notifReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);
    const messageNotif = useSelector(state => state[reducerNotif].notif_msg);
    const variantNotif = useSelector(state => state[reducerNotif].notif_variant);
    const typeNotif = useSelector(state => state[reducerNotif].notif_type);
    const [page, setPage] = useState("publications");

    const closeNotif = () => dispatch(closeNotifAction());

    const handleShowPage = (pageLoad) => {
        setPage(pageLoad);
    }

    useEffect(() => {
        if(data_general_data.length > 0) {
            document.title = data_general_data[0].nombrecorto;
        }
        console.log("admin...:", data_general_data)
        // dispatch(getGeneralData());
    });

    return(
        <>
        <Notification close={() => closeNotif()} message={messageNotif} notiftype={typeNotif} notifvariant={variantNotif} />
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
                >
                <Toolbar className='bg-primary' style={{height: '150px'}}>
                    <Box style={{width: '100%', height: '150px', display: 'block'}}>
                        <Grid container>
                            <Grid item xs={12} pb={12}>
                                <DefaultNavbar showMenuUser />
                            </Grid>
                            <Grid item xs={12}>
                                <MKTypography
                                    color='white'
                                    sx={{
                                        fontSize: '20px',
                                        fontFamily: 'Georgia'
                                    }}
                                    align='center'
                                >
                                    {data_general_data.length > 0 ? data_general_data[0].nombre_institucion : ""}
                                </MKTypography>
                            </Grid>
                        </Grid>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                        // background: '#d2e2eb'
                        // background: '#d2e2eb'
                        backgroundImage: 'linear-gradient(#0A4E70, #FFF)',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                {/* , minHeight: '150px' */}
                <Toolbar className='bg-primary' style={{height: '150px', minHeight: '150px'}}>
                    <img src={logoCompany} width={95}></img>
                </Toolbar>
                <Divider />
                <ListMenu onShowPage={handleShowPage} />
            </Drawer>
            <Box
                component="main"
                sx={{ 
                    flexGrow: 1, 
                    bgcolor: 'background.default', 
                    background: '#FFF', 
                    p: 3 
                }}
                mt={20}
            >
                {page === "general-data" &&(<GeneralData />)}
                {page === "publications" &&(<Publications id_type_publication={KEY_PUBLICATION} />)}
                {page === "noticias" &&(<Publications id_type_publication={KEY_NOTICIA} />)}
                {page === "events" &&(<Publications id_type_publication={KEY_EVENT} />)}
                {page === "admission" &&(<Services id_type_service={1} />)}
                {page === "announcement" &&(<Services id_type_service={5} />)}
                {page === "tuition" &&(<Services id_type_service={3} />)}
                {page === "leveling" &&(<Services id_type_service={2} />)}
                {page === "test" &&(<Services id_type_service={6} />)}
                {page === "transact" &&(<Services id_type_service={4} />)}
                {page === "programs" &&(<Programs />)}
                {page === "graduated" &&(<Students />)}
            </Box>
        </Box>
        {/* <MKBox mt={4}>
          <DefaultFooter content={footerRoutes} />
        </MKBox> */}
        </>
    )
}

export default Administrator;