import axios from 'axios';

import { URL_SERVICE, getUserAuth } from 'helpers/helperInit'

export const useUser = () => {
    const getAllUsers = async (optList) => {
        const dataList = JSON.stringify({ "opt_list": parseInt(optList) });
        const dataUserAuth = getUserAuth();
        const strToken = dataUserAuth[1];
        const response = await axios(URL_SERVICE + "Usuario/GetAllUsers/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            data: dataList,
            method: "POST"
        });
        const data_users = (await response).data;
        return data_users ;
    }

    const SaveChangePassword = async (data) => {
        const dataUserAuth = getUserAuth();
        data.id_usuario = parseInt(dataUserAuth[0]);
        const strToken = dataUserAuth[1];
        const response = await axios(URL_SERVICE + "Usuario/ChangePassword/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            data: data,
            method: "POST"
        });
        const data_rpta = (await response).data;
        return data_rpta;
    }

    return {
        getAllUsers,
        SaveChangePassword,
    };
}