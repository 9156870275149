import { typesGeneralData } from "redux/constants/type"

const initialStateGeneralData = {
    data_general_data: [],
}

export const generalDataReducer = (state = initialStateGeneralData, action) => {
    switch (action.type) {
        case typesGeneralData.read:
            return {
                ...state,
                data_general_data: action.payload
            }
        case typesGeneralData.setOnePublication:
            return {
                ...state,
                one_publication: action.payload,
            }
        default:
            return state;
    }
}
