import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import Container from '@mui/material/Container';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import CategorySelect from 'components/SeveralSelect/CategorySelect';
import { saveNewPublication, getAllPublications } from 'redux/actions/publicationAction';
import { openNotifAction } from 'redux/actions/severalAction';
import { saveEditPublication } from 'redux/actions/publicationAction';
import MKTypography from 'components/MKTypography';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { saveNewImgPublication } from 'redux/actions/publicationAction';
import { saveLoadFileImage } from 'redux/actions/severalAction';

const RegisterPublication = ({onChangeAction, id_type_publication}) => {
    const dispatch = useDispatch();
    const reducerCategory = "categoryReducer";
    const reducerPublication = "publicationReducer";
    const data_categories = useSelector(state => state[reducerCategory].data_categories);
    const one_publication = useSelector(state => state[reducerPublication].one_publication);
    const [title, setTitle] = useState("Nuevo Registro");
    const [values, setValues] = useState({
        "titulo": "",
        "descripcion": "",
        "id_categoria": id_type_publication
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [dataImagen, setDataImagen] = useState([]);
    const [imageName, setImageName] = useState("");
    const [idxElement, setIdxElement] = useState(-1);
    const [valuesImagen, setValuesImagen] = useState({
        nombreimagen: "",
        formato: "",
    });
    const [openDialogProgImagen, setOpenDialogProgImagen] = useState(false);
    const [stateConfirmDialog, setStateConfirmDialog] = useState({
        open: false,
        title: '',
        content: ''
    });

    const handleSavePublication = async () => {
        const newDate = new Date();
        let month = (newDate.getMonth() + 1).toString().padStart(2, '0');
        let day = newDate.getDate().toString().padStart(2, '0');
        let year = newDate.getFullYear();
        const dateString = [year, month, day].join('-');
        one_publication.fecha = dateString;
        if(one_publication.titulo === "") {
            dispatch(openNotifAction(["Datos incompletos", "error", 'filled']));
            return;
        }
        let newIdPubl = one_publication.id_publicacion
        if(parseInt(one_publication.id_publicacion) === 0) {
            const rptaSave = await saveNewPublication(one_publication);
            newIdPubl = rptaSave.data[0].idpublicacion
        } else {
            const rptaSave = saveEditPublication(one_publication);
        }
        for(var i = 0; i < dataImagen.length; i++) {
            dataImagen[i].id_publicacion = newIdPubl;
            const rptaImg = await saveNewImgPublication(dataImagen[i]);
        }
        await dispatch(getAllPublications());
        onChangeAction("list");
    }

    const handleCloseNewPublication = () => {
        onChangeAction("list");
    }

    const handleChangeTitulo = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
        one_publication.titulo = e.target.value;
    }

    const handleChangeDescription = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
        one_publication.descripcion = e.target.value;
    }

    const handleSelectCategory = (category) =>{
        one_publication.id_categoria = category.id_categoria;
    }

    const handleOpenDialogProgImagen = () => {
        setValuesImagen({
            nombreimagen: "",
            formato: ""
        });
        setImageName("");
        setOpenDialogProgImagen(true);
    }

    const onFileProgImagenChange = (event) => {
        setSelectedFile({ selectedFile: event.target.files[0] });
    };
    
    const onFileUpload = async () => {
        if(selectedFile === null) {
            dispatch(openNotifAction(["Seleccione una imagen", "error", "filled"]));
            return;
        }
        const formData = new FormData();
        formData.append(
            "myFile",
            selectedFile.selectedFile,
            selectedFile.selectedFile.name,
        );
        let rptaUpload = await saveLoadFileImage(formData);
        const nameExte = rptaUpload.filename.split('.');
        const newImg = {
            nombreimagen: nameExte[0], formato: nameExte[1]
        };
        dataImagen.push(newImg);
    };

    const handleSetImagenes = async () => {
        await onFileUpload();
        const nameExte = imageName.split('.');
        // const newImg = {
        //     nombreimagen: nameExte[0], formato: nameExte[1]
        // };
        // dataImagen.push(newImg);
        setOpenDialogProgImagen(false);
    }

    const handleCloseDialog = () => {
        setOpenDialogProgImagen(false);
    };

    const handleDeleteImagen = (index) => {
        setIdxElement(index);
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Imagen",
            content: "¿Seguro de Eliminar la imagen seleccionada?"
        });
    }

    const handleCloseConfirmDialog = async (value) => {
        if(value === "Yes") {
            const newList = dataImagen.filter((item, index) => index !== idxElement);
            setDataImagen(newList);
        }
        setStateConfirmDialog({
            open: false,
            title: '',
            content: ''
        });
        setIdxElement(-1);
    }


    const initData = () => {
        if(one_publication.id_publicacion > 0) {
            setValues({
                "titulo": one_publication.titulo,
                "descripcion": one_publication.descripcion
            });
            setDataImagen(one_publication.imagenes);
            setTitle("Actualizando registro");
        }
        one_publication.id_categoria = id_type_publication;
    }

    useEffect(() => {
        initData();
    }, [one_publication]);

    return(
        <>
        <MKTypography className='color-primary'>
            {title}
        </MKTypography>
        <Divider />
        <Card >
            <Container sx={{textAlign: 'right', paddingTop: '5px'}}>
                <Button 
                    variant='contained' 
                    color='primary' 
                    style={{color: '#FFF', marginRight: '5px'}}
                    onClick={handleSavePublication}
                >
                    GUARDAR
                </Button>
                <Button 
                    variant='contained' 
                    color='secondary' 
                    style={{color: '#FFF'}}
                    onClick={handleCloseNewPublication}
                >
                    CANCELAR
                </Button>
            </Container>
            <Divider />
            <Container>
                <Grid container spacing={3}>
                    {/* <Grid item xs={6}>
                        <CategorySelect 
                            dataCategories={data_categories} 
                            onSelectCategory={handleSelectCategory} 
                            categorySelected={one_publication.id_categoria}
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <TextField 
                            variant="standard"
                            label="Título"
                            name="titulo"
                            value={values.titulo}
                            placeholder="Título de la publicación"
                            fullWidth
                            onChange={handleChangeTitulo}
                        />
                    </Grid>
                    <Grid item xs={12} pb={6}>
                        <TextField
                            variant="standard"
                            label="Descripción"
                            name="descripcion"
                            value={values.descripcion}
                            placeholder="Descripción de la publicación"
                            multiline
                            fullWidth
                            rows={4}
                            onChange={handleChangeDescription}
                        />
                    </Grid>
                </Grid>
                
                <Divider />
                <MKTypography pt={5} mb={1} pr={3} display='inline' sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                    <u>Imagenes</u>
                </MKTypography>
                <Button 
                    variant='contained' 
                    // color='secondary' 
                    style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                    onClick={handleOpenDialogProgImagen}
                >
                    <AddIcon />
                </Button>
                <Table>
                    <TableBody>
                        {dataImagen.map((imagen, index) => {
                            return(
                                <TableRow hover key={"key" + index}>
                                    <TableCell style={{width: '70%'}} align='right'>
                                        <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}}>
                                            {imagen.nombreimagen}.{imagen.formato}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Box sx={{width: '80px'}}>
                                            <Tooltip title='Anular'>
                                                <IconButton onClick={() => handleDeleteImagen(index)}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                
                            )
                        })}
                    </TableBody>
                </Table>
            </Container>
        </Card>

        <Dialog onClose={handleCloseDialog} open={openDialogProgImagen} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12}>
                        <div>
                            <input color="text" type="file" id="txtFile" onChange={onFileProgImagenChange} />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetImagenes} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>

        <ConfirmationDialog 
            open={stateConfirmDialog.open}
            title={stateConfirmDialog.title}
            content={stateConfirmDialog.content}
            onClose={handleCloseConfirmDialog}
        />

        </>
    );
}

export default RegisterPublication;