import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

import MKTypography from "components/MKTypography";
import { ROOT_SERVER } from "helpers/helperInit";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";
import { ROOT_FILES_PDF } from "helpers/helperInit";
import FirstCarousel from "components/Carousel";
import ListStudents from "pages/LandingPages/Students/sections/ListStudents";
import { useState } from "react";
import Divider from "@mui/material/Divider";

const imgPdf = ROOT_SERVER + '/static/images/icons/pdf.png';
const filePDF = ROOT_SERVER + '/static/files/OFICIO.pdf';

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const ContentProgram = () => {
    const reducerProgram = "programReducer";
    const reducerGeneralData = "generalDataReducer";
    const one_program = useSelector(state => state[reducerProgram].one_program);
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);
    const [valueTabResource, setValueTabResource] = useState(0);
    const [openDialogFrame, setOpenDialogFrame] = useState(false);
    const [urlPdf, setUrlPdf] = useState("");

    let pathImg = "";
    let pathPdf = "";
    if(data_general_data.length > 0) {
        pathImg = data_general_data[0].url_img_publicaciones;
        pathPdf = data_general_data[0].url_img_publicaciones;
    }

    const handleChangeTabResource = (event, newValue) => {
        setValueTabResource(newValue);
    };

    const handleOpenDialogFrame = (valUrl) => {
        setUrlPdf(valUrl);
        setOpenDialogFrame(true);
    }

    const handleCloseDialog = () => {
        setOpenDialogFrame(false);
    };

    return(
        <>
        <Box
            heigth="100%"
            minHeight={"1190px"}
            width="100%"
            // px={6}
            py={5}
            sx={{
                background: "#FFF",
                backgroundPosition: "left",
                placeItems: "left",
                align: 'left',
            }}
        >
            {one_program === null ? 
            (
                <MKTypography variant="h2" mb={1} className='color-four'>SIN CONTENIDO</MKTypography>
            ) : (
                <>
                <Box px={6} >
                    <MKTypography variant="h2" mb={1} className='color-primary'>
                        {one_program.nombreprograma}
                    </MKTypography>
                    <FirstCarousel images={one_program.imagen} path={pathImg} />
                    <MKTypography variant="body2" mb={1} color='text'>
                        {one_program.descripcion}
                    </MKTypography>


                    <Box pt={5} sx={{ width: '100%' }}>
                        <AppBar position="static">
                            <Tabs 
                                value={valueTabResource} 
                                onChange={handleChangeTabResource} 
                                aria-label="basic tabs example"
                                variant="fullWidth"
                                className="tabs"
                            >
                                <Tab label="Diseño Curricular" sx={{ padding: '10px', marginLeft: '10px' }} {...a11yProps(0)} />
                                <Tab label="Perfil de Egreso" sx={{ padding: '10px', marginLeft: '10px' }} {...a11yProps(1)} />
                                <Tab label="Requisitos" sx={{ padding: '10px', marginLeft: '10px' }} {...a11yProps(2)} />
                            </Tabs>
                        </AppBar>
                    </Box>
                    <Box>
                    <TabPanel value={valueTabResource} index={0}>
                            <Table>
                                <TableBody>
                                    {one_program.plan.length > 0 && one_program.plan.map(plan => {
                                        return (
                                            <TableRow key={"plan" + plan.id_programa_plan} sx={{borderBottom: '2px solid #CCC'}}>
                                                <TableCell sx={{verticalAlign: 'top'}}>
                                                    <MKTypography sx={{fontSize: '16px', fontStyle: 'italic', color: '#000'}}>
                                                        {plan.anio}
                                                    </MKTypography>
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top'}}>
                                                    <MKTypography sx={{fontSize: '15px', color: '#000'}}>
                                                        {plan.descripcion}
                                                    </MKTypography>
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top'}}>
                                                    {plan.pdf !== null && (
                                                        <Card
                                                            // component={MuiLink}
                                                            // href={pathPdf + plan.pdf}
                                                            // target="_blank"
                                                            rel="noreferrer"
                                                            style={{
                                                                background: 'transparent',
                                                                borderRadius: 0,
                                                            }}
                                                            alignItems='center'
                                                            onClick={() => handleOpenDialogFrame(pathPdf + plan.pdf)}
                                                        >
                                                            <img src={imgPdf} width={50} style={{cursor: 'pointer'}} />
                                                        </Card>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TabPanel>
                        <TabPanel value={valueTabResource} index={1}>
                            <Table>
                                <TableBody>
                                    {one_program.modalidad.length > 0 && one_program.modalidad.map(modalidad => {
                                        return (
                                            <TableRow key={"mod" + modalidad.id_programa_modalidad} sx={{borderBottom: '2px solid #CCC'}} >
                                                <TableCell sx={{verticalAlign: 'top'}}>
                                                    <MKTypography sx={{fontSize: '16px', fontStyle: 'italic', color: '#000'}}>
                                                        {modalidad.modalidad}
                                                    </MKTypography>
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top'}}>
                                                    <MKTypography sx={{fontSize: '15px', color: '#000'}}>
                                                        {modalidad.descripcion}
                                                    </MKTypography>
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top'}}>
                                                    {modalidad.pdf !== null && (
                                                        <Card
                                                            // component={MuiLink}
                                                            // href={pathPdf + modalidad.pdf}
                                                            // target="_blank"
                                                            rel="noreferrer"
                                                            style={{
                                                                background: 'transparent',
                                                                borderRadius: 0
                                                            }}
                                                            onClick={() => handleOpenDialogFrame(pathPdf + modalidad.pdf)}
                                                        >
                                                            <img src={imgPdf} width={50} style={{cursor: 'pointer'}} />
                                                        </Card>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TabPanel>
                        <TabPanel value={valueTabResource} index={2}>
                            <Table>
                                <TableBody>
                                    {one_program.requisito.length > 0 && one_program.requisito.map(requisito => {
                                        return (
                                            <TableRow key={"req" + requisito.id_programa_requisito} sx={{borderBottom: '2px solid #CCC'}}>
                                                <TableCell sx={{verticalAlign: 'top'}}>
                                                    <MKTypography sx={{fontSize: '16px', fontStyle: 'italic', color: '#000'}}>
                                                        {requisito.descripcion}
                                                    </MKTypography>
                                                </TableCell>
                                                <TableCell sx={{verticalAlign: 'top'}}>
                                                    {requisito.pdf !== null && (
                                                        <Card
                                                            // component={MuiLink}
                                                            // href={pathPdf + requisito.pdf}
                                                            // target="_blank"
                                                            rel="noreferrer"
                                                            style={{
                                                                background: 'transparent',
                                                                borderRadius: 0
                                                            }}
                                                            onClick={() => handleOpenDialogFrame(pathPdf + requisito.pdf)}
                                                        >
                                                            <img src={imgPdf} width={50} style={{cursor: 'pointer'}} />
                                                        </Card>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TabPanel>
                    </Box>

                    {/* <MKTypography pt={5} mb={1} sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                        <u>Modalidad</u>
                    </MKTypography>
                    <MKTypography pt={5} mb={1} sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                        <u>Requisitos</u>
                    </MKTypography>
                    <MKTypography pt={5} mb={1} sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                        <u>Plan</u>
                    </MKTypography> */}
                    
                </Box>
                {/* <Divider />
                <ListStudents /> */}
                </>
            )}
        </Box>

        <Dialog onClose={handleCloseDialog} open={openDialogFrame} maxWidth="xl" fullWidth >
            <DialogTitle
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{ 
                    height: '95vh'
                }}
            >
                <Divider />
                <iframe style={{width: '100%', height: '100%'}} src={urlPdf} />
            </DialogContent>
        </Dialog>

        </>
    );
}

export default ContentProgram;