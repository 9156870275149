import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import MKBox from 'components/MKBox';
import { ROOT_SERVER } from 'helpers/helperInit';
import MKTypography from 'components/MKTypography';
import { 
    KEY_SERVICE_ADMISION,
    KEY_SERVICE_NIVELACION,
    KEY_SERVICE_MATRICULA,
    KEY_SERVICE_TRAMITE,
    KEY_SERVICE_CONVOCATORIA,
    KEY_SERVICE_TEST
} from "helpers/helperInit";
import { set_one_service_action } from "redux/actions/publicationAction";
import { set_service_type_action } from 'redux/actions/publicationAction';

const CardService = ({key_service, data }) => {
    const dispatch = useDispatch();
    
    const handleSetKeyService = () => {
        sessionStorage.setItem("key_service", JSON.stringify(key_service));
        dispatch(set_one_service_action(null));
        dispatch(set_service_type_action(key_service));
    }

    return(
        <Card 
            // component={Link}
            // to={ROOT_SERVER + "/pages/informate"}
            style={{
                border: '1px solid #C2C2C2', 
                background: 'transparent',
                borderRadius: 0,
                cursor: 'pointer'
            }}
            onClick={handleSetKeyService}
        >
            <Grid container>
                <Grid item xs={12}>
                    <MKBox textAlign="center" style={{background: "#FFF"}}>
                        <MKTypography display="inline" variant="h6" className='color-four' textTransform="capitalize" fontWeight="bold">
                            {key_service.name.toUpperCase()}
                        </MKTypography>
                    </MKBox>
                    <MKBox
                        component="img"
                        src={key_service.image_name}
                        width="100%"
                        zIndex={1}
                        style={{opacity: 0.8}}
                    />
                    {data !== null ? (
                        <>
                            <MKTypography className='color-primary' fontWeight="bold" style={{fontSize: '15px', lineHeight: 1.5, padding: '2px 15px'}}>
                                {data.titulo}
                            </MKTypography>
                        </>
                    ) : (
                        <MKBox textAlign="center">
                            <MKTypography className='color-primary' fontWeight="bold" style={{fontSize: '15px', lineHeight: 1.5, padding: '2px 15px'}}>
                                SIN CONTENIDO
                            </MKTypography>
                        </MKBox>
                    )}
                </Grid>
            </Grid>
        </Card>
    );
}

const LinkServices = () => {
    const reducerService = "serviceReducer";
    const data_services = useSelector(state => state[reducerService].data_services);
    const [dataAdmision, setDataAdmision] = useState(null);
    const [dataNivelacion, setDataNivelacion] = useState(null);
    const [dataMatricula, setDataMatricula] = useState(null);
    const [dataTramite, setDataTramite] = useState(null);
    const [dataConvocatoria, setDataConvocatoria] = useState(null);
    const [dataTest, setDataTest] = useState(null);

    const initData = () => {
        if(data_services !== null) {
            let oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_ADMISION.key_service));
            if (oneService.length > 0) setDataAdmision(oneService[0]);
            
            oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_NIVELACION.key_service));
            if (oneService.length > 0) setDataNivelacion(oneService[0]);
    
            oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_MATRICULA.key_service));
            if (oneService.length > 0) setDataMatricula(oneService[0]);
            
            oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_TRAMITE.key_service));
            if (oneService.length > 0) setDataTramite(oneService[0]);
    
            oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_CONVOCATORIA.key));
            if (oneService.length > 0) setDataConvocatoria(oneService[0]);
    
            oneService = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(KEY_SERVICE_TEST.key));
            if (oneService.length > 0) setDataTest(oneService[0]);
        }
    }

    useEffect(async () => {
        await initData();
    });
    
    return(
        <Box
            heigth="100%"
            minHeight={"1190px"}
            width="100%"
            className='bg-primary-opacity'
            sx={{
                backgroundPosition: "left",
                placeItems: "left",
                align: 'left',
                padding: '10px'
            }}
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <CardService key_service={KEY_SERVICE_ADMISION} data={dataAdmision} />
                </Grid>
                <Grid item xs={12}>
                    <CardService key_service={KEY_SERVICE_NIVELACION} data={dataNivelacion} />
                </Grid>
                <Grid item xs={12}>
                    <CardService key_service={KEY_SERVICE_MATRICULA} data={dataMatricula} />
                </Grid>
                <Grid item xs={12}>
                    <CardService key_service={KEY_SERVICE_TRAMITE} data={dataTramite} />
                </Grid>
                <Grid item xs={12}>
                    <CardService key_service={KEY_SERVICE_CONVOCATORIA} data={dataConvocatoria} />
                </Grid>
                <Grid item xs={12}>
                    <CardService key_service={KEY_SERVICE_TEST} data={dataTest} />
                </Grid>
            </Grid>
        </Box>
    );
}

export default LinkServices;