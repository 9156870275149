import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Helmet } from 'react-helmet'

// @mui material components
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";
import SimpleHeader from "examples/Headers/SimpleHeader";

// Routes
import footerRoutes from "footer.routes";

// Helpers
import { ROOT_SERVER } from 'helpers/helperInit';
import Header from "./sections/Header";
import HomeProgramas from "./sections/HomeProgramas";
import HomeAboutUs from "./sections/HomeAboutUs";
import HomePublication from "./sections/HomePublication";
import HomePublicationInstituto from "./sections/HomePublicationInstituto";
import HomeLinks from "./sections/HomeLinks";
import { getAllPublications } from "redux/actions/publicationAction";
import HeaderHomeInstituto from "./sections/HeaderHomeInstituto";
import { SHOW_HEADER } from "helpers/helperInit";
import HomeAboutUsInstituto from "./sections/HomeAboutUsInstituto";
import { getAllPrograms } from "redux/actions/publicationAction";

function Home() {
  const dispatch = useDispatch();
  sessionStorage.removeItem('hasConn');
  
  useEffect(() => {
    dispatch(getAllPublications());
    dispatch(getAllPrograms());
  });

  return (
    <>
      <SimpleHeader />
      <Box>
        {SHOW_HEADER === 1 ? <Header /> : <HeaderHomeInstituto /> }
       </Box>
      <Box px={4} style={{background: '#FFF'}}>
        {SHOW_HEADER === 1 ?<HomeProgramas/>:<HomeAboutUs/>}                  
        <Divider />
        {SHOW_HEADER === 1 ? <HomePublication />:<HomePublicationInstituto />}        
        <Divider />
        <HomeLinks />
      </Box>
      <MKBox mt={4}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Home;
