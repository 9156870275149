import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const YearSelect = (props) => {
    const { dataYears, onSelectYear, yearSelected, title } = props;
    const [values, setValues] = useState({
        year_id: new Date().getFullYear()
    });

    const handleChange = e => {
        let idYear = parseInt(e.target.value);
        const oneYear = dataYears.filter(item => parseInt(item.year_id) === idYear);
        setValues({ year_id: idYear });
        if(oneYear.length > 0) {
            onSelectYear(oneYear[0]);
        }
    }

    useEffect(() => {
        setValues({ year_id: yearSelected });
    }, [yearSelected]);

    return (
        <FormControl variant='standard' fullWidth>
            <InputLabel color='text'>{title}</InputLabel>
            {dataYears && dataYears.length > 0 && (
                <Select
                    name="year_id"
                    value={values.year_id}
                    label="Año"
                    required
                    color='text'
                    onChange={handleChange}
                >
                    {dataYears && dataYears.map(year => {
                        let keyYear = "key" + year.year_id;
                        return(
                            <MenuItem value={year.year_id} key={keyYear} >{year.name}</MenuItem>
                        )
                    })}
                </Select>
            )}
        </FormControl>
    )
}

export default YearSelect;