import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import { set_one_program_action } from 'redux/actions/publicationAction';
import { ROOT_GALERIA } from 'helpers/helperInit';

const CardProgram = ({data, pathImg, handleLoadDetail}) => {
    let firstImage = ROOT_GALERIA + "empty.jpg";
    const handleLoadData = () => {
        handleLoadDetail(data);
    }

    if(data.imagen.length > 0) {
        const listImage = data.imagen.filter(item => item.formato === 'jpg' || item.formato === 'jpeg' || item.formato === 'png')
        if(listImage.length > 0) {
            firstImage = pathImg + listImage[0].nombreimagen + "." + listImage[0].formato;
        }
    }

    return(
        <Card 
            onClick={handleLoadData}
            style={{
                marginTop: '20px',
                background: 'transparent',
                borderRadius: '0',
                cursor: 'pointer',
            }}
        >
            <Grid container>
                <Grid item xs={6}>
                    <MKBox
                        component="img"
                        src={firstImage}
                        width="100%"
                        zIndex={1}
                        style={{opacity: 0.8}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MKBox textAlign="center">
                        <MKTypography fontWeight="bold" sx={{ fontSize: '15px', color: "#000", lineHeight: 1}}>
                            {data.nombreprograma.toUpperCase()}
                        </MKTypography>
                    </MKBox>
                    
                    <MKTypography className='color-primary' style={{fontSize: '12px', lineHeight: 1.5, padding: '2px 15px'}}>
                        {data.descripcion.substring(0,80)}...
                    </MKTypography>
                </Grid>
            </Grid>
        </Card>
    )
}

const ListPrograms = () => {
    const dispatch = useDispatch();
    const reducerProgram = "programReducer";
    const reducerGeneralData = "generalDataReducer";
    const data_programs = useSelector(state => state[reducerProgram].data_programs);
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);

    let pathImg = "";
    if(data_general_data.length > 0) {
        pathImg = data_general_data[0].url_img_publicaciones;
    }

    const handleLoadDetail = (program) => {
        dispatch(set_one_program_action(program));
    }

    useEffect(() => {
        // if(data_programs.length > 0) {
        //     dispatch(set_one_program_action(data_programs[0]));
        // }
    }, [data_programs]);

    return(
        <Box
            heigth="100%"
            // minHeight={"1190px"}
            width="100%"
            sx={{
                background: '#FFF',
                backgroundPosition: "left",
                placeItems: "left",
                align: 'left',
            }}
        >
            {data_programs !== null && data_programs.map(program => {
                return(
                    <Box key={program.id_programa} style={{padding: '5px'}}>
                        <CardProgram data={program} pathImg={pathImg} handleLoadDetail={handleLoadDetail} />
                    </Box>
                );
            })}
        </Box>
    )
}
export default ListPrograms;