import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Helmet } from 'react-helmet'

// @mui material components
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";
import SimpleHeader from "examples/Headers/SimpleHeader";

// Routes
import footerRoutes from "footer.routes";

// Helpers
import { ROOT_SERVER } from 'helpers/helperInit';
import { getAllPublications } from "redux/actions/publicationAction";
import { SHOW_HEADER } from "helpers/helperInit";
import ListStudents from "./ListStudents";
import { Grid } from "@mui/material";
import LinkFirstTypeExteOne from "pages/LandingPages/Publications/sections/LinkFirstTypeExteOne";

function PageContent() {
  const dispatch = useDispatch();
  sessionStorage.removeItem('hasConn');
  
  useEffect(() => {
    dispatch(getAllPublications());
  });

  return (
    <>
        <Box style={{background: '#FFF'}}>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <ListStudents />
            </Grid>
            {/* <Grid item xs={12} lg={3} px={1} pt={2}>
              <LinkFirstTypeExteOne />
            </Grid> */}
          </Grid>
        </Box>
    </>
  );
}

export default PageContent;
