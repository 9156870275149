import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Grid, TextField, Divider } from "@mui/material";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import SexoSelect from "components/SeveralSelect/SexoSelect";
import { DATA_SEXO } from "helpers/helperInit";
import { openNotifAction } from "redux/actions/severalAction";
import SchoolSelect from "../../../Programs/sections/SchoolSelect";
import { dateFormatYYMMDD } from "helpers/helperInit";
import { saveNewStudent } from "redux/actions/notesAction";
import { getAllStudents } from "redux/actions/notesAction";
import MKTypography from "components/MKTypography";
import YearSelect from "components/SeveralSelect/YearSelect";
import { getYears } from "helpers/helperInit";

const RegisterStudent = ({onChangeAction}) => {
    const dispatch = useDispatch();
    const reducerProgram = "programReducer";
    const reducerStudent = "studentReducer";
    const data_programs = useSelector(state => state[reducerProgram].data_programs);
    const one_student = useSelector(state => state[reducerStudent].one_student);
    const [defaultSchool, setDefaultSchool] = useState(0);
    const [defaultInicYear, setDefaultInicYear] = useState(0);
    const [defaultFinYear, setDefaultFinYear] = useState(0);
    const [title, setTitle] = useState("Nuevo Registro");
    const [values, setValues] = useState({
        id_alumno: 0,
        codigo: "",
        anio_inicio: 0,
        anio_egreso: 0,
        dni_persona: "",
        nom_persona: "",
        ape_paterno: "",
        ape_materno: "",
        foto_persona: "",
        email_persona: "",
        sexo: "1",
        fk_programa: 0,
        f_nacimiento: ""
    });

    const arrYears = getYears(2015);

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const handleChangeSexo = (idSexo) => {
        setValues({ ...values, sexo: idSexo });
    }

    const handleSelectInicYear = (year) => {
        setValues({...values, anio_inicio: year.year_id})
        setDefaultInicYear(year.year_id);
    }
    
    const handleSelectFinYear = (year) => {
        setValues({...values, anio_egreso: year.year_id})
        setDefaultFinYear(year.year_id);
    }

    const handleChangeFecha = (value) => {
        const strDate = dateFormatYYMMDD(value);
        setValues({ ...values, f_nacimiento: strDate });
    }

    const handleSelectSchool = (school) => {
        setValues({...values, fk_programa: school.id_programa})
    }

    const handleSetAction = () => {
        onChangeAction("list");
    }

    const handleSaveStudent = async () => {
        if(values.codigo === "" || values.dni_persona === "" || values.ape_paterno === "" || values.ape_materno === "" || values.email_persona === "") {
            dispatch(openNotifAction(["Datos incompletos", "error", 'filled']));
            return;
        }
        if(values.fk_programa === 0) {
            dispatch(openNotifAction(["Seleccione escuela", "error", 'filled']));
            return;
        }
        const rptaSave = await saveNewStudent(values);
        if(parseInt(rptaSave.flagInsert) > 0 || parseInt(rptaSave.flagUpdate) > 0) {
            dispatch(openNotifAction([rptaSave.msg, "success", 'filled']));
            dispatch(getAllStudents());
            onChangeAction("list");
        } else {
            dispatch(openNotifAction([rptaSave.msg, "error", 'filled']));
        }
    }

    const initData = () => {
        if(one_student !== null) {
            setValues({
                id_alumno: one_student.id_alumno,
                codigo: one_student.codigo,
                anio_inicio: one_student.anio_inicio,
                anio_egreso: one_student.anio_egreso,
                dni_persona: one_student.dni_persona,
                nom_persona: one_student.nom_persona,
                ape_paterno: one_student.ape_paterno,
                ape_materno: one_student.ape_materno,
                foto_persona: one_student.foto_persona,
                email_persona: one_student.email_persona,
                sexo: "1",
                fk_programa: one_student.id_programa,
                f_nacimiento: one_student.f_nacimiento,
            })
            setDefaultSchool(one_student.id_programa);
            setDefaultInicYear(one_student.anio_inicio);
            setDefaultFinYear(one_student.anio_egreso);
            setTitle("Actualizando registro");
        }
    }

    useEffect(() => {
        initData();
    }, [one_student]);

    return(
        <Box>
            <MKTypography className='color-primary' fontWeight='bold'>
                {title}
            </MKTypography>
            <Divider />
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            variant="standard"
                            label="Fecha"
                            inputFormat="dd/MM/yyyy"
                            // color="info"
                            value={values.f_nacimiento}
                            // onChange={(newValue) => {
                            //     setValueDate(newValue);
                            // }}
                            color='text'
                            // inputProps={{ className: classes.input }}
                            // InputLabelProps={{className: classes.inputLabel, shrink: true}}
                            onChange={(newValue) => {
                                handleChangeFecha(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        variant="standard"
                        name="codigo"
                        value={values.codigo}
                        placeholder="Codigo"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        variant="standard"
                        name="dni_persona"
                        value={values.dni_persona}
                        placeholder="DNI"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        variant="standard"
                        name="nom_persona"
                        value={values.nom_persona}
                        placeholder="Nombre"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        variant="standard"
                        name="ape_paterno"
                        value={values.ape_paterno}
                        placeholder="Ape. Paterno"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        variant="standard"
                        name="ape_materno"
                        value={values.ape_materno}
                        placeholder="Ape. Materno"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <SexoSelect dataSexo={DATA_SEXO} onSelectSexo={handleChangeSexo} sexoSelected={values.sexo}/>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        variant="standard"
                        name="email_persona"
                        value={values.email_persona}
                        placeholder="E-mail"
                        onChange={handleChange}
                        color='text'
                        sx={{ padding: '5px'}}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <SchoolSelect dataSchools={data_programs} onSelectSchool={handleSelectSchool} schoolSelected={defaultSchool} />
                </Grid>
                <Grid item xs={4}>
                    <YearSelect dataYears={arrYears} onSelectYear={handleSelectInicYear} yearSelected={defaultInicYear} title="Año de Inicio" />
                </Grid>
                <Grid item xs={4}>
                <YearSelect dataYears={arrYears} onSelectYear={handleSelectFinYear} yearSelected={defaultFinYear} title="Año de Egreso" />
                </Grid>
                <Grid item xs={12} textAlign='right'>
                    <Button variant='contained' sx={{color: '#FFF'}} onClick={handleSaveStudent}>Guardar</Button>
                    <Button onClick={handleSetAction}>Cancelar</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default RegisterStudent;