import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Divider, Grid, Icon, IconButton, Stack, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Tooltip } from "@mui/material";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { set_one_student_action } from "redux/actions/notesAction";
import { getAllStudents } from "redux/actions/notesAction";
import ConfirmationDialog from "components/ConfirmationDialog";
import { saveDeleteStudent } from "redux/actions/notesAction";
import { openNotifAction } from "redux/actions/severalAction";
import { getAllPrograms } from "redux/actions/publicationAction";
import { saveLoadFileImportEgresados } from "redux/actions/severalAction";
import { getOtherGeneralData } from "redux/actions/generalDataAction";
import MKTypography from "components/MKTypography";


const StudentList = ({onChangeAction}) => {
    const reducerStudent = "studentReducer";
    const dispatch = useDispatch();
    const data_students = useSelector(state => state[reducerStudent].data_students);
    const [dataStudents, setDataStudents] = useState(data_students);
    const [deleteStudent, setDeleteStudent] = useState({
        id_alumno: 0
    });
    const [urlTemplate, setUrlTemplate] = useState("");
    const [valorBusq, setValorBusq] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [stateConfirmDialog, setStateConfirmDialog] = useState({
        open: false,
        title: '',
        content: ''
    });
    const [openDialogImport, setOpenDialogImport] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0)
    }

    const handleFindStudent = (e) => {
        if(e.target.value === "") {
            setDataStudents(data_students);
        } else {
            let newList = data_students.filter(item => 
                item.dni_persona.toUpperCase().includes(e.target.value.toUpperCase()) || 
                item.nom_persona.toUpperCase().includes(e.target.value.toUpperCase()) || 
                item.ape_paterno.toUpperCase().includes(e.target.value.toUpperCase()) || 
                item.ape_materno.toUpperCase().includes(e.target.value.toUpperCase())
            );
            setDataStudents(newList);
        }
        setValorBusq(e.target.value);
    }

    const handleSetAction = () => {
        dispatch(getAllPrograms());
        dispatch(set_one_student_action(null));
        onChangeAction("register");
    }
    
    const handleEditStudent = async (student) => {
        await dispatch(getAllPrograms());
        await dispatch(set_one_student_action(student));
        onChangeAction("register");
    }

    const handleDeleteStudent = (studen) => {
        setDeleteStudent({
            id_alumno: parseInt(studen.id_alumno)
        });
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Alumno",
            content: "¿Seguro de Eliminar Alumno seleccionado?"
        });
    }

    const handleCloseConfirmDialog = async (value) => {
        if(value === "Yes") {
            const newDataStudent = {
                "id_alumno": deleteStudent.id_alumno
            };
			const rptaSave = await saveDeleteStudent(newDataStudent);
            if(rptaSave.success) {
                dispatch(openNotifAction(["Eliminación exitosa!!!", "success", 'filled']));
                await dispatch(getAllStudents());
            } else {
                dispatch(openNotifAction(["No fue posible realizar esta operación!!!", "error", 'filled']));
            }
        }
        setStateConfirmDialog({
            open: false,
            title: '',
            content: ''
        });
    }

    const handleOpenDialogImport = () => {
        setOpenDialogImport(true);
    }

    const handleCloseDialog = () => {
        setOpenDialogImport(false);
    };

    const handleSetImport = async () => {
        const rptaUpload = await onFileUpload();
        if(rptaUpload.success) {
            dispatch(openNotifAction(["La migración se realizo con exito", "success", "filled"]));
        } else {
            dispatch(openNotifAction(["No fue posible migrar Egresados", "error", "filled"]));
        }
        setOpenDialogImport(false);
    }

    const onFileImportChange = (event) => {
        setSelectedFile({ selectedFile: event.target.files[0] });
        // const nameExte = event.target.files[0].name.split('.')
        // setValuesImagen({ ...valuesImagen, nombreimagen: nameExte[0], formato: nameExte[1] });
    };

    const onFileUpload = async () => {
        if(selectedFile === null) {
            dispatch(openNotifAction(["Seleccione una imagen", "error", "filled"]));
            return;
        }
        const formData = new FormData();
        formData.append(
            "myFile",
            selectedFile.selectedFile,
            selectedFile.selectedFile.name,
        );
        let rptaUpload = await saveLoadFileImportEgresados(formData);
        console.log("rptaUpload...:", rptaUpload);
        return rptaUpload;
    };

    const initData = async () => {
        setDataStudents(data_students);
        const rptaDataGeneral = await(getOtherGeneralData());
        setUrlTemplate(rptaDataGeneral.data[0].url_plantilla);
        console.log("rptaDataGeneral...:", rptaDataGeneral.data[0]);
    }

    useEffect(() => {
        initData();
    }, [data_students]);


    return(
        <>
            {/* <MKTypography className='color-secondary' fontWeight='bold'>
                Listado
            </MKTypography>
            <Divider /> */}
            <Box>
                <Grid container>
                    <Grid item xs={12} lg={6}>
                        <TextField 
                            variant="standard"
                            name="valorBusq"
                            value={valorBusq}
                            placeholder="Buscar..."
                            onChange={handleFindStudent}
                            color='text'
                            sx={{ padding: '5px'}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} lg={6} textAlign='right'>
                        <Button variant='contained' sx={{color: '#FFF', marginRight: '10px'}} onClick={handleSetAction} >REGISTRAR NUEVO</Button>
                        <Button variant='contained' color='text' onClick={handleOpenDialogImport} >IMPORTAR</Button>
                        <Box pt={1} textAlign='right'>
                            <Icon sx={{display: 'inline'}} fontSize='long'>file_present</Icon>
                            <MKTypography display="inline" variant="button" fontWeight="bold">
                                <a href={urlTemplate} download>Descargar Plantilla</a>
                            </MKTypography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Divider />
            <Table>
                <TableHead>
                    <TableRow hover>
                        <TableCell align="center">Código</TableCell>
                        <TableCell align="center">DNI</TableCell>
                        <TableCell align="left">Nombre</TableCell>
                        <TableCell align="left">E-mail</TableCell>
                        <TableCell align="left">Escuela</TableCell>
                        <TableCell align="center">Inició</TableCell>
                        <TableCell align="center">Egresó</TableCell>
                        <TableCell align="right">...</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataStudents && dataStudents
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(student => {
                        return(
                            <TableRow key={"student" + student.id_alumno} hover>
                                <TableCell align="center">{student.codigo}</TableCell>
                                <TableCell align="center">{student.dni_persona}</TableCell>
                                <TableCell align="left">
                                    {student.nom_persona} {student.ape_paterno} {student.ape_materno}
                                </TableCell>
                                <TableCell align="left">{student.email_persona}</TableCell>
                                <TableCell align="left">{student.nombreprograma}</TableCell>
                                <TableCell align="center">{student.anio_inicio}</TableCell>
                                <TableCell align="center">{student.anio_egreso}</TableCell>
                                <TableCell align='right'>
                                    <Tooltip title='Editar'>
                                        <IconButton color='text' onClick={() => handleEditStudent(student)} ><EditIcon fontSize='small' /></IconButton>
                                    </Tooltip>
                                    <Tooltip title='Anular'>
                                        <IconButton color='text' onClick={() => handleDeleteStudent(student)}><DeleteIcon fontSize='small' /></IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <TablePagination
                component='div'
                rowsPerPageOptions={[5,10,50,100]}
                page={page}
                rowsPerPage={rowsPerPage}
                count={dataStudents ? dataStudents.length : 0 }
                labelRowsPerPage=''
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <ConfirmationDialog 
                open={stateConfirmDialog.open}
                title={stateConfirmDialog.title}
                content={stateConfirmDialog.content}
                onClose={handleCloseConfirmDialog}
            />

            <Dialog onClose={handleCloseDialog} open={openDialogImport} maxWidth="md" fullWidth>
                <DialogContent>
                    <Grid container spacing={2} pt={5}>
                        <Grid item xs={12}>
                        <div>
                            <input color="text" type="file" id="txtFile" onChange={onFileImportChange} />
                        </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={handleSetImport} style={{color: "#FFF"}}>Migrar</Button>
                    <Button onClick={handleCloseDialog}>Cancelar</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default StudentList;