import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import { set_one_program_action } from 'redux/actions/publicationAction';
import { ROOT_GALERIA } from 'helpers/helperInit';
import { set_one_publication_action } from 'redux/actions/publicationAction';

const CardPublication = ({data, handleLoadDetail}) => {
    const reducerGeneralData = "generalDataReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);

    let firstImage = "";
    const handleLoadData = () => {
        handleLoadDetail(data);
    }

    let pathImg = "";
    let pathPdf = "";
    if(data_general_data.length > 0) {
        pathImg = data_general_data[0].url_img_publicaciones;
        pathPdf = data_general_data[0].url_img_publicaciones;
    }

    if(data.imagenes.length > 0) {
        const listImage = data.imagenes.filter(item => item.formato === 'jpg' || item.formato === 'jpeg' || item.formato === 'png')
        if(listImage.length > 0) {
            // firstImage = ROOT_GALERIA + listImage[0].nombreimagen;
            firstImage = pathImg + listImage[0].nombreimagen + "." + listImage[0].formato;
        } else {
            firstImage = ROOT_GALERIA + "empty.jpg";
        }
    }

    return(
        <Card 
            onClick={handleLoadData}
            style={{
                marginTop: '20px',
                background: 'transparent',
                borderRadius: '0',
                cursor: 'pointer',
            }}
        >
            <Grid container>
                <Grid item xs={6}>
                    <MKBox
                        component="img"
                        src={firstImage}
                        width="100%"
                        zIndex={1}
                        style={{opacity: 0.8}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MKBox textAlign="center">
                        <MKTypography fontWeight="bold" sx={{ fontSize: '15px', color: "#000", lineHeight: 1}}>
                            {data.titulo.toUpperCase()}
                        </MKTypography>
                    </MKBox>
                    
                    <MKTypography className='color-primary' style={{fontSize: '12px', lineHeight: 1.5, padding: '2px 15px'}}>
                        {data.descripcion.substring(0,80)}...
                    </MKTypography>
                </Grid>
            </Grid>
        </Card>
    )
}

const ListPublications = () => {
    const dispatch = useDispatch();
    const reducerPublication = "publicationReducer";
    const data_publications = useSelector(state => state[reducerPublication].data_publications);
    const one_publication_type = useSelector(state => state[reducerPublication].one_publication_type);

    const [dataList, setDataList] = useState(null);
    const [load, setLoad] = useState(false);

    const keyTypePubl = sessionStorage.getItem("key_type_publication");

    const handleLoadDetail = (publication) => {
        dispatch(set_one_publication_action(publication));
    }

    const initData = () => {
        const newList = data_publications.filter(item => parseInt(item.fk_categoria) === parseInt(keyTypePubl));
        // if(!load) {
        //     if(newList.length > 0) {
        //         dispatch(set_one_publication_action(dataList[0]));
        //     }
        //     setLoad(true);
        // }
        setDataList(newList);
    }

    useEffect(async () => {
        await initData();
        // if(dataList !== null) {
        //     if(!dataList.id_publicacion) {
        //         dispatch(set_one_publication_action(dataList[0]));
        //         load(true);
        //     }
        // }
    }, [data_publications, one_publication_type]);

    return(
        <Box
            heigth="100%"
            // minHeight={"1190px"}
            width="100%"
            sx={{
                background: '#FFF',
                backgroundPosition: "left",
                placeItems: "left",
                align: 'left',
            }}
        >
            {dataList !== null && dataList.map(publication => {
                return(
                    <Box key={publication.id_publicacion} style={{padding: '5px'}}>
                        <CardPublication data={publication} handleLoadDetail={handleLoadDetail} />
                    </Box>
                );
            })}
        </Box>
    )
}
export default ListPublications;