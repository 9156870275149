import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { saveEditPublication } from 'redux/actions/publicationAction';
import { getGeneralData } from 'redux/actions/generalDataAction';
import { saveGeneralData } from 'redux/actions/generalDataAction';
import { openNotifAction } from 'redux/actions/severalAction';
import { saveLoadFileImage } from 'redux/actions/severalAction';
import MKTypography from 'components/MKTypography';
import { saveLoadFilePDF } from 'redux/actions/severalAction';

const RegisterGeneralData = () => {
    const dispatch = useDispatch();
    const reducerGeneralData = "generalDataReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);
    const [values, setValues] = useState({
        "id_datos_generales": 0,
        "nombre_institucion": "",
        "nombrecorto": "",
        "mision": "",
        "vision": "",
        "objetivos": "",
        "imagen_escudo": "",
        "direccion": "",
        "telefono": "",
        "web": "",
        "email": "",
        "facebook": "",
        "twiter": "",
        "lema": "",
        "resena": "",
        "pdf_creacion": "",
        "pdf_revalidacion": "",
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [fieldName, setFieldName] = useState("");
    const [openDialogPdf, setOpenDialogPdf] = useState(false);
    const [nomFilePdf, setNomFilePdf] = useState("");

    const handleSaveGeneralData = async () => {
        const rptaSave = await saveGeneralData(values);
        if(!!rptaSave.success) {
            dispatch(openNotifAction(["Información actualizada con exito!!!", "success", 'filled']));
            dispatch(getGeneralData());
        } else {
            dispatch(openNotifAction(["No fue posible actualizar informacion", "error", 'filled']));
        }
    }

    const handleCancelSave = () => {
        dispatch(getGeneralData());
    }

    const handleChangeValues = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const handleOpenDialogPdf = (field) => {
        setFieldName(field);
        setNomFilePdf("");
        setOpenDialogPdf(true);
    }

    const onFilePdfChange = (event) => {
        setNomFilePdf(event.target.files[0].name);
        setSelectedFile({ selectedFile: event.target.files[0] });
    }

    const onFilePdfUpload = async () => {
        if(selectedFile === null) {
            dispatch(openNotifAction(["Seleccione un archivo", "error", "filled"]));
            return null;
        }
        const formData = new FormData();
        formData.append(
            "myFile",
            selectedFile.selectedFile,
            selectedFile.selectedFile.name,
        );
        let rptaUpload = await saveLoadFilePDF(formData);
        return rptaUpload.filename;
    };

    const handleSetFilePdf = async () => {
        const fileName = await onFilePdfUpload();
        if(fileName !== null) {
            if(fieldName === "pdf_creacion") {
                setValues({...values, pdf_creacion: fileName});
            } else {
                setValues({...values, pdf_revalidacion: fileName});
            }
            setOpenDialogPdf(false);
        }
    }

    const handleCloseDialog = () => {
        setOpenDialogPdf(false);
    };

    const initData = () => {
        if(data_general_data.length > 0) {
            setValues({
                ...values,
                id_datos_generales: data_general_data[0].id_datos_generales !== null ? data_general_data[0].id_datos_generales : 0,
                nombre_institucion: data_general_data[0].nombre_institucion !== null ? data_general_data[0].nombre_institucion : "",
                nombrecorto: data_general_data[0].nombrecorto !== null ? data_general_data[0].nombrecorto : "",
                mision: data_general_data[0].mision !== null ? data_general_data[0].mision : "",
                vision: data_general_data[0].vision !== null ? data_general_data[0].vision : "",
                objetivos: data_general_data[0].objetivos !== null ? data_general_data[0].objetivos : "",
                imagen_escudo: data_general_data[0].imagen_escudo !== null ? data_general_data[0].imagen_escudo : "",
                direccion: data_general_data[0].direccion !== null ? data_general_data[0].direccion : "",
                telefono: data_general_data[0].telefono !== null ? data_general_data[0].telefono : "",
                web: data_general_data[0].web !== null ? data_general_data[0].web : "",
                email: data_general_data[0].email !== null ? data_general_data[0].email : "",
                facebook: data_general_data[0].facebook !== null ? data_general_data[0].facebook : "",
                twiter: data_general_data[0].twiter !== null ? data_general_data[0].twiter : "",
                lema: data_general_data[0].lema !== null ? data_general_data[0].lema : "",
                resena: data_general_data[0].resena !== null ? data_general_data[0].resena : "",
                pdf_creacion: data_general_data[0].pdf_creacion !== null ? data_general_data[0].pdf_creacion : "",
                pdf_revalidacion: data_general_data[0].pdf_revalidacion !== null ? data_general_data[0].pdf_revalidacion : "",
            });
        }
    }

    useEffect(() => {
        initData();
    }, []);

    return(
        <>
        <Card >
            <Container sx={{textAlign: 'right', paddingTop: '5px'}}>
                <Button 
                    variant='contained' 
                    color='primary' 
                    style={{color: '#FFF', marginRight: '5px'}}
                    onClick={handleSaveGeneralData}
                >
                    GUARDAR
                </Button>
                {/* <Button 
                    variant='contained' 
                    color='secondary' 
                    style={{color: '#FFF'}}
                    onClick={handleCancelSave}
                >
                    CANCELAR
                </Button> */}
            </Container>
            <Divider />
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField 
                            variant="standard"
                            label="Nombre"
                            name="nombre_institucion"
                            value={values.nombre_institucion}
                            placeholder="Nombre de la institución"
                            fullWidth
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            variant="standard"
                            label="Nombre Corto"
                            name="nombrecorto"
                            value={values.nombrecorto}
                            placeholder="Nombre Corto"
                            fullWidth
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            variant="standard"
                            label="Lema"
                            name="lema"
                            value={values.lema}
                            placeholder="Nuestro lema"
                            fullWidth
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label="Reseña"
                            name="resena"
                            value={values.resena}
                            placeholder="Breve Reseña"
                            multiline
                            fullWidth
                            rows={4}
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField 
                            variant="standard"
                            label="Tlf"
                            name="telefono"
                            value={values.telefono}
                            placeholder="Nro de Teléfono / Celular"
                            fullWidth
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField 
                            variant="standard"
                            label="Dirección"
                            name="direccion"
                            value={values.direccion}
                            placeholder="Dirección de la Institución"
                            fullWidth
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={12} pb={6}>
                        <TextField
                            variant="standard"
                            label="Misión"
                            name="mision"
                            value={values.mision}
                            placeholder="Misión"
                            multiline
                            fullWidth
                            rows={4}
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={12} pb={6}>
                        <TextField
                            variant="standard"
                            label="Vision"
                            name="vision"
                            value={values.vision}
                            placeholder="Vision"
                            multiline
                            fullWidth
                            rows={4}
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={12} pb={6}>
                        <TextField
                            variant="standard"
                            label="Objetivos"
                            name="objetivos"
                            value={values.objetivos}
                            placeholder="Objetivos"
                            multiline
                            fullWidth
                            rows={4}
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            variant="standard"
                            label="Web"
                            name="web"
                            value={values.web}
                            placeholder="Página web"
                            fullWidth
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            variant="standard"
                            label="E-mail"
                            name="email"
                            value={values.email}
                            placeholder="Dirección E-mail"
                            fullWidth
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            variant="standard"
                            label="Facebook"
                            name="facebook"
                            value={values.facebook}
                            placeholder="Facebook"
                            fullWidth
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField 
                            variant="standard"
                            label="Twiter"
                            name="twiter"
                            value={values.twiter}
                            placeholder="Twiter"
                            fullWidth
                            onChange={handleChangeValues}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MKTypography display='inline' variant='body2' pr={5}>
                            Creacion
                        </MKTypography>
                        <Button 
                            variant='contained' 
                            style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                            onClick={() => handleOpenDialogPdf("pdf_creacion")}
                        >
                            Archivo
                        </Button>
                        <MKTypography display='inline' variant='body2' pr={5}>
                            {values.pdf_creacion}
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12}>
                        <MKTypography display='inline' variant='body2' pr={5}>
                            Revalidación
                        </MKTypography>
                        <Button 
                            variant='contained' 
                            style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                            onClick={() => handleOpenDialogPdf("pdf_revalidacion")}
                        >
                            Archivo
                        </Button>
                        <MKTypography display='inline' variant='body2' pr={5}>
                            {values.pdf_revalidacion}
                        </MKTypography>
                    </Grid>
                </Grid>
            </Container>
            <Container sx={{textAlign: 'right', paddingTop: '5px'}}>
                <Button 
                    variant='contained' 
                    color='primary' 
                    style={{color: '#FFF', marginRight: '5px'}}
                    onClick={handleSaveGeneralData}
                >
                    GUARDAR
                </Button>
                <Button 
                    variant='contained' 
                    color='secondary' 
                    style={{color: '#FFF'}}
                    onClick={handleCancelSave}
                >
                    CANCELAR
                </Button>
            </Container>
        </Card>

        <Dialog onClose={handleCloseDialog} open={openDialogPdf} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12}>
                        <div>
                            <input color="text" type="file" id="txtFile" onChange={onFilePdfChange} />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetFilePdf} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>
        </>
    );
}

export default RegisterGeneralData;