// import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ContentPublication from "./ContentPublication";
import ListPublications from "./ListPublications";
import LinkServices from "pages/LandingPages/Services/sections/LinkServices";
import LinkFirstType from "./LinkFirstType";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { set_one_publication_action } from "redux/actions/publicationAction";

const ContentPage = () => {
    const dispatch = useDispatch();
    // const reducerService = "serviceReducer";
    // const one_service_type = useSelector(state => state[reducerService].one_service_type);
    const reducerPublication = "publicationReducer";
    const data_publications = useSelector(state => state[reducerPublication].data_publications);
    const one_publication = useSelector(state => state[reducerPublication].one_publication);

    console.log("one_publication...:", one_publication.id_publicacion);
    console.log("data_publications...:", data_publications);

    useEffect(() => {
        if(typeof one_publication.id_publicacion === 'undefined'){
            console.log("noe xiste seleccionado")
            if(data_publications.length > 0) {
                dispatch(set_one_publication_action(data_publications[0]));
            }
        }
    });

    return(
        <Box>
            <Grid container>
                <Grid item xs={12} lg={3}>
                    <ListPublications />
                </Grid>
                <Grid item xs={12} lg={7}>
                    <ContentPublication />
                </Grid>
                <Grid item xs={12} lg={2}>
                    <LinkFirstType />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ContentPage;