import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";

// @mui material components
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Helpers
import { ROOT_SERVER } from 'helpers/helperInit';
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { ROOT_GALERIA } from "helpers/helperInit";

const imgAbaut = ROOT_SERVER + '/static/images/academyc/quienes.png';
const imgReclamos = ROOT_SERVER + '/static/images/academyc/reclamos.png';
const imgEgresados = ROOT_GALERIA + 'egresados.jpg';
const imgLocate = ROOT_SERVER + '/static/images/icons/image1.png';

const HomeAboutUs = () => {
    const reducerGeneralData = "generalDataReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);
    const [openDialogFrame, setOpenDialogFrame] = useState(false);
    const [urlPdf, setUrlPdf] = useState("");
    let pathPdf = "";
    if(data_general_data.length > 0) {
        pathPdf = data_general_data[0].url_img_publicaciones;
    }

    useEffect(() => {
        if(data_general_data.length > 0) {
            document.title = data_general_data[0].nombrecorto;
        }
    });

    const handleOpenDialogFrame = (valUrl) => {
        setUrlPdf(valUrl);
        setOpenDialogFrame(true);
    }

    const handleCloseDialog = () => {
        setOpenDialogFrame(false);
    };

    return(
        <>
            <img src={imgLocate} width={30} />
            <MKTypography display="inline" variant="h5" className='color-four' 
            textTransform="capitalize" fontWeight="bold">
                QUIENES SOMOS
            </MKTypography>
            <hr />
            {/* <div style={{padding: '2% 2%'}}>
                <MKTypography variant="body2" component="p" style={{color: "#000"}} >
                {data_general_data.length > 0 && data_general_data[0].resena !== null ? data_general_data[0].resena.substring(0,150) : ""}
                </MKTypography>
            </div> */}
            <Grid container px={6} spacing={2}>
                <Grid item xs={12} lg={4}>
                    <MKBox 
                        component={Link}
                        to={ROOT_SERVER + "/pages/about-us"}
                        style={{border: 'none'}}
                        
                    >
                        <MKBox p={3} mt={-1} pt={6} textAlign="center">
                            <Grid container>
                                <Grid item xs={12} alignSelf='center'>
                                    <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
                                    <MKBox
                                        component="img"
                                        src={imgAbaut}
                                        borderRadius="lg"
                                        width="100%"
                                        position="relative"
                                        zIndex={1}
                                        style={{opacity: 0.8}}
                                    />
                                    </MKBox>
                                </Grid>
                                <Grid item xs={12}>
                                    <MKTypography display="inline" variant="h5" className='color-primary' textTransform="capitalize" fontWeight="bold">
                                       NUESTRA INSTUCIÓN
                                    </MKTypography>
                                    {/* <MKBox mt={1} mb={3}>
                                        <MKTypography variant="body2" component="p" style={{textAlign: 'left'}}>
                                            {data_general_data.length > 0 && data_general_data[0].mision.substring(0,150)}...
                                        </MKTypography>
                                    </MKBox> */}
                                </Grid>
                            </Grid>
                        </MKBox>
                    </MKBox>
                </Grid>
                <Grid item xs={12} lg={4} border={0}>
                    <MKBox 
                        href={ROOT_SERVER + "/static/files/pdf/flojograma.pdf"}
                        key={ROOT_SERVER + "/static/files/pdf/flojograma.pdf"}
                        // component="a"
                        style={{border: 'none', cursor: 'pointer'}}
                        target="_blank"
                        rel="noreferrer"
                        variant="gradient"
                        size="small"     
                        onClick={() => handleOpenDialogFrame(pathPdf + "flujograma.pdf")}                  
                    >
                        <MKBox p={3} mt={-1} pt={6} textAlign="center">
                            <Grid container>
                                <Grid item xs={12} alignSelf='center'>
                                    <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
                                        <MKBox
                                            component="img"
                                            src={imgReclamos}
                                            borderRadius="lg"
                                            width="100%"
                                            position="relative"
                                            zIndex={1}
                                            style={{opacity: 0.8}}
                                        />
                                    </MKBox>
                                </Grid>
                                <Grid item xs={12} >
                                    <MKTypography display="inline" variant="h5" className='color-primary' textTransform="capitalize" fontWeight="bold" >
                                        TRAMITES Y RECLAMOS
                                    </MKTypography>
                                    {/* <MKBox mt={1} mb={3}>
                                        <MKTypography variant="body2" component="p" style={{textAlign: 'left'}}>
                                            {data_general_data.length > 0 && data_general_data[0].vision.substring(0,150)}...
                                        </MKTypography>
                                    </MKBox> */}
                                </Grid>
                            </Grid>
                        </MKBox>
                    </MKBox>
                </Grid>
                <Grid item xs={12} lg={4} >
                    <MKBox 
                        component={Link}
                        to={ROOT_SERVER + "/pages/egresados"}
                        style={{border: 'none'}}
                    >
                        <MKBox p={3} mt={-1} pt={6} textAlign="center">
                            <Grid container>
                                <Grid item xs={12} alignSelf='center'>
                                    <MKBox position="relative" borderRadius="lg" mx={2} mt={-3}>
                                        <MKBox
                                            component="img"
                                            src={imgEgresados}
                                            borderRadius="lg"
                                            width="100%"
                                            position="relative"
                                            zIndex={1}
                                            style={{opacity: 0.8}}
                                        />
                                    </MKBox>
                                </Grid>
                                <Grid item xs={12}>
                                    <MKTypography display="inline" variant="h5" className='color-primary' textTransform="capitalize" fontWeight="bold">
                                        NUESTROS EGRESADOS
                                    </MKTypography>
                                    {/* <MKBox mt={1} mb={3}>
                                        <MKTypography variant="body2" component="p" style={{textAlign: 'left'}}>
                                        Las competencias que adquieren nuestros egresados son transformadoras porque los impulsan a generar impactos positivos en la sociedad.
                                        </MKTypography>
                                    </MKBox> */}
                                </Grid>
                            </Grid>
                        </MKBox>
                    </MKBox>
                </Grid>
            </Grid>

            <Dialog onClose={handleCloseDialog} open={openDialogFrame} maxWidth="xl" fullWidth >
                <DialogTitle
                >
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    sx={{ 
                        height: '95vh'
                    }}
                >
                    <Divider />
                    <iframe style={{width: '100%', height: '100%'}} src={urlPdf} />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default HomeAboutUs;