import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import Container from '@mui/material/Container'

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { openNotifAction } from 'redux/actions/severalAction';
import MKTypography from 'components/MKTypography';

import { Box } from '@mui/system';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { getAllPrograms } from 'redux/actions/publicationAction';
import { saveNewProgram } from 'redux/actions/publicationAction';
import { saveEditProgram } from 'redux/actions/publicationAction';
import { saveNewProgramModality } from 'redux/actions/publicationAction';
import { saveNewProgramRequiment } from 'redux/actions/publicationAction';
import { saveNewProgramPlan } from 'redux/actions/publicationAction';
import { saveNewProgramImagen } from 'redux/actions/publicationAction';
import { saveLoadFileImage } from 'redux/actions/severalAction';
import { saveLoadFilePDF } from 'redux/actions/severalAction';

const RegisterProgram = ({onChangeAction}) => {
    const dispatch = useDispatch();
    const reducerProgram = "programReducer";
    const one_program = useSelector(state => state[reducerProgram].one_program);
    const [title, setTitle] = useState("Nuevo Registro");
    const [values, setValues] = useState({
        "id_programa": 0,
        "nombreprograma": "",
        "descripcion": "",
        "imagenprograma": ""
    });
    const [editing, setEditing] = useState(false);
    const [idElementEdit, setIdElementEdit] = useState(0);
    const [nomElemeDelete, setNomElemenrDelete] = useState("");
    const [openDialogImagen, setOpenDialogImagen] = useState(false);
    const [nomImagen, setNomImagen] = useState("");

    const [dataModality, setDataModality] = useState([]);
    const [valuesModality, setValuesModality] = useState({
        modalidad: "",
        descripcion: "",
        pdf: ""
    });
    const [openDialogModality, setOpenDialogModality] = useState(false);

    const [dataRequirement, setDataRequirement] = useState([]);
    const [valuesRequirement, setValuesRequirement] = useState({
        descripcion: "",
        pdf: "",
    });
    const [openDialogRequirement, setOpenDialogRequirement] = useState(false);
        
    const [dataPlan, setDataPlan] = useState([]);
    const [valuesPlan, setValuesPlan] = useState({
        descripcion: "",
        anio: 0,
        pdf: "",
    });
    const [openDialogPlan, setOpenDialogPlan] = useState(false);

    const [dataImagen, setDataImagen] = useState([]);
    const [valuesImagen, setValuesImagen] = useState({
        descripcion: "",
        nombreimagen: "",
        formato: "",
    });
    const [openDialogProgImagen, setOpenDialogProgImagen] = useState(false);

    const [stateConfirmDialog, setStateConfirmDialog] = useState({
        open: false,
        title: '',
        content: ''
    });
    const [selectedFile, setSelectedFile] = useState(null);

    const handleSaveProgram = async () => {
        if(values.titulo === "" || values.descripcion === "") {
            dispatch(openNotifAction(["Datos incompletos", "error", 'filled']));
            return;
        }
        let idNewProg = values.id_programa;
        if(parseInt(values.id_programa) === 0) {
            const rptaSave = await saveNewProgram(values);
            idNewProg = rptaSave.data[0].id_programa;
        } else {
            const rptaSave = await saveEditProgram(values);
        }
        for(var i = 0; i < dataModality.length; i++) {
            dataModality[i].fk_programa = idNewProg;
            const rptaModality = await saveNewProgramModality(dataModality[i]);
        }

        for(var i = 0; i < dataRequirement.length; i++) {
            dataRequirement[i].fk_programa = idNewProg;
            const rptaRequirement = await saveNewProgramRequiment(dataRequirement[i]);
        }
        
        for(var i = 0; i < dataPlan.length; i++) {
            dataPlan[i].fk_programa = idNewProg;
            const rptaPlan = await saveNewProgramPlan(dataPlan[i]);
        }
        
        for(var i = 0; i < dataImagen.length; i++) {
            dataImagen[i].fk_programa = idNewProg;
            const rptaImagen = await saveNewProgramImagen(dataImagen[i]);
        }

        await dispatch(getAllPrograms());
        onChangeAction("list");
    }

    const handleSetAction = () => {
        onChangeAction("list");
    }

    const handleChange = (e) => {
        setValues({...values, [e.target.name]: e.target.value});
    }

    const handleOpenDialogImagen = () => {
        setOpenDialogImagen(true);
        setNomImagen("");
    }

    const onFileImagenChange = (event) => {
        setNomImagen(event.target.files[0].name);
    }

    const handleSetImagen = () => {
        setValues({...values, imagenprograma: nomImagen});
        setOpenDialogImagen(false);
    }

    const handleOpenDialogModality = (modality) => {
        if(modality === null) {
            setValuesModality({
                modalidad: "",
                descripcion: "",
                pdf: ""
            });
            setEditing(false);
            setIdElementEdit(0);
        } else {
            setValuesModality({
                modalidad: modality.modalidad !== null ? modality.modalidad : "",
                descripcion: modality.descripcion !== null ? modality.descripcion : "",
                pdf: modality.pdf !== null ? modality.pdf : "",
            });
            setEditing(true);
            setIdElementEdit(modality.id_programa_modalidad);
        }
        setOpenDialogModality(true);
    }
    
    const handleOpenDialogRequirement = (requirement) => {
        if(requirement === null) {
            setValuesRequirement({
                descripcion: "",
                pdf: "",
            });
            setEditing(false);
            setIdElementEdit(0);
        } else {
            setValuesRequirement({
                descripcion: requirement.descripcion !== null ? requirement.descripcion : "",
                pdf: requirement.pdf !== null ? requirement.pdf : "",
            });
            setEditing(true);
            setIdElementEdit(requirement.id_programa_requisito);
        }
        
        setOpenDialogRequirement(true);
    }
    
    const handleOpenDialogPlan = (plan) => {
        if(plan === null) {
            setValuesPlan({
                descripcion: "",
                anio: 0,
                pdf: ""
            });
            setEditing(false);
            setIdElementEdit(0);
        } else {
            setValuesPlan({
                descripcion: plan.descripcion !== null ? plan.descripcion : "",
                anio: plan.anio !== null ? plan.anio : 0,
                pdf: plan.pdf !== null ? plan.pdf : "",
            });
            setEditing(true);
            setIdElementEdit(plan.id_programa_plan);
        }
        setOpenDialogPlan(true);
    }
    
    const handleOpenDialogProgImagen = (imagen, index) => {
        if(imagen === null) {
            setValuesImagen({
                descripcion: "",
                nombreimagen: "",
                formato: ""
            });
            setEditing(false);
        } else {
            setValuesImagen({
                descripcion: imagen.descripcion !== null ? imagen.descripcion: "",
                nombreimagen: imagen.nombreimagen !== null ? imagen.nombreimagen: "",
                formato: imagen.formato !== null ? imagen.formato: "",
            });
            setEditing(true);
            // setIdElementEdit(imagen.id_programa_imagen);
        }
        setIdElementEdit(index);
        setOpenDialogProgImagen(true);
    }
    const onFilePdfUpload = async () => {
        // if(selectedFile === null) {
        //     dispatch(openNotifAction(["Seleccione un archivo", "error", "filled"]));
        //     return null;
        // }
        // const formData = new FormData();
        // formData.append(
        //     "myFile",
        //     selectedFile.selectedFile,
        //     selectedFile.selectedFile.name,
        // );
        // let rptaUpload = await saveLoadFilePDF(formData);
        // return rptaUpload.filename;
        let fileName = "";
        if(selectedFile !== null) {
            // dispatch(openNotifAction(["Seleccione un archivo", "error", "filled"]));
            // return null;
            const formData = new FormData();
            formData.append(
                "myFile",
                selectedFile.selectedFile,
                selectedFile.selectedFile.name,
            );
            let rptaUpload = await saveLoadFilePDF(formData);
            fileName = rptaUpload.filename
        }
        return fileName;
    };

    const handleChangeModality = (e) => {
        setValuesModality({...valuesModality, [e.target.name]: e.target.value})
    }

    const onFileModalityChange = (event) => {
        // setValuesModality({ ...valuesModality, pdf: event.target.files[0].name });
        setSelectedFile({ selectedFile: event.target.files[0] });
    };
    
    const handleChangeRequirement = (e) => {
        setValuesRequirement({...valuesRequirement, [e.target.name]: e.target.value})
    }

    const onFileRequirementChange = (event) => {
        // setValuesRequirement({ ...valuesRequirement, pdf: event.target.files[0].name });
        setSelectedFile({ selectedFile: event.target.files[0] });
    };
    
    const handleChangePlan = (e) => {
        setValuesPlan({...valuesPlan, [e.target.name]: e.target.value})
    }

    const onFilePlanChange = (event) => {
        // setValuesPlan({ ...valuesPlan, pdf: event.target.files[0].name });
        setSelectedFile({ selectedFile: event.target.files[0] });
    };

    const handleChangeImagen = (e) => {
        setValuesImagen({...valuesImagen, [e.target.name]: e.target.value})
    }

    const onFileProgImagenChange = (event) => {
        setSelectedFile({ selectedFile: event.target.files[0] });
        // const nameExte = event.target.files[0].name.split('.')
        // setValuesImagen({ ...valuesImagen, nombreimagen: nameExte[0], formato: nameExte[1] });
    };

    const onFileUpload = async () => {
        if(selectedFile === null) {
            dispatch(openNotifAction(["Seleccione una imagen", "error", "filled"]));
            return;
        }
        const formData = new FormData();
        formData.append(
            "myFile",
            selectedFile.selectedFile,
            selectedFile.selectedFile.name,
        );
        let rptaUpload = await saveLoadFileImage(formData);
        const nameExte = await rptaUpload.filename.split('.');
        return rptaUpload.filename;
        // const newImg = {
        //     nombreimagen: nameExte[0], formato: nameExte[1]
        // };
        // const nameExte = event.target.files[0].name.split('.')
        // await setValuesImagen({ ...valuesImagen, nombreimagen: nameExte[0], formato: nameExte[1] });
    };
    
    const handleSetModality = async () => {
        const fileName = await onFilePdfUpload();
        if(fileName !== null) {
            if(!editing) {
                const newElem = {
                    modalidad: valuesModality.modalidad,
                    descripcion: valuesModality.descripcion,
                    pdf: fileName
                }
                dataModality.push(newElem);
            } else {
                dataModality.map(item => {
                    if(parseInt(item.id_programa_modalidad) === parseInt(idElementEdit)) {
                        item.modalidad = valuesModality.modalidad;
                        item.descripcion = valuesModality.descripcion;
                        item.pdf = fileName;
                        return;
                    }
                })
            }
        }
        setOpenDialogModality(false);
    }
    
    const handleSetRequirement = async () => {
        const fileName = await onFilePdfUpload();
        if(fileName !== null) {
            if(!editing) {
                const newElem = {
                    descripcion: valuesRequirement.descripcion,
                    pdf: fileName
                }
                dataRequirement.push(newElem);
            } else {
                dataRequirement.map(item => {
                    if(parseInt(item.id_programa_requisito) === parseInt(idElementEdit)) {
                        item.descripcion = valuesRequirement.descripcion;
                        item.pdf = fileName;
                        return;
                    }
                });
            }
            setOpenDialogRequirement(false);
        }
    }
    
    const handleSetPlan = async () => {
        const fileName = await onFilePdfUpload();
        if(fileName !== null) {
            if(!editing) {
                const newElem = {
                    descripcion: valuesPlan.descripcion,
                    anio: valuesPlan.anio,
                    pdf: fileName
                }
                dataPlan.push(newElem);
            } else {
                dataPlan.map(item => {
                    if(parseInt(item.id_programa_plan) === parseInt(idElementEdit)) {
                        item.descripcion = valuesPlan.descripcion;
                        item.anio = valuesPlan.anio;
                        item.pdf = fileName
                        return;
                    }
                });
            }
            setOpenDialogPlan(false);
        }
    }
    
    const handleSetProgImagen = async () => {
        const fileName = await onFileUpload();
        const nameExte = fileName.split('.')
        const newImg = {
            descripcion: valuesImagen.descripcion, 
            nombreimagen: nameExte[0],
            formato: nameExte[1]
        };
        if(!editing) {
            dataImagen.push(newImg);
        } else {
            dataImagen.map((item, index) => {
                if(parseInt(index) === parseInt(idElementEdit)) {
                    item.descripcion = valuesImagen.descripcion;
                    item.nombreimagen = valuesImagen.nombreimagen
                    item.formato = valuesImagen.formato
                    return;
                }
            });
        }
        setOpenDialogProgImagen(false);
    }

    const handleCloseDialog = () => {
        setOpenDialogModality(false);
        setOpenDialogRequirement(false);
        setOpenDialogPlan(false);
        setOpenDialogProgImagen(false);
        setOpenDialogImagen(false);
    };

    const handleDeleteModality = (modality) => {
        setIdElementEdit(modality.id_programa_modalidad);
        setNomElemenrDelete("modality");
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Modalidad",
            content: "¿Seguro de Eliminar la modalidad seleccionada?"
        });
    }
    
    const handleDeleteRequirement = (requirement) => {
        setIdElementEdit(requirement.id_programa_requisito);
        setNomElemenrDelete("requirement");
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Requisito",
            content: "¿Seguro de Eliminar el requisito seleccionado?"
        });
    }
    
    const handleDeletePlan = (plan) => {
        setIdElementEdit(plan.id_programa_plan);
        setNomElemenrDelete("plan");
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Plan",
            content: "¿Seguro de Eliminar el plan seleccionado?"
        });
    }
    
    const handleDeleteImagen = (index) => {
        setIdElementEdit(index);
        setNomElemenrDelete("imagen");
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Imagen",
            content: "¿Seguro de Eliminar la imagen seleccionada?"
        });
    }

    const handleCloseConfirmDialog = async (value) => {
        if(value === "Yes") {
            if(nomElemeDelete === "modality"){
                const newList = dataModality.filter(item => parseInt(item.id_programa_modalidad) !== parseInt(idElementEdit));
                setDataModality(newList);
            }
            if(nomElemeDelete === "requirement"){
                const newList = dataRequirement.filter(item => parseInt(item.id_programa_requisito) !== parseInt(idElementEdit));
                setDataRequirement(newList);
            }
            if(nomElemeDelete === "plan"){
                const newList = dataPlan.filter(item => parseInt(item.id_programa_plan) !== parseInt(idElementEdit));
                setDataPlan(newList);
            }
            if(nomElemeDelete === "imagen"){
                const newList = dataImagen.filter((item, index) => parseInt(index) !== parseInt(idElementEdit));
                setDataImagen(newList);
            }
        }
        setStateConfirmDialog({
            open: false,
            title: '',
            content: ''
        });
        setIdElementEdit(0);
        setNomElemenrDelete("");
    }

    const initData = () => {
        if(one_program !== null) {
            setValues({
                id_programa: one_program.id_programa, 
                nombreprograma: one_program.nombreprograma,
                descripcion: one_program.descripcion,
                imagenprograma: one_program.imagenprograma
            });
            setDataModality(one_program.modalidad);
            setDataPlan(one_program.plan);
            setDataRequirement(one_program.requisito);
            setDataImagen(one_program.imagen);

            setTitle("Actualizando registro");
        }
    }

    useEffect(() => {
        initData();
    }, [one_program]);


    return(
        <>
        <MKTypography className='color-primary'>
            {title}
        </MKTypography>
        <Card >
            <Container sx={{textAlign: 'right', paddingTop: '5px'}}>
                <Button 
                    variant='contained' 
                    color='primary' 
                    style={{color: '#FFF', marginRight: '5px'}}
                    onClick={handleSaveProgram}
                >
                    GUARDAR
                </Button>
                <Button 
                    variant='contained' 
                    color='secondary' 
                    style={{color: '#FFF'}}
                    onClick={handleSetAction}
                >
                    CANCELAR
                </Button>
            </Container>
            <Divider />
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField 
                            variant="standard"
                            label="Título"
                            name="nombreprograma"
                            value={values.nombreprograma}
                            placeholder="Título"
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label="Descripción"
                            name="descripcion"
                            value={values.descripcion}
                            placeholder="Descripción"
                            multiline
                            fullWidth
                            rows={4}
                            onChange={handleChange}
                        />
                    </Grid>
                    {/* <Grid item xs={12} pb={2}>
                        <MKTypography display='inline' variant='body2' pr={5}>
                            {values.imagenprograma}
                        </MKTypography>
                        <Button 
                            variant='contained' 
                            style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                            onClick={() => handleOpenDialogImagen(null)}
                        >
                            Imagen
                        </Button>
                    </Grid> */}
                </Grid>
                <>
                <MKTypography pt={5} mb={1} pr={3} display='inline' sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                    <u>Perfil de Egreso</u>
                </MKTypography>
                <Button 
                    variant='contained' 
                    style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                    onClick={() => handleOpenDialogModality(null)}
                >
                    <AddIcon />
                </Button>
                <Table>
                    <TableBody>
                        {dataModality.map((modality, index) => {
                            return(
                                <TableRow hover key={"key" + index}>
                                    <TableCell style={{width: '30%'}}>
                                        <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}} pl={2}>
                                            {modality.modalidad !== null ? modality.modalidad : ""}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell style={{width: '50%'}}>
                                        <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={2}>
                                            {modality.descripcion !== null ? modality.descripcion : ""}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell style={{width: '30%'}}>
                                        <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}} pl={2}>
                                            {modality.pdf !== null ? modality.pdf : ""}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Box sx={{width: '80px'}}>
                                            <Tooltip title='Editar'>
                                                <IconButton onClick={() => handleOpenDialogModality(modality)}>
                                                    <EditIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title='Anular'>
                                                <IconButton onClick={() => handleDeleteModality(modality)}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                </>
                <Divider />
                <>
                <MKTypography pt={5} mb={1} pr={3} display='inline' sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                    <u>Requisitos</u>
                </MKTypography>
                <Button 
                    variant='contained' 
                    // color='secondary' 
                    style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                    onClick={() => handleOpenDialogRequirement(null)}
                >
                    <AddIcon />
                </Button>
                <Table>
                    <TableBody>
                        {dataRequirement.map((requirement, index) => {
                            return(
                                <TableRow hover key={"key" + index}>
                                    <TableCell style={{width: '70%'}}>
                                        <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={2}>
                                            {requirement.descripcion}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell style={{width: '30%'}}>
                                        <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}} pl={2}>
                                            {requirement.pdf !== null ? requirement.pdf : ""}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Box sx={{width: '80px'}}>
                                            <Tooltip title='Editar'>
                                                <IconButton onClick={() => handleOpenDialogRequirement(requirement)}>
                                                    <EditIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title='Anular'>
                                                <IconButton onClick={() => handleDeleteRequirement(requirement)}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                </>
                <Divider />
                <>
                <MKTypography pt={5} mb={1} pr={3} display='inline' sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                    <u>Diseño Curricular</u>
                </MKTypography>
                <Button 
                    variant='contained' 
                    style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                    onClick={() => handleOpenDialogPlan(null)}
                >
                    <AddIcon />
                </Button>
                <Table>
                    <TableBody>
                        {dataPlan.map((plan, index) => {
                            return(
                                <TableRow hover key={"key" + index}>
                                    <TableCell style={{width: '80%'}}>
                                        <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={2}>
                                            {plan.descripcion !== null ? plan.descripcion : ""}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell style={{width: '20%'}}>
                                        <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={2}>
                                            {plan.anio !== null ? plan.anio : ""}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell style={{width: '30%'}} align='right'>
                                        <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}}>
                                            {plan.pdf !== null ? plan.pdf : ""}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Box sx={{width: '80px'}}>
                                            <Tooltip title='Editar'>
                                                <IconButton onClick={() => handleOpenDialogPlan(plan)}>
                                                    <EditIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title='Anular'>
                                                <IconButton onClick={() => handleDeletePlan(plan)}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
                </>
                <Divider />
                <>
                <MKTypography pt={5} mb={1} pr={3} display='inline' sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
                    <u>Imagenes</u>
                </MKTypography>
                <Button 
                    variant='contained' 
                    // color='secondary' 
                    style={{background: '#FFF', color: '#000', marginRight: '5px', display: 'inline'}}
                    onClick={() => handleOpenDialogProgImagen(null, -1)}
                >
                    <AddIcon />
                </Button>
                <Table>
                    <TableBody>
                        {dataImagen.map((imagen, index) => {
                            return(
                                <TableRow hover key={"key" + index}>
                                    <TableCell style={{width: '70%'}}>
                                        <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={2}>
                                            {imagen.descripcion !== null ? imagen.descripcion : ""}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell style={{width: '30%'}} align='right'>
                                        <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}}>
                                            {imagen.nombreimagen}.{imagen.formato}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align='right'>
                                        <Box sx={{width: '80px'}}>
                                            <Tooltip title='Editar'>
                                                <IconButton onClick={() => handleOpenDialogProgImagen(imagen, index)}>
                                                    <EditIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title='Anular'>
                                                <IconButton onClick={() => handleDeleteImagen(index)}>
                                                    <DeleteIcon fontSize='small' />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                                
                            )
                        })}
                    </TableBody>
                </Table>
                </>
            </Container>
        </Card>


        <Dialog onClose={handleCloseDialog} open={openDialogImagen} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12}>
                        <div>
                            <input color="text" type="file" id="txtFile" onChange={onFileImagenChange} />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetImagen} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>

        <Dialog onClose={handleCloseDialog} open={openDialogModality} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            variant="standard"
                            label="Modalidad"
                            name="modalidad"
                            value={valuesModality.modalidad}
                            onChange={handleChangeModality}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                            variant="standard"
                            label="Descripcion"
                            name="descripcion"
                            value={valuesModality.descripcion}
                            onChange={handleChangeModality}
                            color='text'
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <input color="text" type="file" id="txtFile" onChange={onFileModalityChange} />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetModality} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>
        
        <Dialog onClose={handleCloseDialog} open={openDialogRequirement} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12}>
                        <TextField
                            variant="standard"
                            label="Descripcion"
                            name="descripcion"
                            value={valuesRequirement.descripcion}
                            onChange={handleChangeRequirement}
                            color='text'
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <input color="text" type="file" id="txtFile" onChange={onFileRequirementChange} />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetRequirement} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>
        
        
        
        <Dialog onClose={handleCloseDialog} open={openDialogPlan} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={10}>
                        <TextField
                            autoFocus
                            variant="standard"
                            label="Descripcion"
                            name="descripcion"
                            value={valuesPlan.descripcion}
                            onChange={handleChangePlan}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            type="number"
                            variant="standard"
                            label="Año"
                            name="anio"
                            value={valuesPlan.anio}
                            onChange={handleChangePlan}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <div>
                        <input color="text" type="file" id="txtFile" onChange={onFilePlanChange} />
                    </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetPlan} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>
        
        <Dialog onClose={handleCloseDialog} open={openDialogProgImagen} maxWidth="md" fullWidth>
            <DialogContent>
                <Grid container spacing={2} pt={5}>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            variant="standard"
                            label="Descripcion"
                            name="descripcion"
                            value={valuesImagen.descripcion}
                            onChange={handleChangeImagen}
                            color='text'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                    <div>
                        <input color="text" type="file" id="txtFile" onChange={onFileProgImagenChange} />
                    </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleSetProgImagen} style={{color: "#FFF"}}>Agregar</Button>
                <Button onClick={handleCloseDialog}>Cancelar</Button>
            </DialogActions>
        </Dialog>
        <ConfirmationDialog 
            open={stateConfirmDialog.open}
            title={stateConfirmDialog.title}
            content={stateConfirmDialog.content}
            onClose={handleCloseConfirmDialog}
        />
        </>
    );
}

export default RegisterProgram;