import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Divider from '@mui/material/Divider';
import ExpandIcon from '@mui/icons-material/CallMade';
import MinimizeIcon from '@mui/icons-material/CallReceived';
import CloseIcon from '@mui/icons-material/Close';
import styles from './panel-jss';

const FloatingPanel = (props) => {
    const { classes, openForm, closeForm, widthPanel, maxHeightPanel, title, children } = props;
    const [expanded, setExpanded] = useState(props.expanded);
    const [expandedBtn, setShowExpanded] = useState(props.expandedBtn);

    const toggleExpand = () => setExpanded(!expanded);

    return (
        <div>
            <div className={
                classNames(
                    classes.formOverlay,
                    openForm ? classes.showForm : classes.hideForm
                )
            }
            />
            <section className={
                classNames(
                    !openForm ? classes.hideForm : classes.showForm,
                    expanded ? classes.expanded : '',
                    classes.floatingForm,
                    classes.formTheme,
                    classes.large
                )}
                style={{ width: widthPanel, maxHeight: maxHeightPanel }}
            >
                <header>
                    {title}
                    <div className={classes.btnOpt}>
                        {expandedBtn && (
                            <Tooltip title=''>
                                <IconButton className={classes.expandButton} onClick={() => toggleExpand()} aria-label="Expand">
                                    {expanded ? <MinimizeIcon /> : <ExpandIcon /> }
                                </IconButton>
                            </Tooltip>
                        )}
                        <Tooltip title=''>
                            <IconButton className={classes.closeButton} onClick={() => closeForm()}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Divider sx={{ mb: 3 }} />
                </header>
                <div style={{ paddingLeft: "5px", paddingTop: "3px"}}>
                    {children}
                </div>
            </section>
        </div>
    )
}

export default withStyles(styles)(FloatingPanel)