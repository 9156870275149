import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import MKTypography from "components/MKTypography";
import { ROOT_SERVER } from "helpers/helperInit";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const imgLocate = ROOT_SERVER + '/static/images/icons/programs.svg';

const ProgramLinkMain = () => {
    const reducerProgram = "programReducer";
    const data_programs = useSelector(state => state[reducerProgram].data_programs);
    const [dataFirstProg, setDataFirstProg] = useState(null);

    const initData = () => {
        if(data_programs !== null) {
            setDataFirstProg(data_programs[0]);
        }
    }

    useEffect(() => {
        initData();
    })

    return (
        <>
        {(dataFirstProg && dataFirstProg !== null) && (
            <Box 
                style={{
                    background: '#FFF',
                    borderRadius: 0,
                    border: 'none'
                }}
            >
                <img src={imgLocate} width={50} style={{display: 'inline'}} />
                <MKTypography display='inline' pl={3} style={{color: '#000', fontSize: '24px', fontFamily: 'Arial', fontWeight: 600}}>
                    Nuestros Programas
                </MKTypography>
                <MKTypography pt={1} style={{fontSize: '20px', color: "#000"}}>
                    {dataFirstProg.nombreprograma !== null ?  dataFirstProg.nombreprograma + " ... " + dataFirstProg.descripcion.substring(0,120) : ""}...
                </MKTypography>
                <Box px={2} pt={2}>
                    <Button 
                        component={Link}
                        to={ROOT_SERVER + "/pages/programas"}
                        className="color-secondary"
                        sx={{fontSize: '16px'}}
                    >
                        MAS INFORMACIÓN <ArrowForwardIcon />
                    </Button>
                </Box>
            </Box>
        )}
        </>
    );
}

export default ProgramLinkMain;