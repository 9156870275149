import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Routes
import { ROOT_SERVER } from 'helpers/helperInit';
import { getAllPublications, getAllCategories } from "redux/actions/publicationAction";
import RegisterGeneralData from "./sections/RegisterGeneralData";
import MKTypography from "components/MKTypography";
import Divider from "@mui/material/Divider";
import { getGeneralData } from "redux/actions/generalDataAction";

function GeneralData() {
    const dispatch = useDispatch();
    let hasConn = sessionStorage.getItem('hasConn');

    const [load, setLoad] = useState(false);

    useEffect(async () => {
        if(hasConn === null) {
            window.location = ROOT_SERVER + "/home";
        }
        await dispatch(getGeneralData());
        setLoad(true);
        // await dispatch(getAllPublications());
        // await dispatch(getAllCategories());
    }, [load]);
    
    return (
        <>
            <MKTypography className='color-primary' fontWeight='bold'>
                DATOS GENERALES
            </MKTypography>
            <Divider />
            {!!load && (
                <RegisterGeneralData />
            )}
        </>
  );
}

export default GeneralData;
