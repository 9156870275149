import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import MKTypography from "components/MKTypography";
import { useDispatch, useSelector } from "react-redux";
import ServiceInformation from "./ServiceInformation";
import { set_one_service_action } from "redux/actions/publicationAction";
import AbautLinkMain from "pages/LandingPages/AboutUs/sections/AbautLinkMain";
import ProgramLinkMain from "pages/LandingPages/Programs/sections/ProgramLinkMain";

const ContentService = () => {
    const dispatch = useDispatch();
    const reducerService = "serviceReducer";
    const one_service_type = useSelector(state => state[reducerService].one_service_type);
    const data_services = useSelector(state => state[reducerService].data_services);
    const one_service = useSelector(state => state[reducerService].one_service);
    const [thisServices, setThisServices] = useState(null);

    const handleSelectService = (service) => {
        dispatch(set_one_service_action(service));
    }

    useEffect(() => {
        if(data_services.length > 0) {
            let newList = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(one_service_type.key_service));
            if(newList.length > 0) {
                // if(one_service !== null) {
                    // newList = newList.filter(item => parseInt(item.id_servicio) !== parseInt(one_service.id_servicio));
                // }
                setThisServices(newList);
                dispatch(set_one_service_action(newList[0]));
            }
        }
    }, [data_services, one_service_type]);

    return(
        <>
        {/* <Box px={6} sx={{background: '#FFF'}}>
            <Grid container pt={3}>
                <Grid item xs={12} lg={6} px={3}>
                    <AbautLinkMain />
                </Grid>
                <Grid item xs={12} lg={6} px={3}>
                    <ProgramLinkMain />
                </Grid>
            </Grid>
        </Box> */}
        <Box
            heigth="100%"
            minHeight={"1190px"}
            width="100%"
            px={6}
            py={5}
            sx={{
                background: "#FFF",
                backgroundPosition: "left",
                placeItems: "left",
                align: 'left',
            }}
        >
            {thisServices === null ? (
                <MKTypography 
                    variant="h2" 
                    mb={1} 
                    className='color-four'
                >
                    SIN CONTENIDO
                </MKTypography>
            ) : (
                <Box>
                    <ServiceInformation />
                    <Divider />
                    {thisServices.length > 1 && (
                        <>
                        <MKTypography 
                            mb={1} 
                            sx={{color: "#000", fontSize: '20px', fontWeight: 'bold'}}
                        >
                            {one_service_type.name === "Admisión" ? "Otros procesos de admisión" : one_service_type.name}
                        </MKTypography>
                        <Table>
                            <TableBody>
                                {thisServices.map(service => {
                                    if(one_service !== null) {
                                        if(parseInt(service.id_servicio) !== parseInt(one_service.id_servicio)) {
                                            return(
                                                <TableRow hover key={"serv" + service.id_servicio} onClick={() => handleSelectService(service)} style={{cursor: 'pointer'}}>
                                                    <TableCell style={{width: '40%', fontStyle: 'italic'}}>
                                                        {service.titulo}
                                                    </TableCell>
                                                    <TableCell style={{width: '60%'}}>
                                                        {service.descripcion.substring(0,250)}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    }
                                })}
                            </TableBody>
                        </Table>
                        </>
                    )}
                    <Box px={6} pt={5} sx={{background: '#FFF'}}>
                        <Grid container pt={3}>
                            <Grid item xs={12} lg={6} px={3}>
                                <AbautLinkMain />
                            </Grid>
                            <Grid item xs={12} lg={6} px={3}>
                                <ProgramLinkMain />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )}
        </Box>
        </>
    );
}

export default ContentService;