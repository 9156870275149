import { useEffect } from "react";
import { useDispatch } from "react-redux";

// Reducer
import { getGeneralData } from "redux/actions/generalDataAction";

// Material Kit 2 React pages
import Home from "pages/Home";
import { getAllServices } from "redux/actions/publicationAction";
// import Home from "pages/LandingPages/Publications";

export default function HomePage() {
  sessionStorage.removeItem('hasConn');
  const dispatch = useDispatch();
  useEffect(async () => {
    await dispatch(getGeneralData());
    await dispatch(getAllServices());
  });

  return <Home />;
}
