import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

// Images
import SimpleHeader from "examples/Headers/SimpleHeader";

// Reducer
import { getAllPublications, getAllCategories } from "redux/actions/publicationAction";

// Custom Pages and Components
// import PublicationList from "./sections/PublicationsList";

// Helperinit
import { ROOT_SERVER } from "helpers/helperInit";
import Header from "./sections/Header";
import ContentPage from "./sections/ContentPage";
import HomeLinks from "pages/Home/sections/HomeLinks";
import { getAllPrograms } from "redux/actions/publicationAction";
import { getGeneralData } from "redux/actions/generalDataAction";
// import ListMain from "./sections/ListMain";

function Programs() {
    const dispatch = useDispatch();
    const reducerPublication = "publicationReducer";
    const one_publication = useSelector(state => state[reducerPublication].one_publication);
    const [firstLoad, setFirstLoad] = useState(true);

    const handleSetFirstLoad = (valor) => {
        setFirstLoad(valor);
    }

    useEffect(async () => {
        dispatch(getGeneralData());
        await dispatch(getAllPublications());
    });

    return (
        <>
            <SimpleHeader />
            <Box pl={4} style={{background: "#FFF"}}>
                {/* <Header /> */}
                <ContentPage />
                
                <Box style={{background: '#FFF'}}>
                    <Divider />
                    <HomeLinks />
                </Box>
            </Box>
            <MKBox mt={4}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default Programs;