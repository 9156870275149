import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import { set_one_program_action } from 'redux/actions/publicationAction';
import { ROOT_GALERIA } from 'helpers/helperInit';
import Container from '@mui/material/Container';
import LinkFirstTypeExteOne from 'pages/LandingPages/Publications/sections/LinkFirstTypeExteOne';
import ServiceLinkMain from 'pages/LandingPages/Services/sections/ServiceLinkMain';

const CardProgram = ({data, handleLoadDetail, onSelect}) => {
    const reducerGeneralData = "generalDataReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);

    let firstImage = ROOT_GALERIA + "empty.jpg";
    const handleLoadData = () => {
        handleLoadDetail(data);
        onSelect();
    }

    let pathImg = "";
    
    if(data.imagen.length > 0) {
        if(data_general_data.length > 0) {
            pathImg = data_general_data[0].url_img_publicaciones;
        }
        const listImage = data.imagen.filter(item => item.formato === 'jpg' || item.formato === 'jpeg' || item.formato === 'png')
        if(listImage.length > 0) {
            firstImage = pathImg + listImage[0].nombreimagen + "." + listImage[0].formato;
        }
    }

    return(
        <Card 
            onClick={handleLoadData}
            style={{
                minHeight: '400px',
                marginTop: '20px',
                background: 'transparent',
                borderRadius: '0',
                cursor: 'pointer',
                borderRadius: '10px',
                padding: '10px'
            }}
            className='bg-primary-opacity'
        >
            <Grid container>
                <Grid item xs={12}>
                    <MKBox textAlign="center" pt={1} pb={1}>
                        <MKTypography fontWeight="bold" sx={{ fontSize: '25px', color: "#000", lineHeight: 1}}>
                            {data.nombreprograma.toUpperCase()}
                        </MKTypography>
                    </MKBox>
                </Grid>
                <Grid item xs={12} pb={1}>
                    <hr className='border-primary-1' />
                </Grid>
                <Grid item xs={12}>
                    <MKBox
                        component="img"
                        src={firstImage}
                        width="100%"
                        zIndex={1}
                        style={{opacity: 0.8}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MKTypography className='color-primary' style={{fontSize: '12px', lineHeight: 1.5, padding: '2px 15px'}}>
                        {data.descripcion.substring(0,250)}...
                    </MKTypography>
                </Grid>
            </Grid>
        </Card>
    )
}

const ListMain = ({onSelect}) => {
    const dispatch = useDispatch();
    const reducerProgram = "programReducer";
    const data_programs = useSelector(state => state[reducerProgram].data_programs);

    const handleLoadDetail = (program) => {
        dispatch(set_one_program_action(program));
    }

    const handleSetFirstLoad = () => {
        onSelect(false);
    }

    useEffect(() => {
        if(data_programs.length > 0) {
            dispatch(set_one_program_action(data_programs[0]));
        }
    }, [data_programs]);

    return(
        <>
        <Box
            heigth="100%"
            // minHeight={"1190px"}
            width="100%"
            sx={{
                background: '#FFF',
                backgroundPosition: "left",
                placeItems: "left",
                align: 'left',
                // paddingTop: ''
            }}
            pt={5}
        >
            <Box px={4}>
                <Grid container>
                    <Grid item xs={12} lg={12} pr={3}>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={12}>
                                <ServiceLinkMain /> 
                            </Grid> */}
                            <Grid item xs={12}>
                                <Box textAlign={'left'} pt={5}>
                                    <MKTypography fontWeight='bold'style={{color: "#000"}} >
                                        NUESTRAS ESCUELAS
                                    </MKTypography>
                                    <hr className='border-primary-1' style={{display: 'inline-block', width: '50%'}} />
                                </Box>
                            </Grid>
                            {data_programs !== null && data_programs.map(program => {
                                return(
                                    <Grid item key={program.id_programa} xs={12} lg={4}>
                                        {/* <Box key={program.id_programa} style={{padding: '5px'}}> */}
                                            <CardProgram data={program} handleLoadDetail={handleLoadDetail} onSelect={handleSetFirstLoad} />
                                        {/* </Box> */}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} lg={2}>
                        <LinkFirstTypeExteOne />
                    </Grid> */}
                </Grid>
            </Box>
        </Box>
        <Box px={12} pt={8} sx={{background: "#FFF"}}>
            <ServiceLinkMain />
        </Box>
        </>
    )
}
export default ListMain;