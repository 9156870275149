// @mui material components
import Card from "@mui/material/Card";

import { ROOT_SERVER, INSTITUTION_SUBTITLE } from "helpers/helperInit";
import { useSelector } from "react-redux";

const bgImage = ROOT_SERVER + '/static/images/academyc/image2.jpg';

const Header = () => {
    const reducerGeneralData = "generalDataReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);

    return(
        <Card style={{zIndex: -2}}>
            <div>
                <div className="carousel-item" style={{ height: '500px', width: '500px', justifyContent: 'center' }}>
                    <img src={bgImage} className='img-carrusel' />
                    <p 
                        className={"titulo-cauroles"}
                        style={{
                            fontWeight: 700,
                            color: '#FFF',
                            fontSize: '40px',
                            fontFamily: 'Georgia'
                        }}
                    >
                        {data_general_data.length > 0 && data_general_data[0].lema !== null ? data_general_data[0].lema.substring(0,150) : ""}...</p>
                    <p
                        className={"description-carousel color-three"}
                        style={{
                            fontSize: '12px',
                            fontFamily: 'Arial',
                        }}
                    >
                        {INSTITUTION_SUBTITLE}
                    </p>
                </div>
            </div>
        </Card>
    );
}

export default Header;