import React from 'react'

import AboutUs from "layouts/public/landing-pages/about-us";
import Publications from "layouts/public/landing-pages/publicaciones";
import Programs from "layouts/public/landing-pages/programs";
import Home from "layouts/public/home";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import routes from "routes";

import { ROOT_SERVER } from 'helpers/helperInit';

const LoginRouter = () => {
    const getRoutes = (allRoutes) => 
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

    return (
        <Routes>
          {getRoutes(routes)}
          <Route path={ROOT_SERVER + "/home"} element={<Home />} />
          <Route path={ROOT_SERVER + "/pages/about-us"} element={<AboutUs />} />
          <Route path={ROOT_SERVER + "/pages/publicaciones"} element={<Publications />} />
          <Route path={ROOT_SERVER + "/pages/programas"} element={<Programs />} />
          <Route path="*" element={<Navigate to={ROOT_SERVER + "/home"} />} />
        </Routes>
    )
}

export default LoginRouter
