import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// @mui material components
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import footerRoutes from "footer.routes";

// Images
import SimpleHeader from "examples/Headers/SimpleHeader";

// Reducer
import { getGeneralData } from "redux/actions/generalDataAction";

// Custom Pages and Components
// import PublicationList from "./sections/PublicationsList";

// Helperinit
import { ROOT_SERVER } from "helpers/helperInit";
import Header from "./sections/Header";
import Information from "./sections/Information";

function AboutUs() {
    const dispatch = useDispatch();
    useEffect(async () => {
        await dispatch(getGeneralData());
    });

    return (
        <>
            <SimpleHeader />
            <Box>
              <Card style={{zIndex: -3}}>
                <Header />
              </Card>
            </Box>
            <Box sx={{background: '#FFF'}}>
                {/* <Divider /> */}
                {/* <Container> */}
                {/* </Container> */}
                  <Information />
            </Box>
            <MKBox mt={4}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default AboutUs;
