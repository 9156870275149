import axios from "axios";
import { URL_SERVICE, MESSAGE_NOT_CONNECT_SERVER, getUserAuth } from '../../helpers/helperInit';
import { typesNotif, typesSystemParamts, typesSeveral } from '../constants/type';

const validatorResponseService = (dispatch, requireAuth, data, entity, action) => {
    if(data.success !== undefined) {
        if(requireAuth) {
            if (!data.isAuthenticated) {
                window.location = "/";
            }    
        }
        if (data.success) {
            if(entity === "param_system") {
                switch (action) {
                    case "getAll":
                        dispatch(get_all_system_paramts_action(data.data));
                        return;
                    default:
                        return
                }
            }
        } else {
            dispatch(openNotifAction([data.msg_rpta, "error", 'filled']));
        }
    } else {
        dispatch(openNotifAction([MESSAGE_NOT_CONNECT_SERVER, "error", 'filled']));
    }
}

// Notify
export const openNotifAction = (msg) => {
    return {
        type: typesNotif.OPEN_NOTIF,
        payload: msg,
    }
};

export const closeNotifAction = () => {
    return {
        type: typesNotif.CLOSE_NOTIF,
    }
};
// Fin Notify

// acttions Param System
export const getAllSystemParamts = () => {
    // const dataUserAuth = getUserAuth();
    // const strToken = dataUserAuth[1];
    const requireAuth = false;
    const entity = "param_system";
    const action = "getAll";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "Varios/GetAllParamSystem/", {
            headers: {
                'Content-Type': 'Application/Json',
                // 'Authorization': 'Bearer ' + strToken,
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const get_all_system_paramts_action = (data) => {
    return {
        type: typesSystemParamts.read,
        payload: data,
    }
}

export const UploadFile = (formData) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "several";
    const action = "upload_file";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "Publicacion/UploadFile/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "POST",
            data: formData
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

// Several actions
export const open_frm_galery_action = (data) => {
    return {
        type: typesSeveral.openFrmGalery,
        payload: data,
    }
}

export const open_loader_action = (data) => {
    return {
        type: typesSeveral.openLoader,
        payload: data,
    }
}
// End several actions

// Load file
export const saveLoadFileImage = async (dataFileImg) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "publicacion/subirImagen/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataFileImg
    });
    const data = (await res).data;
    return data;
}

export const saveLoadFilePDF = async (dataFilePDF) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "programa/subirPdf/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataFilePDF
    });
    const data = (await res).data;
    return data;
}

export const saveLoadFileImportEgresados = async (dataFilePDF) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "alumno/importarEgresados/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataFilePDF
    });
    const data = (await res).data;
    return data;
}
// Fin load file