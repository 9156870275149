import Services from "pages/LandingPages/Services";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { set_service_type_action } from "redux/actions/publicationAction";
import { getAllServices } from "redux/actions/publicationAction";

export default function ServicesPage() {
    const dispatch = useDispatch();
    const key_service = sessionStorage.getItem("key_service");;

    useEffect(() => {
        dispatch(getAllServices());
        dispatch(set_service_type_action(JSON.parse(key_service)));
    });

    return <Services />;
}
