import axios from "axios";
import { MESSAGE_NOT_CONNECT_SERVER } from "helpers/helperInit";
import { URL_SERVICE, getUserAuth } from "helpers/helperInit";
import { typesStudents } from "redux/constants/type";
import { openNotifAction } from "./severalAction";

const validatorResponseService = (dispatch, requireAuth, data, entity, action) => {
    if(data.success !== undefined) {
        if(requireAuth) {
            if (!data.isAuthenticated) {
                window.location = "/";
            }    
        }
        if (data.success) {
            if(entity === "student") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_students_action(data.data));
                        return;
                    case "insert":
                        dispatch(openNotifAction(["Estudiante insertado con exito", "success", 'filled']));
                        return;
                    case "update":
                        dispatch(openNotifAction(["Publicación actualizada con exito", "success", 'filled']));
                        return;
                    case "delete":
                        dispatch(openNotifAction(["Publicación eliminada con exito", "success", 'filled']));
                        return;
                    default:
                        return
                }
            }
            if(entity === "school") {
                switch (action) {
                    case "get-all":
                        dispatch(get_all_schools_action(data.data));
                        return;
                    case "insert":
                        dispatch(openNotifAction(["Publicación insertada con exito", "success", 'filled']));
                        return;
                    case "update":
                        dispatch(openNotifAction(["Publicación actualizada con exito", "success", 'filled']));
                        return;
                    case "delete":
                        dispatch(openNotifAction(["Publicación eliminada con exito", "success", 'filled']));
                        return;
                    default:
                        return
                }
            }
        } else {
            dispatch(openNotifAction([data.msg_rpta, "error", 'filled']));
        }
    } else {
        dispatch(openNotifAction([MESSAGE_NOT_CONNECT_SERVER, "error", 'filled']));
    }
}

// Students actions
export const getAllStudents = () => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = false;
    const entity = "student";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "alumno/getAlumnos/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "GET"
        });
        const data = (await res).data;
        console.log(data)
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}

export const saveNewStudent = async (dataStudent) => {
    let rptaReturn = [];
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const requireAuth = true;
    const entity = "student";
    const action = "insert";
    const res = await axios(URL_SERVICE + "alumno/insertUpdateAlumno/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataStudent
    });
    const data = (await res).data;
    return data.data[0];
}

export const saveDeleteStudent = async (dataStudent) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const res = await axios(URL_SERVICE + "alumno/deleteAlumno/", {
        headers: {
            'Content-Type': 'Application/Json',
            'Authorization': 'Bearer ' + strToken,
        },
        method: "POST",
        data: dataStudent
    });
    const data = (await res).data;
    return data;
}

export const get_all_students_action = (data) => {
    return {
        type: typesStudents.read,
        payload: data
    }
}

export const set_one_student_action = (data) => {
    return {
        type: typesStudents.setOneStudent,
        payload: data
    }
}
// End Students actions

// Schoold actions
export const getAllScholls = () => {
    const requireAuth = false;
    const entity = "school";
    const action = "get-all";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "escuela/getEscuelas/", {
            headers: {
                'Content-Type': 'Application/Json',
            },
            method: "GET"
        });
        const data = (await res).data;
        validatorResponseService(dispatch, requireAuth, data, entity, action);
    }
}
// End Schoold actions