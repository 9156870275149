export const typesNotif = {
    OPEN_NOTIF: "[Notif] open_notyf",
    CLOSE_NOTIF: "[Notif] close_notif",
};

export const typesSeveral = {
    openFrmGalery: "[Several] openFrmGalery",
    openLoader: "[Several] openLoader",
};

export const typesSystemParamts = {
    read: "[SystemParamts] read",
};

export const typesMenu = {
    readKeysPublic: "[Menu] readKeysPublic",
    readUserPermits: "[Menu] readUserPermits",
}

export const typesUser = {
    readUsers: "[User] readUser",
    openFrm: "[User] openFrm",
    openChangePass: "[User] openChangePass",
    search: "[User] search",
    openFrmPermisions: "[User] openFrmPermissions",
};

export const typesPublication = {
    read: "[Publication] read",
    setOnePublication: "[Publication] setOnePublication",
    setTipoPubl: "[Publication] setTipoPubl",
    readCateg: "[Publication] readCateg",
}

export const typesServices = {
    read: "[Services] read",
    readTipoServ: "[Services] readTipoServ",
    setTipoServ: "[Service] setTipoServ",
    setOneService: "[Services] setOneService",
}

export const typesPrograms = {
    read: "[Programs] read",
    setOneProgram: "[Programs] setOneProgram",
    firstLoad: "[Courses] firstLoad",
    // readTipoServ: "[Programs] readTipoServ",
    // setTipoServ: "[Programs] setTipoServ",
}

export const typesGeneralData = {
    read: "[GeneralData] read",
}

export const typesStudents = {
    read: "[Students] read",
    setOneStudent: "[Student] setOneStudent"
}

export const typesSchools = {
    read: "[Schools] read",
    setOneSchool: "[Courses] setOneSchool",
}