// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Gestor de contenido",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/profile.php?id=100018539530949",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com",
    },
    {
      icon: <LinkedInIcon />,
      link: "https://www.linkedin.com/",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCpnktAvO8fDJPwqmToxpIfQ",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "https://computerhoy.com/" },
        { name: "freebies", href: "https://computerhoy.com/" },
        { name: "premium tools", href: "https://computerhoy.com/" },
        { name: "blog", href: "https://computerhoy.com/" },
      ],
    },
    {
      name: "resources",
      items: [
        { name: "illustrations", href: "https://computerhoy.com/" },
        { name: "bits & snippets", href: "https://computerhoy.com/" },
        { name: "affiliate program", href: "https://computerhoy.com/" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "https://computerhoy.com/" },
        { name: "knowledge center", href: "https://computerhoy.com/" },
        { name: "custom development", href: "https://computerhoy.com/" },
        { name: "sponsorships", href: "https://computerhoy.com/" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "https://computerhoy.com/" },
        { name: "privacy policy", href: "https://computerhoy.com/" },
        { name: "licenses (EULA)", href: "https://computerhoy.com/" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular" color="light">
      All rights reserved. Copyright &copy; {date}
    </MKTypography>
  ),
};
