import { typesNotif, typesSystemParamts, typesSeveral } from '../constants/type';

const initialStateNotify = {
    notif_msg: "",
    notif_type: "success",
    notif_variant: "",
}

const initialStateSystemParamts = {
    data_system_params: [],
};

const initialStateSeveral = {
    open_frm_galery: false,
    open_loader: false,
}

export const notifReducer = (state = initialStateNotify, action) => {
    switch (action.type) {
        case typesNotif.OPEN_NOTIF:
            return {
                ...state,
                notif_msg: action.payload[0],
                notif_type: action.payload[1],
                notif_variant: action.payload[2],
            }
        case typesNotif.CLOSE_NOTIF:
            return {
                ...state,
                notif_msg: ''
            };
        default:
            return state;
    }
}

export const systemParamtsReducer = (state = initialStateSystemParamts, action) => {
    switch (action.type) {
        case typesSystemParamts.read:
            return { ...state, data_system_params: action.payload };
        default:
            return state;
    }
};

export const severalReducer = (state = initialStateSeveral, action) => {
    switch (action.type) {
        case typesSeveral.openFrmGalery: 
            return {
                ...state,
                open_frm_galery: action.payload,
            }
        case typesSeveral.openLoader:
            return {
                ...state,
                open_loader: action.payload,
            }
        default:
            return state
    }
}