import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import MKTypography from "components/MKTypography";
import { SERVICE_SHOW_DETAILS } from "helpers/helperInit";
import { useSelector } from "react-redux";
import ServAttached from "./ServAttached";
import ServBlog from "./ServBlog";
import ServConvalidation from "./ServConvalidation";
import ServCosts from "./ServCosts";
import ServCronograma from "./ServCronograma";
import ServModality from "./ServModality";
import ServOrientation from "./ServOrientation";
import ServRequirements from "./ServRequirements";
import ServResults from "./ServResults";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { AppBar } from "@mui/material";
// import { TabPanel } from "@mui/lab";

function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const ServiceInformation = () => {
    const reducerService = "serviceReducer";
    const reducerGeneralData = "generalDataReducer";
    const one_service = useSelector(state => state[reducerService].one_service);
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);
    const [valueTabResource, setValueTabResource] = useState(0);
    
    let data_show = [];
    if(one_service !== null) {
        data_show = SERVICE_SHOW_DETAILS.filter(item => parseInt(item.id_type_service) === one_service.id_tiposervicio);
    }

    let pathPdf = "";
    if(data_general_data.length > 0) {
        pathPdf = data_general_data[0].url_img_publicaciones;
    }

    const handleChangeTabResource = (event, newValue) => {
        setValueTabResource(newValue);
    };

    return(
        <Box
            heigth="100%"
            width="100%"
            px={6}
            py={5}
            sx={{
                background: "#FFF",
                backgroundPosition: "left",
                placeItems: "left",
                align: 'left',
            }}
        >
            {one_service === null ? (
                <MKTypography 
                    variant="h2" 
                    mb={1} 
                    className='color-four'
                >
                    SIN CONTENIDO
                </MKTypography>
            ) : (
                <Box>
                    <Grid container>
                        <Grid item xs={12}>
                            <MKTypography 
                                variant="h2" 
                                mb={1} 
                                className='color-primary'
                            >
                                {one_service.titulo}
                            </MKTypography>
                        </Grid>
                        <Grid item xs={12}>
                            <MKTypography 
                                mb={1} 
                                style={{color: '#000'}}
                                pl={5}
                            >
                                {one_service.descripcion}
                            </MKTypography>
                        </Grid>
                        <Box pt={5} sx={{ width: '100%' }}>
                            <AppBar position="static">
                                <Tabs 
                                    value={valueTabResource} 
                                    onChange={handleChangeTabResource} 
                                    aria-label="basic tabs example"
                                    variant="fullWidth"
                                    className="tabs"
                                >
                                    {(data_show.length > 0 && !!data_show[0].show.show_modalidad && one_service.modalidad.length > 0) && (
                                        <Tab label="Modalidad" sx={{ padding: '10px', marginLeft: '10px' }} {...a11yProps(0)} />
                                    )}
                                    {(data_show.length > 0 && !!data_show[0].show.show_requisitos && one_service.requisito.length > 0) && (
                                        <Tab label="Requisitos" sx={{ padding: '10px', marginLeft: '10px' }} {...a11yProps(1)} />
                                    )}
                                    {(data_show.length > 0 && !!data_show[0].show.show_costos && one_service.costo.length > 0) && (
                                        <Tab label="Costos" sx={{ padding: '10px', marginLeft: '10px' }} {...a11yProps(2)} />
                                    )}
                                    {(data_show.length > 0 && !!data_show[0].show.show_cronograma && one_service.cronograma.length > 0) && (
                                        <Tab label="Cronograma" sx={{ padding: '10px', marginLeft: '10px' }} {...a11yProps(3)} />
                                    )}
                                    {(data_show.length > 0 && !!data_show[0].show.show_resultados && one_service.resultado.length > 0) && (
                                        <Tab label="Resultados" sx={{ padding: '10px', marginLeft: '10px' }} {...a11yProps(4)} />
                                    )}
                                    {one_service.archivo.length > 0 && (
                                        <Tab label="Adjuntos" sx={{ padding: '10px', marginLeft: '10px' }} {...a11yProps(5)} />
                                    )}
                                </Tabs>
                            </AppBar>
                        </Box>
                        <Grid item xs={12} mb={5}>
                            <TabPanel value={valueTabResource} index={0}>
                                <Grid item xs={12}>
                                    <ServModality />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={valueTabResource} index={1}>
                                <Grid item xs={12}>
                                    <ServRequirements />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={valueTabResource} index={2}>
                                <Grid item xs={12}>
                                    <ServCosts />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={valueTabResource} index={3}>
                                <Grid item xs={12}>
                                    <ServCronograma />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={valueTabResource} index={4}>
                                <Grid item xs={12}>
                                    <ServResults path={pathPdf} />
                                </Grid>
                            </TabPanel>
                            <TabPanel value={valueTabResource} index={5}>
                                <Grid item xs={12}>
                                    <ServAttached path={pathPdf} />
                                </Grid>
                            </TabPanel>
                        </Grid>
                        {/* <Button>Modalidad</Button>
                        <Button>Requisitos</Button>
                        <Button>Costos</Button>
                        <Button>Cronograma</Button>
                        <Button>Resultados</Button> */}
                        {/* {(data_show.length > 0 && !!data_show[0].show.show_modalidad && one_service.modalidad.length > 0) && (
                            <Grid item xs={12}>
                                <ServModality />
                            </Grid>
                        )}
                        {(data_show.length > 0 && !!data_show[0].show.show_requisitos && one_service.requisito.length > 0) && (
                            <Grid item xs={12}>
                                <ServRequirements />
                            </Grid>
                        )}
                        {(data_show.length > 0 && !!data_show[0].show.show_costos && one_service.costo.length > 0) && (
                            <Grid item xs={12}>
                                <ServCosts />
                            </Grid>
                        )} */}
                        {/* <Grid item xs={12}>
                            <ServConvalidation />
                        </Grid> */}
                        {/* {(data_show.length > 0 && !!data_show[0].show.show_cronograma && one_service.cronograma.length > 0) && (
                            <Grid item xs={12}>
                                <ServCronograma />
                            </Grid>
                        )} */}
                        {/* <Grid item xs={12}>
                            <ServOrientation />
                        </Grid>
                        <Grid item xs={12}>
                            <ServBlog />
                        </Grid> */}
                        {/* {(data_show.length > 0 && !!data_show[0].show.show_resultados && one_service.resultado.length > 0) && (
                            <Grid item xs={12}>
                                <ServResults path={pathPdf} />
                            </Grid>
                        )}
                        {one_service.archivo.length > 0 && (
                        <Grid item xs={12}>
                            <ServAttached path={pathPdf} />
                        </Grid>
                        )} */}
                    </Grid>
                </Box>
            )}
        </Box>
    );
}

export default ServiceInformation;