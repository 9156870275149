import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";
import { useSelector } from "react-redux";

const ServCronograma = () => {
    const reducerService = "serviceReducer";
    const one_service = useSelector(state => state[reducerService].one_service);

    return(
        <>
        {/* <MKTypography pt={5} mb={1} sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
            <u>Cronograma</u>
        </MKTypography> */}
        {one_service.cronograma.map(cronograma => {
            return(
                <Grid container key={cronograma.id_servicio_cronograma} style={{borderBottom: '1px solid #CCC'}} >
                    <Grid item xs={8}>
                        <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={5}>
                            {cronograma.descripcion}
                        </MKTypography>
                    </Grid>
                    <Grid item xs={2}>
                        <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}}>
                            {cronograma.fecha_inicio.substring(0,10)}
                        </MKTypography>
                    </Grid>
                    <Grid item xs={2}>
                        <MKTypography sx={{fontSize: '15px', color: '#000', fontStyle: 'italic'}}>
                            {cronograma.fecha_fin.substring(0,10)}
                        </MKTypography>
                    </Grid>
                </Grid>
            );
        })}
        </>
    );
}

export default ServCronograma;