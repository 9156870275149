import axios from 'axios';
import { typesMenu, typesUser } from '../constants/type';
import { URL_SERVICE, getUserAuth } from 'helpers/helperInit';
import { openNotifAction } from 'redux/actions/severalAction';

const validatorResponseService = (dispatch, data, entity, action) => {
    if(data.success !== undefined) {
        if (!data.isAuthenticated) {
            window.location = "/";
        } else if (data.success) {
            if(entity === "user") {
                switch (action) {
                    case "getAll":
                        // dispatch(get_all_empaque_action(data.data));
                        return;
                    case "insert":
                    case "update":
                    case "change_state":
                        dispatch(open_frm_user_action([false, false]));
                        dispatch(openNotifAction([data.msg_rpta, "success", 'filled'])); //outlined - filled
                        return;
                    default:
                        return
                }
            }
        } else {
            dispatch(openNotifAction([data.msg_rpta, "error", 'filled']));
        }
    } else {
        (openNotifAction([MESSAGE_NOT_CONNECT_SERVER, "error", 'filled']));
    }
}

export const saveNewUser = (dataNewUser) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    // newDataEmpaque.fk_usuario_crud = idUser;
    const entity = "user";
    const action = "insert";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "Usuario/Insert/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "POST",
            data: dataNewUser
        });
        const data = (await res).data;
        validatorResponseService(dispatch, data, entity, action);
    }
}

export const saveEditUser = (dataUser) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const entity = "user";
    const action = "update";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "Usuario/Update/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "POST",
            data: dataUser,
        });
        const data = (await res).data;
        validatorResponseService(dispatch, data, entity, action);
    }
}

export const saveChangeStateUser = (dataUser) => {
    const dataUserAuth = getUserAuth();
    const strToken = dataUserAuth[1];
    const entity = "user";
    const action = "change_state";
    return async (dispatch) => {
        const res = await axios(URL_SERVICE + "Usuario/ChangeState/", {
            headers: {
                'Content-Type': 'Application/Json',
                'Authorization': 'Bearer ' + strToken,
            },
            method: "POST",
            data: dataUser
        });
        const data = (await res).data;
        validatorResponseService(dispatch, data, entity, action);
    }
}

export const get_all_users_action = (data) => {
    return {
        type: typesUser.readUsers,
        payload: data,
    }
}

export const open_frm_user_action = (data) => {
    return {
        type: typesUser.openFrm,
        payload: data,
    }
}

export const open_frm_permissions_action = (data) => {
    return {
        type: typesUser.openFrmPermisions,
        payload: data,
    }
}

export const get_one_user_action = (data) => {
    return {
        type: typesUser.search,
        payload: data,
    }
}

export const open_change_pass_action = (data) => {
    return {
        type: typesUser.openChangePass,
        payload: data,
    };
}

// Menu actions
export const get_all_keys_public_action = (data) => {
    return {
        type: typesMenu.readKeysPublic,
        payload: data,
    }
}

export const get_all_user_permits_action = (data) => {
    return {
        type: typesMenu.readUserPermits,
        payload: data,
    }
}
// end Menu Actions