import { ROOT_SERVER } from './helpers/helperInit'

// Pages
import AboutUs from "layouts/public/landing-pages/about-us";
import Services from "layouts/public/landing-pages/services";
import Egresados from "layouts/public/landing-pages/egresados";
import DashboardAdmin from "layouts/public/dashboard-adm";

import PublicationAdmin from "layouts/administrator/publications";
import GeneralDataAdmin from "layouts/administrator/general-data";

import SignIn from "layouts/public/authentication";
const routes = [
  {
    name: "pages",
    collapse: [
      {
        name: "landing pages",
        collapse: [
          {
            name: "about us",
            route: ROOT_SERVER + "/pages/about-us",
            component: <AboutUs />,
          },
          {
            name: "informate",
            route: ROOT_SERVER + "/pages/informate",
            component: <Services />,
          },
          {
            name: "egresados",
            route: ROOT_SERVER + "/pages/egresados",
            component: <Egresados />,
          },
          {
            name: "Administrator",
            route: ROOT_SERVER + "/pages/dashboard/administrator",
            component: <DashboardAdmin />,
          },
        ],
      },
      {
        name: "administrator",
        collapse: [
          {
            name: "publications",
            route: ROOT_SERVER + "/pages/administrator/publication",
            component: <PublicationAdmin />,
          },
          {
            name: "generalData",
            route: ROOT_SERVER + "/pages/administrator/nosotros",
            component: <GeneralDataAdmin />,
          },
        ],
      },
      {
        name: "account",
        collapse: [
          {
            name: "sign in",
            route: ROOT_SERVER + "/pages/authentication/sign-in",
            component: <SignIn />,
          },
        ],
      },
    ],
  },
];

export default routes;
