import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Routes
import { ROOT_SERVER } from 'helpers/helperInit';
import ProgramsList from "./sections/ProgramsList";
import RegisterProgram from "./sections/RegisterProgram";
import MKTypography from "components/MKTypography";
import { getAllPrograms } from "redux/actions/publicationAction";

function Programs() {
    const dispatch = useDispatch();
    const [actionService, setActionService] = useState("list");

    let hasConn = sessionStorage.getItem('hasConn');

    const handleChangeAction = (action) => {
        setActionService(action);
    }

    useEffect(async () => {
        if(hasConn === null) {
            window.location = ROOT_SERVER + "/home";
        }
        await dispatch(getAllPrograms());
    }, [actionService]);
    
    return (
        <>
            <MKTypography className='color-primary' fontWeight='bold'>
                GESTION DE ESCUELAS
            </MKTypography>
            {actionService === "list" && (<ProgramsList onChangeAction={handleChangeAction} />)}
            {actionService === "register" && (<RegisterProgram onChangeAction={handleChangeAction} />)}
        </>
  );
}

export default Programs;
