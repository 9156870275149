import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import NewspaperIcon from '@mui/icons-material/Newspaper';
import AddCardIcon from '@mui/icons-material/AddCard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CalendarMonthIcon from '@mui/icons-material/CalendarToday';

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Helpers
import { ROOT_SERVER } from 'helpers/helperInit';
import MKTypography from "components/MKTypography";
import { useEffect, useState } from "react";
import { 
    KEY_SERVICE_ADMISION,
    KEY_SERVICE_NIVELACION,
    KEY_SERVICE_MATRICULA,
    KEY_SERVICE_TRAMITE,
    KEY_SERVICE_CONVOCATORIA,
    KEY_SERVICE_TEST
} from "helpers/helperInit";
import { set_service_type_action } from "redux/actions/publicationAction";
import { set_one_service_action } from "redux/actions/publicationAction";
import PublicationCarousel from "pages/LandingPages/Publications/sections/PublicationCarousel";
import { ROOT_ICONS } from "helpers/helperInit";
import { KEY_NOTICIA } from "helpers/helperInit";
import { set_one_publication_action } from "redux/actions/publicationAction";

const PublicationCardNotice = ({data}) => {
    const dispatch = useDispatch();

    const handleLoadData = (notice) => {
        sessionStorage.setItem("key_type_publication", KEY_NOTICIA);
        dispatch(set_one_publication_action(notice));
    }

    return (
        <Card 
            component={Link}
            to={ROOT_SERVER + "/pages/publicaciones"}
            onClick={() => handleLoadData(data)}
            style={{
                background: 'transparent',
                borderRadius: 0,
                background: "#FFF",
                minHeight: '250px',
                border: '1px solid #ECECEC'
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <MKBox textAlign="left" style={{background: "#FFF"}}>
                        <MKTypography pl={2} display="inline" textTransform="capitalize" style={{fontSize: '12px'}}>
                            Noticia
                        </MKTypography>
                    </MKBox>
                    <hr style={{margin: '0px 10px', border: '1px solid #CCC'}} />
                    {/* <Divider /> */}
                    <MKTypography pl={5} pt={3} style={{fontSize: '16px', color: "#0C83D6"}}>
                        {data.titulo.substring(0,100)}...
                    </MKTypography>
                    <Box pl={5} pt={1}>
                        <div style={{fontSize: '15px', color: "#7D7D7E"}}>
                            <CalendarMonthIcon /> <p style={{fontSize: '11px', display: 'inline'}}>Publicado: {data.fecha.substring(0,10)}</p>
                        </div>
                    </Box>
                    <MKTypography pl={5} pt={3} style={{fontSize: '14px', color: "#444547"}}>
                        {data.descripcion.substring(0,300)}...
                    </MKTypography>
                    <Box px={2} pt={3}>
                        <button style={{width: '100%', height: '25px', background: '#5591EA', borderRadius: '3px', color: '#FFF', fontSize: '11px', border: 'none'}}>Mas Información</button>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
}

export default PublicationCardNotice;