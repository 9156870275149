import DashboardAdmin from "pages/LandingPages/DashboardAdm";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getGeneralData } from "redux/actions/generalDataAction";

const DashboardAdminPage = () => {
  const dispatch = useDispatch();
  useEffect(()=> {
    dispatch(getGeneralData());
  });

  return <DashboardAdmin />;
}

export default DashboardAdminPage;