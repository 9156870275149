import React from 'react';
import PropTypes from 'prop-types';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogActions from '@material-ui/core/DialogActions';
// import Dialog from '@material-ui/core/Dialog';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

class ConfirmationDialog extends React.Component {
    constructor(props, context) {
        super(props, context);
        const { value } = this.props;
        this.state.valueState = value;
    }

    state = {};
    handleCancel = () => {
        const { value, onClose } = this.props;
        onClose("Cancel");
    };

    handleOk = () => {
        const { valueState } = this.state;
        const { onClose } = this.props;
        onClose("Yes");
    };

    render() {
        const { value, ...other } = this.props;
        const { valueState } = this.state;
        return (
            <Dialog
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">{other.title}</DialogTitle>
                <DialogContent>
                    <div>{other.content}</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancel} color="secondary">No</Button>
                    <Button onClick={this.handleOk} color="primary">Si</Button>
                </DialogActions>
            </Dialog>
        );
    }    
}

ConfirmationDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

export default ConfirmationDialog;