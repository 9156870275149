import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Mui Components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TablePagination from '@mui/material/TablePagination';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table'
import TableHead from "@mui/material/TableHead";
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

import Button from "@mui/material/Button";

import ConfirmationDialog from "components/ConfirmationDialog";
import { set_one_service_action } from "redux/actions/publicationAction";
import { saveDeleteService } from "redux/actions/publicationAction";
import { getAllServices } from "redux/actions/publicationAction";

const ServiceList = ({onChangeAction, id_type_service}) => {
    const dispatch = useDispatch();
    const reducerService = "serviceReducer";
    const data_services = useSelector(state => state[reducerService].data_services);
    const [dataServices, setDataServices] = useState(null);
    const [deleteService, setDeleteService] = useState({
        id_servicio: 0
    });
    const [stateConfirmDialog, setStateConfirmDialog] = useState({
        open: false,
        title: '',
        content: ''
    });
    const [valuesPage, setValuesPage] = useState({
        category_id: 0,
    })
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(e.target.value);
        setPage(0)
    }

    const handleSetAction = () => {
        dispatch(set_one_service_action(null));
        onChangeAction("register");
    }

    const handleEditService = async (service) => {
        await dispatch(set_one_service_action(service));
        onChangeAction("register");
    }

    const handleOpenConfirmDialog = (service) => {
        setDeleteService({
            id_servicio: parseInt(service.id_servicio)
        });
        setStateConfirmDialog({
            open: true,
            title: "Eliminar Registro",
            content: "¿Seguro de Eliminar el registro seleccionado?"
        });
    }

    const handleCloseConfirmDialog = async (value) => {
        if(value === "Yes") {
            const newDataService = {
                "id_servicio": deleteService.id_servicio
            };
			const rptaSave = await saveDeleteService(newDataService);
			await dispatch(getAllServices());
        }
        setStateConfirmDialog({
            open: false,
            title: '',
            content: ''
        });
    }

    useEffect(() => {
        const newList = data_services.filter(item => parseInt(item.id_tiposervicio) === parseInt(id_type_service));
        setDataServices(newList);
    },[data_services, valuesPage]);

    return (
        <>
            <Container>
                <Grid container pt={3}>
                    <Grid item xs={12} textAlign='right'>
                        <Button 
                            variant='contained' 
                            color='secondary' 
                            style={{color: '#FFF'}}
                            onClick={handleSetAction}
                        >
                            GENERAR NUEVA
                        </Button>
                    </Grid>
                </Grid>
                <Divider />
                <Table>
                    <TableBody>
                        {dataServices !== null && dataServices
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(service => {
                            return(
                                <TableRow key={service.id_servicio} style={{verticalAlign: 'top'}}>
                                    <TableCell style={{borderBottom: '1px solid #CCC'}}>
                                        <MKTypography className='color-primary' style={{cursor: 'pointer', fontStyle: 'italic', fontSize: '16px'}}>
                                            {service.titulo}
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell style={{borderBottom: '1px solid #CCC'}}>
                                        <MKTypography variant="body2" opacity={0.8} mb={2}>
                                            {service.descripcion !== null ? service.descripcion.substring(0, 250) : ""}...
                                        </MKTypography>
                                    </TableCell>
                                    <TableCell align="right" style={{borderBottom: '1px solid #CCC'}}>
                                        <Tooltip title='Editar'>
                                            <IconButton onClick={() => handleEditService(service)}>
                                                <EditIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title='Anular'>
                                            <IconButton onClick={() => handleOpenConfirmDialog(service)}>
                                                <DeleteIcon fontSize='small' />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}            
                    </TableBody>
                </Table>
                <TablePagination
                    component='div'
                    rowsPerPageOptions={[5,10,50,100]}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    count={dataServices ? dataServices.length : 0 }
                    labelRowsPerPage=''
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <ConfirmationDialog 
                    open={stateConfirmDialog.open}
                    title={stateConfirmDialog.title}
                    content={stateConfirmDialog.content}
                    onClose={handleCloseConfirmDialog}
                />
            </Container>
        </>
    )
}

export default ServiceList;