import MKTypography from "components/MKTypography";
import { useSelector } from "react-redux";

const ServModality = () => {
    const reducerService = "serviceReducer";
    const one_service = useSelector(state => state[reducerService].one_service);

    return(
        <>
        {/* <MKTypography pt={5} mb={1} sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
            <u>Modalidad</u>
        </MKTypography> */}
        {one_service.modalidad.map(modality => {
            return(
                <MKTypography key={modality.id_servicio_modalidad} sx={{fontSize: '15px', color: '#000', borderBottom: '1px solid #CCC'}} pl={5}>
                    {modality.descripcion}
                </MKTypography>
            );
        })}
        </>
    );
}

export default ServModality;