import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import MKTypography from "components/MKTypography";
import { ROOT_SERVER } from "helpers/helperInit";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const imgLocate = ROOT_SERVER + '/static/images/icons/calendar.svg';
const imgServ = ROOT_SERVER + '/static/images/galeria/services.jpg';

const ServiceLinkMain = () => {
    const reducerService = "serviceReducer";
    const data_services = useSelector(state => state[reducerService].data_services);

    const [dataFirstServ, setDataFirstServ] = useState(null);

    const initData = () => {
        if(data_services !== null) {
            setDataFirstServ(data_services[0]);
        }
    }

    useEffect(() => {
        initData();
    })

    return (
        <>
        {(dataFirstServ && dataFirstServ !== null) && (
            <Box 
                style={{
                    background: '#FFF',
                    borderRadius: 0,
                    border: 'none'
                }}
            >
                <Grid container>
                    <Grid item xs={12} alignContent='center' alignItems={'center'} alignSelf='center'>
                        <img src={imgLocate} width={50} style={{display: 'inline'}} />
                        <MKTypography display='inline' pl={2} style={{color: '#000', fontSize: '24px', fontFamily: 'Arial', fontWeight: 600}}>
                            {dataFirstServ.tiposervicio}
                        </MKTypography>
                        <MKTypography pt={1} style={{fontSize: '20px', color: "#000"}}>
                            {dataFirstServ.titulo !== null ?  dataFirstServ.titulo + " ... " + dataFirstServ.descripcion.substring(0,120) : ""}...
                        </MKTypography>
                        <Box px={2} pt={2}>
                            <Button 
                                component={Link}
                                to={ROOT_SERVER + "/pages/informate"}
                                className="color-secondary"
                                sx={{fontSize: '16px'}}
                            >
                                MAS INFORMACIÓN <ArrowForwardIcon />
                            </Button>
                        </Box>
                    </Grid>
                    {/* <Grid item xs={12} lg={6}>
                        <img src={imgServ} width={500} />
                    </Grid> */}
                </Grid>
            </Box>
        )}
        </>
    );
}

export default ServiceLinkMain;