import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MuiLink from "@mui/material/Link";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Helpers
import { ROOT_SERVER } from 'helpers/helperInit';
import MKTypography from "components/MKTypography";

const imgLinks = ROOT_SERVER + '/static/images/icons/image3.png';
const imgMidenu = ROOT_SERVER + '/static/images/academyc/midenu.png';
const imgSunat = ROOT_SERVER + '/static/images/academyc/sunat.jpg';
const imgGRPiura = ROOT_SERVER + '/static/images/academyc/gr_piura.jpg';
const imgsiage = ROOT_SERVER + '/static/images/academyc/siage.png';

const HomeLinks = () => {
    const reducerGeneralData = "generalDataReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);

    return(
        <>
        <Box style={{background: '#FFF'}}>
            <img src={imgLinks} width={30} /> 
            <MKTypography display="inline" variant="h5" className='color-four' textTransform="capitalize" fontWeight="bold">
                ENLACES DESTACADOS
            </MKTypography>
            <hr />
            <Grid container px={6} pt={2} spacing={2}>
                <Grid item xs={12} lg={3}>
                    <Card 
                        component={MuiLink}
                        href={"https://www.gob.pe/minedu"}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            border: '1px solid #C2C2C2', 
                            background: 'transparent',
                            borderRadius: '15px'
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <MKBox
                                    component="img"
                                    src={imgMidenu}
                                    width="100%"
                                    // zIndex={1}
                                    style={{padding: '5px', opacity: 0.8, height: '90px'}}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Card 
                        component={MuiLink}
                        href={"https://www.sunat.gob.pe/"}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            border: '1px solid #C2C2C2', 
                            background: 'transparent',
                            borderRadius: '15px'
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <MKBox
                                    component="img"
                                    src={imgSunat}
                                    width="100%"
                                    // zIndex={1}
                                    style={{padding: '5px', opacity: 0.8 , height: '90px'}}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Card 
                        component={MuiLink}
                        href={"https://www.gob.pe/regionpiura"}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            border: '1px solid #C2C2C2', 
                            background: 'transparent',
                            borderRadius: '15px'
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <MKBox
                                    component="img"
                                    src={imgGRPiura}
                                    width="100%"
                                    // zIndex={1}
                                    style={{padding: '5px', opacity: 0.8, height: '90px'}}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Card 
                        component={MuiLink}
                        href={"https://siagie.minedu.gob.pe/"}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            border: '1px solid #C2C2C2', 
                            background: 'transparent',
                            borderRadius: '15px'
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <MKBox
                                    component="img"
                                    src={imgsiage}
                                    width="100%"
                                    // zIndex={1}
                                    style={{padding: '5px', opacity: 0.8, height: '90px'}}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
        </>
    );
}

export default HomeLinks;