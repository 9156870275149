// @mui material components
import Card from "@mui/material/Card";

import { ROOT_SERVER } from "helpers/helperInit";
import MKTypography from "components/MKTypography";
import { useSelector } from "react-redux";
import FirstCarousel from "components/Carousel";

const bgImage = ROOT_SERVER + '/static/images/academyc/image3.jpg';

const HeaderHomeInstituto = () => {
    const reducerGeneralData = "generalDataReducer";
    const data_general_data = useSelector(state => state[reducerGeneralData].data_general_data);

    const images = [
        {
            nombreimagen: "sliderMorropon1.jpg",
        },
        {
            nombreimagen: "sliderMorropon2.jpg",
        },
        {
            nombreimagen: "sliderMorropon3.jpg",
        },
    ]

    return(
        <FirstCarousel images={images} />
        // <Card style={{zIndex: -2}}>
        //     <div>
        //         <div className="carousel-item" style={{ height: '300px', width: '50%' }}>
        //             <img src={bgImage} className='img-carrusel' />
        //             <p 
        //                 // className={"color-primary"}
        //                 style={{
        //                     fontWeight: 700,
        //                     color: '#252525',
        //                     fontSize: '40px',
        //                     fontFamily: 'Georgia'
        //                 }}
        //             >
        //                 {data_general_data.length > 0 && data_general_data[0].lema !== null ? data_general_data[0].lema.substring(0,150) : ""}...
        //             </p>
        //             {/* <MKTypography variant="body2" color="white" opacity={0.8} mb={2}>
        //                 data_general_data.objetivos
        //             </MKTypography> */}
        //         </div>
        //     </div>
        // </Card>
    );
}

export default HeaderHomeInstituto;