import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';

import MKTypography from "components/MKTypography";
import { useSelector } from "react-redux";
import MuiLink from "@mui/material/Link";
import { ROOT_SERVER } from "helpers/helperInit";
import { ROOT_FILES_PDF } from "helpers/helperInit";
import { useState } from "react";

const imgPdf = ROOT_SERVER + '/static/images/icons/pdf.png';

const ServResults = ({path}) => {
    const reducerService = "serviceReducer";
    const one_service = useSelector(state => state[reducerService].one_service);
    const [openDialogFrame, setOpenDialogFrame] = useState(false);
    const [urlPdf, setUrlPdf] = useState("");


    const handleOpenDialogFrame = (valUrl) => {
        setUrlPdf(valUrl);
        setOpenDialogFrame(true);
    }

    const handleCloseDialog = () => {
        setOpenDialogFrame(false);
    };

    return(
        <>
        {/* <MKTypography pt={5} mb={1} sx={{color: "#000", fontSize: '20px', fontWeight: 700, fontStyle: 'italic' }} >
            <u>Resultados</u>
        </MKTypography> */}
        {one_service.resultado.map(resultado => {
            return(
                <Grid container key={resultado.id_servicio_resultado} style={{borderBottom: '1px solid #CCC'}} >
                    <Grid item xs={8}>
                        <MKTypography sx={{fontSize: '15px', color: '#000'}} pl={5}>
                            {resultado.descripcion}
                        </MKTypography>
                    </Grid>
                    <Grid item xs={2}>
                        {resultado.pdfresulados !== null && (
                            <Card
                                // component={MuiLink}
                                // href={path + resultado.pdfresulados}
                                // target="_blank"
                                rel="noreferrer"
                                style={{
                                    background: 'transparent',
                                    borderRadius: 0
                                }}
                                onClick={() => handleOpenDialogFrame(path + resultado.pdfresulados)}
                            >
                                <img src={imgPdf} width={50} style={{cursor: 'pointer'}} />
                            </Card>
                        )}
                    </Grid>
                </Grid>
            );
        })}

        <Dialog onClose={handleCloseDialog} open={openDialogFrame} maxWidth="xl" fullWidth >
            <DialogTitle
            >
                <IconButton
                    aria-label="close"
                    onClick={handleCloseDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{ 
                    height: '95vh'
                }}
            >
                <Divider />
                <iframe style={{width: '100%', height: '100%'}} src={urlPdf} />
            </DialogContent>
        </Dialog>

        </>
    )
}

export default ServResults;