import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import { ROOT_SERVER } from 'helpers/helperInit';

import './css/my-style.css';
import { ROOT_GALERIA } from 'helpers/helperInit';

const img1 = ROOT_SERVER + '/static/images/executive/image1.jpg';
const img2 = ROOT_SERVER + '/static/images/executive/image2.jpg';
const img3 = ROOT_SERVER + '/static/images/executive/image3.jpg';

const SecondCarousel = ({images, path}) => {
    let nomImg = "";
    if(typeof path === 'undefined') {
        path = ROOT_GALERIA;
    }
    if(path === null) {
        path = ROOT_GALERIA;
    }

    let keyImg = "";
    return (
        <Carousel autoPlay infiniteLoop	interval={2000} showIndicators={false} showThumbs={false}>
            {images && images.map((image, index) => {
                keyImg = "key" + image;
                nomImg = image.nombreimagen;
                if(image.formato) {
                    nomImg = nomImg + "." + image.formato;
                }
                return(
                    <div key={keyImg} style={{ minHeight: '200px' }}>
                        <img src={path + nomImg} height='400px' />
                        {/* <p className="legend">Legend 1</p> */}
                    </div>        
                    // <div key={keyImg} className='item-carrusel' style={{ minHeight: '400px' }}>
                    //     <div style={{ height: '500px', width: '400px' }}>
                    //         <img src={path + nomImg} />
                    //         <p className="legend">Legend 1</p>
                    //     </div>
                    // </div>
                );
            })}
        </Carousel>
    );
}

export default SecondCarousel;