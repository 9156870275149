import { useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ContentService from "./ContentService";
import LinkServices from "./LinkServices";
import ListServices from "./ListServices";

const ContentPage = () => {
    const reducerService = "serviceReducer";
    const one_service_type = useSelector(state => state[reducerService].one_service_type);

    return(
        <Box>
            <Grid container>
                {/* <Grid item xs={2}>
                    <ListServices type_service={one_service_type} />
                </Grid> */}
                <Grid item xs={10}>
                    <ContentService type_service={one_service_type} />
                </Grid>
                <Grid item xs={2}>
                    <LinkServices type_service={one_service_type} />
                </Grid>
            </Grid>
        </Box>
    )
}

export default ContentPage;