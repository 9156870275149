import { createStore, combineReducers, compose,applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { systemParamtsReducer, notifReducer, severalReducer } from './modules/severalReducer';
import { userReducer, menuReducer } from './modules/userReducer';
import { publicationReducer, categoryReducer, serviceReducer, programReducer } from './modules/publicationReducer';
import { generalDataReducer } from './modules/generalDataReducer';
import { studentReducer, schoolReducer } from './modules/notesReducer';


const reducers = combineReducers({
    systemParamtsReducer,
    notifReducer,
    severalReducer,
    menuReducer,
    userReducer,
    publicationReducer,
    categoryReducer,
    generalDataReducer,
    serviceReducer,
    programReducer,
    studentReducer, 
    schoolReducer
});

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(thunk))
)