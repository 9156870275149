const URL_SERVICE = process.env.URL_SERVICE || "https://iestpmorropon.edu.pe:10050/";
//const URL_SERVICE = process.env.URL_SERVICE || "http://localhost:2800/";

const ROOT_SERVER = process.env.ROOT_SERVER || ""; 
const ROOT_GALERIA = ROOT_SERVER + '/static/images/galeria/';
const ROOT_ICONS = ROOT_SERVER + '/static/images/icons/';
const ROOT_FILES_PDF = ROOT_SERVER + '/static/files/pdf/';
const MESSAGE_NOT_CONNECT_SERVER = "No es posible conectar con el Servidor";
const INSTITUTION_SUBTITLE = "Instituto de Educación Superior Tecnológico Público";

const SHOW_HEADER = 2;
const DATA_SEXO = [
    {
        id_sexo: "0",
        nom_sexo: "Femenino"
    },
    {
        id_sexo: "1",
        nom_sexo: "Masculino"
    }
];

const MENU_INIT = [
    {
        "id_modulo": 1,
        "name": "NOSOTROS",
        "columns": "1",
        "rowsPerColumn": 1,
        "key_menu": "mod-us",
        "route": ROOT_SERVER + "/pages/about-us",
        "icon": "",
        "collapse": null
    },
    {
        "id_modulo": 2,
        "name": "PROGRAMAS DE ESTUDIO",
        "columns": "1",
        "rowsPerColumn": 1,
        "key_menu": "mod-schools",
        "route": ROOT_SERVER + "/pages/programas",
        "icon": "",
        "collapse": null
    },
    // {
    //     "id_modulo": 3,
    //     "name": "Publicaciones",
    //     "columns": "1",
    //     "rowsPerColumn": 1,
    //     "key_menu": "mod-publication",
    //     "route": "/pages/publicaciones",
    //     "icon": "",
    //     "collapse": null
    // },
];

const MENU_ADMIN = [
    // {
    //     "id_modulo": 100,
    //     "name": "Panel",
    //     "columns": "1",
    //     "rowsPerColumn": 1,
    //     "key_menu": "panel-admin",
    //     "route": "/pages/dashboard/administrator",
    //     "icon": "",
    //     "collapse": null
    // },
];

// Keys Services
const KEY_SERVICE_ADMISION = {
    "key_service": 1,
    "name": "Proceso de Admisión",
    "image_name": ROOT_SERVER + '/static/images/academyc/admision.png'
};
const KEY_SERVICE_NIVELACION = {
    "key_service": 2,
    "name": "Nivelación",
    "image_name": ROOT_SERVER + '/static/images/academyc/nivelacion.png'
};
const KEY_SERVICE_MATRICULA = {
    "key_service": 3,
    "name": "Matrícula",
    "image_name": ROOT_SERVER + '/static/images/academyc/matricula.jpg'
};
const KEY_SERVICE_TRAMITE = {
    "key_service": 4,
    "name": "Trámite",
    "image_name": ROOT_SERVER + '/static/images/academyc/tramite.jpg'
};
const KEY_SERVICE_CONVOCATORIA = {
    "key_service": 5,
    "name": "Convocatoria",
    "image_name": ROOT_SERVER + '/static/images/academyc/convocatoria.jpg'
};
const KEY_SERVICE_TEST = {
    "key_service": 6,
    "name": "Test",
    "image_name": ROOT_SERVER + '/static/images/academyc/test.jpg'
};

const TYPE_PUBLICATION = [
    {
        id_type_publication: 1,
        name: "Noticias"
    },
    {
        id_type_publication: 2,
        name: "Publicaciones"
    },
    {
        id_type_publication: 3,
        name: "Eventos"
    }
];

const KEY_NOTICIA = 1;
const KEY_PUBLICATION = 2;
const KEY_EVENT = 3;

const SERVICE_SHOW_DETAILS = [
    {
        id_type_service: 1,
        name: "Admision",
        show: {
            show_blog: false,
            show_convalidation: true,
            show_costos: true,
            show_cronograma: true,
            show_modalidad: true,
            show_orientation: false,
            show_requisitos: true,
            show_resultados: true,
            show_files: true,
            show_adjuntos: true,
        }
    },
    {
        id_type_service: 2,
        name: "Nivelacion",
        show: {
            show_blog: false,
            show_convalidation: false,
            show_costos: true,
            show_cronograma: true,
            show_modalidad: false,
            show_orientation: false,
            show_requisitos: true,
            show_resultados: false,
            show_adjuntos: true,
        }
    },
    {
        id_type_service: 3,
        name: "Matricula",
        show: {
            show_blog: false,
            show_convalidation: false,
            show_costos: true,
            show_cronograma: false,
            show_modalidad: false,
            show_orientation: false,
            show_requisitos: true,
            show_resultados: false,
            show_adjuntos: true,
        }
    },
    {
        id_type_service: 4,
        name: "Tramite",
        show: {
            show_blog: false,
            show_convalidation: false,
            show_costos: false,
            show_cronograma: false,
            show_modalidad: false,
            show_orientation: false,
            show_requisitos: false,
            show_resultados: true,
            show_adjuntos: true,
        }
    },
    {
        id_type_service: 5,
        name: "Convocatoria",
        show: {
            show_blog: false,
            show_convalidation: false,
            show_costos: false,
            show_cronograma: false,
            show_modalidad: false,
            show_orientation: false,
            show_requisitos: true,
            show_resultados: true,
            show_adjuntos: true,
        }
    },
    {
        id_type_service: 6,
        name: "Test",
        show: {
            show_blog: true,
            show_convalidation: false,
            show_costos: false,
            show_cronograma: false,
            show_modalidad: false,
            show_orientation: true,
            show_requisitos: false,
            show_resultados: true,
            show_adjuntos: true,
        }
    }
]

function dateFormatYYMMDD(date) {
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    return year + "-" + month + "-" + day;
}

function getYears(minYear) {
    let years= [];
    const currentYear = new Date().getFullYear();
    if(minYear === 0) {
        minYear = 2000;
    }
    for(var i = currentYear; i >= minYear; i--) {
        var oneYear = {
            year_id: i,
            name: i
        }
        years.push(oneYear);
    }
    return years;
}

const getUserAuth = () => {
    let hasConn = sessionStorage.getItem('hasConn');
    let idUser = "";
    let strToken = "";
    let userName = "";
    let fotoUser = "";
    let nomCargo = "";
    if(hasConn !== null){
        let jsonHasConn = JSON.parse(hasConn);
        idUser = jsonHasConn['id_rand'];
        strToken = jsonHasConn['id_cook'];
        userName = jsonHasConn['username'];
        fotoUser = jsonHasConn['foto_user'];
        nomCargo = jsonHasConn['nom_rol'];
    }
    return [idUser, strToken, userName, fotoUser, nomCargo];
}

export {
    MESSAGE_NOT_CONNECT_SERVER,
    URL_SERVICE,
    ROOT_SERVER,
    ROOT_GALERIA,
    ROOT_ICONS,
    ROOT_FILES_PDF,
    MENU_INIT,
    INSTITUTION_SUBTITLE,
    MENU_ADMIN,
    KEY_SERVICE_ADMISION,
    KEY_SERVICE_NIVELACION,
    KEY_SERVICE_MATRICULA,
    KEY_SERVICE_TRAMITE,
    KEY_SERVICE_CONVOCATORIA,
    KEY_SERVICE_TEST,
    KEY_NOTICIA,
    KEY_PUBLICATION,
    KEY_EVENT,
    SERVICE_SHOW_DETAILS,
    TYPE_PUBLICATION,
    SHOW_HEADER,
    DATA_SEXO,
    getUserAuth,
    dateFormatYYMMDD,
    getYears,
}