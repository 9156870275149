import { typesCourses } from "redux/constants/type";
import { typesPlan } from "redux/constants/type";
import { typesPlanCurso } from "redux/constants/type";
import { typesSchools } from "redux/constants/type";
import { typesTeachers } from "redux/constants/type";
import { typesStudents } from "redux/constants/type";

const initialStateStudent = {
    data_students: [],
    one_student: null
}

const initialStateSchool = {
    data_schools: [],
    one_school: null
}

export const studentReducer = (state = initialStateStudent, action) => {
    switch (action.type) {
        case typesStudents.read:
            return {
                ...state,
                data_students: action.payload
            }
        case typesStudents.setOneStudent:
            return {
                ...state,
                one_student: action.payload,
            }
        default:
            return state;
    }
}

export const schoolReducer = (state = initialStateSchool, action) => {
    switch (action.type) {
        case typesSchools.read:
            return {
                ...state,
                data_schools: action.payload
            }
        case typesSchools.setOneSchool:
            return {
                ...state,
                one_school: action.payload,
            }
        default:
            return state;
    }
}