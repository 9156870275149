import React from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

function Notification(props) {
    const { close, message, notiftype, notifvariant } = props;
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        close('crudTableDemo');
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={message !== ''}
            autoHideDuration={2000}
            onClose={() => handleClose()}
        >
            <Alert 
                severity={notiftype} 
                // variant={notifvariant} 
                sx={{ width: '100%', fontWeight: 100 }} 
                direction="down" 
            >
                {message}
            </Alert>
        </Snackbar>
    );
}

export default Notification;